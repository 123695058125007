import styled from "styled-components";
import { theme } from "../../../theme/theme";

export const ETFormWI059ERWrapper = styled.div`
  height: calc(100% - 96px);

  .disabled {
    div {
      opacity: 0.6;
    }
  }
  
  .opttion-container {
    padding-top: 20px;
    padding-bottom: 15px;
    border-top: 1px solid ${theme.colors.greyDivider};
    position: relative;

    .label {
      color: ${theme.colors.label};
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 16px;
      margin: 0;
      margin-bottom: 16px;
    }

    .editor-info {
      color: ${theme.colors.label};
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 16px;
      display: flex;
      align-items: center;
      position: absolute;
      right: 0;
      top: 50px;
    }
  }

  .opttion-container:last-child {
    border-bottom: 1px solid ${theme.colors.greyDivider};
  }
`;