import { observer } from "mobx-react-lite";
import { FC } from "react";
import { useRootStore } from "../../../stores/storeContext";
import { VQDynamicFormComponent } from "vivaquant-components-library";
import { ETWIProps } from "../et-wi-forms.props";

export const ETFormWI050Main: FC<ETWIProps> = observer((props: ETWIProps) => {
  const {
    wiRev,
    failureMode,
    comment
  } = useRootStore().wi050FormStore;

  const handleChange = () => {};


  return (
    <>
      <div className="col-container">
        <VQDynamicFormComponent
          item={wiRev}
          customClass="col-container-el"
          errors={props.errors}
          handleChange={handleChange}
          showError={true}
          disabled={props.isDisabled}
          isFormInvalid={props.formInvalid}></VQDynamicFormComponent>
        {!failureMode.isLoading && <VQDynamicFormComponent
          item={failureMode}
          customClass="col-container-el"
          errors={props.errors}
          handleChange={handleChange}
          showError={true}
          disabled={props.isDisabled}
          isFormInvalid={props.formInvalid}></VQDynamicFormComponent>}
      </div>
      <VQDynamicFormComponent
        item={comment}
        errors={props.errors}
        handleChange={handleChange}
        showError={true}
        disabled={props.isDisabled}
        isFormInvalid={props.formInvalid}></VQDynamicFormComponent>
    </>
  );
});
