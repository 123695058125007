import { observer } from "mobx-react-lite";
import { FC } from "react";
import { useRootStore } from "../../../stores/storeContext";
import { VQDynamicFormComponent } from "vivaquant-components-library";
import { ETWIProps } from "../et-wi-forms.props";

export const ETFormWI048Main: FC<ETWIProps> = observer((props: ETWIProps) => {
  const {
    wiRev,
    dmm,
    ecgSimulator,
    testFixture,
    wiFiSignalRssi,
    wiFiCurrent,
    state1Current,
    state4Current,
    modemCurrent,
    failureMode,
    comment
  } = useRootStore().wi048FormStore;

  const handleChange = () => {};
  const handleChangeNumber = (e: any, item: any) => {
    item.setValue(item.value.replace(/[^0-9.-]/g, ""));
  };


  return (
    <>
      <div className="col-container">
        <VQDynamicFormComponent
          item={wiRev}
          customClass="col-container-el"
          errors={props.errors}
          handleChange={handleChange}
          showError={true}
          disabled={props.isDisabled}
          isFormInvalid={props.formInvalid}></VQDynamicFormComponent>
        <VQDynamicFormComponent
          item={dmm}
          customClass="col-container-el"
          errors={props.errors}
          handleChange={handleChange}
          showError={true}
          disabled={props.isDisabled}
          isFormInvalid={props.formInvalid}></VQDynamicFormComponent>
      </div>
      <div className="col-container">
        <VQDynamicFormComponent
          item={ecgSimulator}
          customClass="col-container-el"
          errors={props.errors}
          handleChange={handleChange}
          showError={true}
          disabled={props.isDisabled}
          isFormInvalid={props.formInvalid}></VQDynamicFormComponent>
        <VQDynamicFormComponent
          item={testFixture}
          customClass="col-container-el"
          errors={props.errors}
          handleChange={handleChange}
          showError={true}
          disabled={props.isDisabled}
          isFormInvalid={props.formInvalid}></VQDynamicFormComponent>
      </div>
      <div className="col-container">
        <VQDynamicFormComponent
          item={wiFiSignalRssi}
          customClass="col-container-el"
          errors={props.errors}
          handleChange={handleChangeNumber}
          showError={true}
          disabled={props.isDisabled}
          isFormInvalid={props.formInvalid}></VQDynamicFormComponent>
        <VQDynamicFormComponent
          item={wiFiCurrent}
          customClass="col-container-el"
          errors={props.errors}
          handleChange={handleChangeNumber}
          showError={true}
          disabled={props.isDisabled}
          isFormInvalid={props.formInvalid}></VQDynamicFormComponent>
      </div>
      <div className="col-container">
        <VQDynamicFormComponent
          item={state1Current}
          customClass="col-container-el"
          errors={props.errors}
          handleChange={handleChangeNumber}
          showError={true}
          disabled={props.isDisabled}
          isFormInvalid={props.formInvalid}></VQDynamicFormComponent>
        <VQDynamicFormComponent
          item={state4Current}
          customClass="col-container-el"
          errors={props.errors}
          handleChange={handleChangeNumber}
          showError={true}
          disabled={props.isDisabled}
          isFormInvalid={props.formInvalid}></VQDynamicFormComponent>
      </div>
      <div className="col-container">
        <VQDynamicFormComponent
          item={modemCurrent}
          customClass="col-container-el"
          errors={props.errors}
          handleChange={handleChangeNumber}
          showError={true}
          disabled={props.isDisabled}
          isFormInvalid={props.formInvalid}></VQDynamicFormComponent>
        {!failureMode.isLoading && <VQDynamicFormComponent
          item={failureMode}
          customClass="col-container-el"
          errors={props.errors}
          handleChange={handleChange}
          showError={true}
          disabled={props.isDisabled}
          isFormInvalid={props.formInvalid}></VQDynamicFormComponent>}
      </div>
      <VQDynamicFormComponent
        item={comment}
        errors={props.errors}
        handleChange={handleChange}
        showError={true}
        disabled={props.isDisabled}
        isFormInvalid={props.formInvalid}></VQDynamicFormComponent>
    </>
  );
});

