import styled from "styled-components";
import { theme } from "../../theme/theme";

export const ETReportModalWrapper = styled.div`
  min-height: 100vh;
  background: rgba(47, 47, 47, 0.80) !important; 
  overflow: hidden;

  .top-section {
    opacity: 0.8;
    background: ${theme.colors.text};
    padding: 12px 24px;
    display: flex;
    justify-content: flex-end;
    gap: 16px;
    align-items: center;

    .close {
      background: ${theme.colors.light};
      border-radius: 50%;
      width: 40px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
    }
  }

  .report {
    padding: 24px;
    padding-bottom: 24px;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: calc(100vh - 72px);
    overflow: auto;
    
    .react-pdf__Document {
      max-width: 1067px;
      gap: 16px;
      display: flex;
      flex-direction: column;
    }
    .react-pdf__Page {
      min-width: 100px !important;
    }

    canvas {
      width: 100% !important;
      height: auto !important;
    }
  }
`;