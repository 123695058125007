import { IAdminUsersInviteModelSnapShot } from "../stores/admin-users-invite.store";

export const AdminUsersInviteState: IAdminUsersInviteModelSnapShot = {
  name: {
    name: "First Name",
    value: "",
    type: "input",
    fieldId: "name"
  },
  lastName: {
    name: "Last Name",
    value: "",
    type: "input",
    fieldId: "lastName"
  },
  email: {
    name: "Email",
    value: "",
    type: "email",
    fieldId: "email"
  },
  typeUser: {
    name: "Role",
    value: "",
    type: "multiply",
    fieldId: "typeUser",
    options: []
  },
  location: {
    name: "Location",
    value: "",
    type: "input",
    fieldId: "phone"
  }
};

