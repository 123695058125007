import { API } from "../../boot/api";
import { IUpdateProfileRequest } from "../../models/IUpdateProfileRequest";
import { IUpdateProfileResponse } from "../../models/IUpdateProfileResponse";
import { IUserProfile } from "../../models/IUserProfile";
import { ApiResponseDto } from "../../models/api";
import { TApiResponse } from "../../types";
import { ProcessAPIError } from "../decorators";


class UserApi {
  // @ProcessAPIError()
  // public async validatePassCodeProfile(email: string, code: string): Promise<ApiResponseType<null>> {
  //   const params = stringify({ emailOrUserName: email, code });
  //   const result = await API.post(`/salesAppAPI/SA/ValidatePassCodeProfile?${params}`);
  //   return new ApiResponseDto(result.data);
  // }

  @ProcessAPIError()
  public async getCurrentUser(): Promise<ApiResponseDto<IUserProfile>> {
    const { data } = await API.get<IUserProfile>("users/me");
    return new ApiResponseDto<IUserProfile>(data);
  }

  @ProcessAPIError()
  public async getAdminInfo(id: number) {
    const result = await API.get(`users/me/${id}/profile`);
    return new ApiResponseDto(result);
  }

  @ProcessAPIError()
  public async updateProfile(request: IUpdateProfileRequest): Promise<TApiResponse<IUpdateProfileResponse>> {
    const { data } = await API.patch<IUpdateProfileResponse>("users/me/profile", request);
    return new ApiResponseDto(data);
  }

  @ProcessAPIError()
  public async updateProfile2Fa(profile: IUpdateProfileRequest, code: string)
    : Promise<TApiResponse<IUpdateProfileResponse>> {
    const { data } = await API.patch<IUpdateProfileResponse>("users/me/profile/2fa", { profile, code });
    return new ApiResponseDto(data);
  }

  @ProcessAPIError()
  public async resendUpdateCode(): Promise<TApiResponse<null>> {
    await API.post("users/me/profile/2fa");
    return new ApiResponseDto(null);
  }
}

export const userAPI = new UserApi();
