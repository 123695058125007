import { INCMDispositionModelSnapShot } from "../stores/ncm-disposition.store";

export const NCMDispositionState: INCMDispositionModelSnapShot = {
  routeConfiguration: {
    name: "Route Configuration",
    value: "",
    type: "select",
    fieldId: "routeConfiguration",
    options: []
  },
  ncmNumber: {
    name: "NCM Number",
    value: "",
    type: "input",
    fieldId: "ncmNumber"
  },
  comment: {
    name: "Comment",
    value: "",
    type: "textarea",
    fieldId: "comment"
  },
};
