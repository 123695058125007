import { observer } from "mobx-react-lite";
import { FC, useEffect, useState } from "react";
import { message } from "antd";
import { useRootStore } from "../../stores/storeContext";
import { VQBlueButton, VQDynamicFormComponent, VQMessage, VQModalClose } from "vivaquant-components-library";
import { ContainerCodeWrapper, VQUserProfileWrapper } from "./Profile.presents";
import { Modal } from "react-bootstrap";
import AuthCode from "react-auth-code-input";
import { StringsUtils } from "../../services/utils";

export const ProfilePage: FC = observer(() => {
  const {
    initProfile,
    firstName,
    lastName,
    email,
    username,
    location,
    is2FaEnabled,
    setIs2FaEnabled,
    typeUser,
    showModal,
    closeModal,
    openModal,
    validateCode,
    validateField,
    saveForm,
    getDataForSave,
    resendUpdateCode
  } = useRootStore().profileStore;

  const { updateProfileData, profile } = useRootStore().userStore;

  const [formInvalid, setFormInvalid] = useState(false);
  const [errors, setErrors] = useState({});
  const [errorCode, setErrorCode] = useState(false);
  const [authCode, setAuthCode] = useState("");
  const [isResentCode, setIsResentCode] = useState(false);
  const handleChange = () => {};

  useEffect(() => {
    initProfile(profile);
  }, []);

  const validateForm = () => {
    const { errors, isValid } = validateField();
    setErrors(errors);
    return isValid;
  };

  const onSubmit = async(event: any) => {
    event.preventDefault();
    if (!validateForm()) {
      setFormInvalid(true);
      message.error({ content: "Please fill the fields!", duration: 2 });
    } else {
      setFormInvalid(false);
      const key = "updatable";
      const result = await saveForm();

      if (!result) {
        message.error({ content: "Error, Something went wrong!", key, duration: 2 });
      }

      if (result.isOk && !result.data.isConfirmationCodeNeeded) {
        updateProfileData(getDataForSave());
        message.success({ content: "User profile successfully updated!", key, duration: 2 });
      }

      if (result.isOk && result.data.isConfirmationCodeNeeded) {
        return openModal();
      }

      if (!result.isOk) {
        message.error({ content: result.error?.detail, key, duration: 2 });
      }
    }
  };

  const handleOnChangeAuthCode = (res: string) => {
    setAuthCode(res);
    setErrorCode(false);
  };

  const hideModal = () => {
    setAuthCode("");
    setIsResentCode(false);
    closeModal();
  };

  const onValidateCode = async(event: any) => {
    event.preventDefault();
    const key = "updatable";
    const response = await validateCode(authCode);
    if (response) {
      setAuthCode("");
      closeModal();
      message.success({ content: "User profile successfully updated!", key, duration: 2 });
    } else {
      setErrorCode(true);
    }
  };

  const getTwoFactorAuthenticationMessage = () => {
    const replaced = StringsUtils.hideEmail(email?.value || "");
    const sentText = isResentCode ? "resent" : "sent";
    return `A message with a verification code has been ${sentText} to your email ${replaced}.
    Enter the code to continue.`;
  };

  const retryCode = () => {
    setIsResentCode(true);
    resendUpdateCode();
  };

  return (
    <VQUserProfileWrapper>
      <div className="container">
        <div className="title">Profile</div>
        <form onSubmit={onSubmit}>
          <VQDynamicFormComponent
            item={firstName}
            errors={errors}
            isFormInvalid={formInvalid}
            showError={true}
            handleChange={handleChange}></VQDynamicFormComponent>
          <VQDynamicFormComponent
            item={lastName}
            errors={errors}
            isFormInvalid={formInvalid}
            showError={true}
            handleChange={handleChange}></VQDynamicFormComponent>
          <VQDynamicFormComponent
            item={email}
            errors={errors}
            isFormInvalid={formInvalid}
            showError={true}
            handleChange={handleChange}></VQDynamicFormComponent>
          <VQDynamicFormComponent
            item={username}
            errors={errors}
            isFormInvalid={formInvalid}
            showError={true}
            handleChange={handleChange}></VQDynamicFormComponent>
          {typeUser && <VQDynamicFormComponent
            item={typeUser}
            errors={errors}
            isFormInvalid={formInvalid}
            showError={true}
            handleChange={handleChange}></VQDynamicFormComponent>}
          <VQDynamicFormComponent
            item={location}
            errors={errors}
            isFormInvalid={formInvalid}
            showError={true}
            handleChange={handleChange}></VQDynamicFormComponent>
          <div className="switch-container">
            <div className="label">Two-factor authentication</div>
            <div className="enable-container">
              {is2FaEnabled ? "Enabled" : "Disabled"}
              <label className="switch">
                <input type="checkbox" checked={is2FaEnabled} onChange={(event: any) => {
                  setIs2FaEnabled(!!event?.target?.checked);
                }} />
                <div className="slider"></div>
              </label>
            </div>
          </div>
          <div className="btn-container">
            <VQBlueButton
              text={"Save"}
              click={() => {}}
            ></VQBlueButton>
          </div>
        </form>
      </div>
      <Modal
        onHide={hideModal}
        show={showModal}
        centered
        dialogClassName="invite-admin-modal">
        <Modal.Body>
          <>
            <VQModalClose onHide={hideModal}></VQModalClose>
            <ContainerCodeWrapper>
              <div className="container-code">
                <VQMessage text={getTwoFactorAuthenticationMessage()}></VQMessage>
                <div className="label">Authorization Code</div>
                <AuthCode
                  length={6}
                  onChange={handleOnChangeAuthCode}
                  containerClassName="auth-code-container"
                  inputClassName={`auth-code-input${errorCode ? " error" : ""}`}
                  allowedCharacters={"numeric"}
                />
                <div className="error-code">{errorCode && <span>Wrong authorization code</span>}&nbsp;</div>
                <VQBlueButton
                  width="100%"
                  text="Confirm"
                  type="button"
                  isDisabled={!authCode || authCode.length < 6}
                  click={(event: any) => { onValidateCode(event); }}></VQBlueButton>
                <div className="retry" onClick={() => { retryCode(); }}>
                  <span>Didn't get a verification code?</span>
                </div>
              </div>
            </ContainerCodeWrapper>
          </>
        </Modal.Body>
      </Modal>
    </VQUserProfileWrapper>
  );
});
