import { observer } from "mobx-react-lite";
import { FC } from "react";
import { AdminUsersPageWrapper } from "./Users.presents";
import { ETAdminUsersListHeader } from "../../../components/et-admin-users-list-header";

export const AdminUsersPage: FC = observer(() => {
  // const {
  //   getTotalCount
  // } = useRootStore().adminListStore;
  // const { setGlobalSpiner } = useRootStore().globalStore;

  // useEffect(() => {
  //   setGlobalSpiner(true);
  //   getTotalCount().then(() => { setGlobalSpiner(false); });
  // });

  return (
    <AdminUsersPageWrapper>
      <ETAdminUsersListHeader></ETAdminUsersListHeader>
    </AdminUsersPageWrapper>
  );
});
