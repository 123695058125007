import { MessageApi } from "antd/lib/message";
import { PAGINATION } from "../boot/constants";
import { WorkInstructionActionType } from "../boot/enums/WorkInstructionActionType";
import { IInProgressModel } from "../stores/in-progress-list.store";
import { FunctionUtils } from "./utils";

export class WIFormService {
  static isTransferData( previous: string, curent: string) {
    return previous === curent;
  }

  public static openNextDevice(
    itemsData : IInProgressModel[], 
    deviceId: string, 
    action: number,
    page: number,
    total: number,
    setFormInvalid: Function,
    setErrors: Function,
    setGlobalSpiner: Function,
    fetchData: Function,
    nextPage: Function,
    hide: Function,
    getTotalCount: Function,
    itemBatchIdNextPage: string
  ) {
    let itemBatchIdNext: string = null;
    const items = itemsData.map((el: IInProgressModel) => {return el;});
    const index = items.findIndex((el: IInProgressModel) => el.id === deviceId);
    if (index !== -1) {
      if (action === WorkInstructionActionType.None) {
        setFormInvalid(false);
        setErrors({});
        if ((index + 1) <= items.length - 1) {
          setGlobalSpiner(true);
          if ((index + 1) === items.length - 1) {
            itemBatchIdNext = itemBatchIdNextPage;
          } else if ((index + 1) < items.length - 1) {
            itemBatchIdNext = items[index + 2]?.batchId;
          }
          fetchData(
            items[index + 1].id, 
            itemBatchIdNext, 
            this.isTransferData(items[index]?.batchId, items[index + 1]?.batchId)
          ).then(() => { setGlobalSpiner(false); });
        } else if ((page + 1) * PAGINATION.ROWS_PER_PAGE < total) {
          return nextPage()
            .then((resultItems: any) => {
              setGlobalSpiner(true);
              fetchData(
                resultItems[0].id, 
                resultItems[1]?.batchId,
                this.isTransferData(items[index]?.batchId, resultItems[0]?.batchId)
              ).then(() => { setGlobalSpiner(false); });
            });
        } else {
          hide();
        }
      } else {
        setFormInvalid(false);
        setErrors({});
        getTotalCount()
          .then((resultItems: any) => {
            if(index <= resultItems.length - 1) {
              if(resultItems[index]) {
                setGlobalSpiner(true);
                if (index === resultItems.length - 1) {
                  itemBatchIdNext = itemBatchIdNextPage;
                } else if (index < resultItems.length - 1) {
                  itemBatchIdNext = resultItems[index + 1]?.batchId;
                }
                fetchData(
                  resultItems[index].id, 
                  itemBatchIdNext,
                  this.isTransferData(items[index]?.batchId, resultItems[index]?.batchId)
                ).then(() => { setGlobalSpiner(false); });
              } else {
                hide();
              }
            } else {
              hide();
            }
          });
      }
    } else {
      hide();
    }
  }

  public static onSubmit(
    action: number,
    isNextDevice: boolean,
    message: MessageApi,
    validateForm: Function,
    setFormInvalid: Function,
    saveForm: Function,
    setGlobalSpiner: Function,
    openNextDevice: Function,
    hide: Function
  ) {

    const saveFormFunction = () => {
      return saveForm(action)
        .then((res: any) => {
          setGlobalSpiner(false);
          if (res.isOk) {
            const key = "updatable";
            message.success({ content: "Your form has been saved successfully!", key, duration: 3 });
            if (isNextDevice) {
              openNextDevice(action);
            } else {
              hide();
            }
          } else {
            const key = "updatable";
            message.error({
              content: res.error?.detail || res.error?.title || "Error, Something went wrong!",
              key,
              duration: 3
            });
          }
        })
        .catch((error: any) => {
          setGlobalSpiner(false);
          const key = "updatable";
          message.error({
            content: error?.detail || error?.title || "Error, Something went wrong!",
            key,
            duration: 3
          });
        });
    };

    if (FunctionUtils.returnsPromise(validateForm)) {
      validateForm(action)
        .then((result: boolean) => {
          if (!result) {
            setFormInvalid(true);
          } else {
            setFormInvalid(false);
            return saveFormFunction();
          }
        });
    } else {
      if (!validateForm(action)) {
        setFormInvalid(true);
      } else {
        setFormInvalid(false);
        return saveFormFunction();
      }
    }
  }

  public static onClose(isFormChanged: Function, confirmAlert: Function, hide: Function, cancel?: Function) {
    if (isFormChanged()) {
      return confirmAlert({
        message: "This form contains unsaved changes. Do you still wish to leave the form?",
        overlayClassName: "sl-confirm-modal",
        buttons: [
          {
            label: "Cancel",
            onClick:  cancel ? () => { cancel(); } :() => {}
          },
          {
            label: "Leave",
            className: "confirm-btn",
            onClick: () => { hide(); }
          }
        ]
      });
    } else {
      hide();
    }
  }
}