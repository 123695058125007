import { observer } from "mobx-react-lite";
import { FC } from "react";
import { useRootStore } from "../../../stores/storeContext";
import {
  VQDynamicFormComponent,
  VQIcon, 
  VQRadio
} from "vivaquant-components-library";
import _uniqueId from "lodash.uniqueid";
import { DateUtils } from "../../../services/utils";
import EditorIcon from "../../../assets/icons/rotate-left.svg";
import { ETWIProps } from "../et-wi-forms.props";

export const ETFormWI059ERMain: FC<ETWIProps> = observer((props: ETWIProps) => {
  const {
    wiRev,
    failureMode,
    comment,
    setBooleanValue,
    visualInspection,
    activeLeadTestFixture,
    connectorCleanupFit,
    legsGluedProperly,
    deviceCharging,
    hardReset,
    transitionToReady,
    deviceInRecordingShowing3xGreenBlink,
    cellSignalMoreThen100,
    deviceReleasedAndShowsSolidGreen,
    leakTest,
    backLabelReApplied,
    deviceCleaned,
    errorOther
  } = useRootStore().wi059ERFormStore;

  const ETFormWI059EROptions = [
    {
      name: "visualInspection",
      value: visualInspection,
      title: "Visual inspection"
    },
    {
      name: "activeLeadTestFixture",
      value: activeLeadTestFixture,
      title: "Active lead test fixture (ALTF)"
    },
    {
      name: "connectorCleanupFit",
      value: connectorCleanupFit,
      title: "Connector cleanup/fit"
    },
    {
      name: "legsGluedProperly",
      value: legsGluedProperly,
      title: "Legs glued properly"
    },
    {
      name: "deviceCharging",
      value: deviceCharging,
      title: "Device charging"
    },
    {
      name: "hardReset",
      value: hardReset,
      title: "Hard reset"
    },
    {
      name: "transitionToReady",
      value: transitionToReady,
      title: "Transition to ready"
    },
    {
      name: "deviceInRecordingShowing3xGreenBlink",
      value: deviceInRecordingShowing3xGreenBlink,
      title: "Device in recording showing 3x green blink"
    },
    {
      name: "cellSignalMoreThen100",
      value: cellSignalMoreThen100,
      title: "Cell signal > 100"
    },
    {
      name: "deviceReleasedAndShowsSolidGreen",
      value: deviceReleasedAndShowsSolidGreen,
      title: "Device released and shows solid green over heart icon"
    },
    {
      name: "leakTest",
      value: leakTest,
      title: "Leak test"
    },
    {
      name: "backLabelReApplied",
      value: backLabelReApplied,
      title: "Back label re-applied"
    },
    {
      name: "deviceCleaned",
      value: deviceCleaned,
      title: "Device cleaned"
    }
  ];

  const handleChange = () => {};


  return (
    <>
      <div className="col-container">
        <VQDynamicFormComponent
          item={wiRev}
          customClass="col-container-el"
          errors={props.errors}
          handleChange={handleChange}
          showError={true}
          disabled={props.isDisabled}
          isFormInvalid={props.formInvalid}></VQDynamicFormComponent>
        <div className="col-container-el"></div>
      </div>

      <div>
        {ETFormWI059EROptions.map((el: any, index: number) => (
          <div className="opttion-container" key={index}>
            <div className="label">{el.title}</div>
            <div className="col-container col-container-el">
              <VQRadio 
                handleChange={() => { setBooleanValue(el.name, true); }}
                isChecked={el.value?.isPassed === true}
                label="Pass"
                name={el.name}
                disabled={props.isDisabled}
                id={_uniqueId()}
              ></VQRadio>
              <VQRadio 
                handleChange={() => { setBooleanValue(el.name, false); }}
                isChecked={el.value?.isPassed === false}
                label="Fail"
                name={el.name}
                disabled={props.isDisabled}
                id={_uniqueId()}
              ></VQRadio>
            </div>
            {(!props.isDisabledLastEditor && el.value?.updatedAt && el.value?.lastEditor) 
            && <div className="editor-info">
              <VQIcon icon={EditorIcon} padding="0 8px 0 0"></VQIcon>
              <div>
                {`${el.value.lastEditor.firstname} ${el.value.lastEditor.lastname}, `} 
                {DateUtils.formatDateTime(el.value.updatedAt)}
              </div>
            </div>}
          </div>
        ))}
      </div>
          
      <div className="col-container">
        {!failureMode.isLoading && <VQDynamicFormComponent
          item={failureMode}
          customClass="col-container-el"
          errors={props.errors}
          handleChange={handleChange}
          showError={true}
          disabled={props.isDisabled}
          isFormInvalid={props.formInvalid}></VQDynamicFormComponent>}
        <div className="col-container-el"></div>
      </div>

      {props.children}


      <VQDynamicFormComponent
        item={comment}
        errors={props.errors}
        handleChange={handleChange}
        showError={true}
        disabled={props.isDisabled}
        isFormInvalid={props.formInvalid}></VQDynamicFormComponent>
      {errorOther && <div className="invalid-text-error">{errorOther}</div>}
    </>
  );
});