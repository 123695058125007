import { API } from "../../boot/api";
import { IAddRouteConfiguration } from "../../models/IAddRouteConfiguration";
import { IEditRouteConfiguration } from "../../models/IEditRouteConfiguration";
import { IRouteConfigurationResponse } from "../../models/IRouteConfigurationResponse";
import { ApiResponseDto } from "../../models/api";
import { IRouteConfigurationModelSnapShot } from "../../stores/admin-route-configuration-list.store";
import { TApiResponse } from "../../types";
import { ProcessAPIError } from "../decorators";
import { BaseFilter } from "../filters/base.filter";

class RouteConfigurationApi {
  @ProcessAPIError()
  public async getCounters(filter: BaseFilter): Promise<TApiResponse<number>> {
    const result = await API.get("/route-configurations/count", { params: filter }).then(r => r.data);
    return new ApiResponseDto(result);
  }

  @ProcessAPIError()
  public async getRouteConfigurationsList(filter: BaseFilter)
  : Promise<TApiResponse<IRouteConfigurationModelSnapShot[]>> {
    const { data } = await API.get("/route-configurations",
      { params: filter });
    return new ApiResponseDto(data);
  }

  @ProcessAPIError()
  public async getRouteConfiguration(id: string): Promise<TApiResponse<IRouteConfigurationResponse>> {
    const { data } = await API.get(`/route-configurations/${id}`);
    return new ApiResponseDto(data);
  }

  @ProcessAPIError()
  public async addRouteConfiguration(data: IAddRouteConfiguration): Promise<TApiResponse<string>> {
    const result = await API.post("/route-configurations", data);
    return new ApiResponseDto(result.data);
  }

  @ProcessAPIError()
  public async editRouteConfiguration(data: IEditRouteConfiguration): Promise<TApiResponse<string>> {
    const result = await API.put("/route-configurations", data);
    return new ApiResponseDto(result.data);
  }
}

export const routeConfigurationApi = new RouteConfigurationApi();