import { observer } from "mobx-react-lite";
import { FC } from "react";
import { useRootStore } from "../../../stores/storeContext";
import { VQDynamicFormComponent, VQRadio } from "vivaquant-components-library";
import _uniqueId from "lodash.uniqueid";
import { ETWIProps } from "../et-wi-forms.props";

export const ETFormWI057Main: FC<ETWIProps> = observer((props: ETWIProps) => {
  const {
    wiRev,
    cellSignal,
    isChargeTestPassed,
    isVibrationPassed,
    isEcgPassed,
    failureMode,
    comment,
    setBooleanValue
  } = useRootStore().wi057FormStore;

  const handleChange = () => {};
  const handleChangeNumber = (e: any, item: any) => {
    item.setValue(item.value.replace(/[^0-9.]/g, ""));
  };

  return (
    <>
      <div className="col-container">
        <VQDynamicFormComponent
          item={wiRev}
          customClass="col-container-el"
          errors={props.errors}
          handleChange={handleChange}
          showError={true}
          disabled={props.isDisabled}
          isFormInvalid={props.formInvalid}></VQDynamicFormComponent>
      </div>
      <div className="col-container">
        <VQDynamicFormComponent
          item={cellSignal}
          customClass="col-container-el"
          errors={props.errors}
          handleChange={handleChangeNumber}
          showError={true}
          disabled={props.isDisabled}
          isFormInvalid={props.formInvalid}></VQDynamicFormComponent>
        {!failureMode.isLoading && <VQDynamicFormComponent
          item={failureMode}
          customClass="col-container-el"
          errors={props.errors}
          handleChange={handleChange}
          showError={true}
          disabled={props.isDisabled}
          isFormInvalid={props.formInvalid}></VQDynamicFormComponent>}
      </div>
      <div className="col-container">
        <div className="col-container-el label-input">Charge Test</div>
        <div className="col-container-el label-input">Vibration</div>
        <div className="col-container-el label-input">ECG</div>
      </div>
      <div className="col-container">
        <div className="col-container col-container-el">
          <VQRadio 
            handleChange={() => { setBooleanValue("isChargeTestPassed", true); }}
            isChecked={isChargeTestPassed === true}
            label="Pass"
            name="isChargeTestPassed"
            disabled={props.isDisabled}
            id={_uniqueId()}
          ></VQRadio>
          <VQRadio 
            handleChange={() => { setBooleanValue("isChargeTestPassed", false); }}
            isChecked={isChargeTestPassed === false}
            label="Fail"
            name="isChargeTestPassed"
            disabled={props.isDisabled}
            id={_uniqueId()}
          ></VQRadio>
        </div>
        <div className="col-container col-container-el">
          <VQRadio 
            handleChange={() => { setBooleanValue("isVibrationPassed", true); }}
            isChecked={isVibrationPassed === true}
            label="Pass"
            name="isVibrationPassed"
            disabled={props.isDisabled}
            id={_uniqueId()}
          ></VQRadio>
          <VQRadio 
            handleChange={() => { setBooleanValue("isVibrationPassed", false); }}
            isChecked={isVibrationPassed === false}
            label="Fail"
            name="isVibrationPassed"
            disabled={props.isDisabled}
            id={_uniqueId()}
          ></VQRadio>
        </div>
        <div className="col-container col-container-el">
          <VQRadio 
            handleChange={() => { setBooleanValue("isEcgPassed", true); }}
            isChecked={isEcgPassed === true}
            label="Pass"
            name="isEcgPassed"
            disabled={props.isDisabled}
            id={_uniqueId()}
          ></VQRadio>
          <VQRadio 
            handleChange={() => { setBooleanValue("isEcgPassed", false); }}
            isChecked={isEcgPassed === false}
            label="Fail"
            name="isEcgPassed"
            disabled={props.isDisabled}
            id={_uniqueId()}
          ></VQRadio>
        </div>
      </div>
      <VQDynamicFormComponent
        item={comment}
        errors={props.errors}
        handleChange={handleChange}
        showError={true}
        disabled={props.isDisabled}
        isFormInvalid={props.formInvalid}></VQDynamicFormComponent>
    </>
  );
});