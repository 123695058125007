export enum WI057FailureMode {
  None = 0,
  RedCellLed = 1,
  RedHeartLed = 2,
  NoCommunication = 3,
  PowerOnFailure = 4,
  ResetLoop = 5,
  ChargeFailure = 6,
  VibrationFailure = 7,
  AlarmsOnCharger = 8,
  EcgSnapFailure = 9,
  CellSignalFailure = 10,
  EcgDataFailure = 11,
  Unresponsive = 12,
  ConnectorFailure = 13,
  SimCardFailure = 14,
  SdCardFailure = 15,
  LedFailure = 16,
  Other = 17
}

export enum WI057FailureModeLabels {
  None = "None",
  RedCellLed = "Red Cell LED",
  RedHeartLed = "Red Heart LED",
  NoCommunication = "No Communication",
  PowerOnFailure = "Power On Failure",
  ResetLoop = "Reset Loop",
  ChargeFailure = "Charge Failure",
  VibrationFailure = "Vibration Failure",
  AlarmsOnCharger = "Alarms on Charger",
  EcgSnapFailure = "ECG Snap Failure",
  CellSignalFailure = "Cell Signal Failure",
  EcgDataFailure = "ECG Data Failure",
  Unresponsive = "Unresponsive",
  ConnectorFailure = "Connector Failure",
  SimCardFailure = "SIM Card Failure",
  SdCardFailure = "SD Card Failure",
  LedFailure = "LED Failure",
  Other = "Other"
}