import { observer } from "mobx-react-lite";
import { FC } from "react";
import { useRootStore } from "../../../stores/storeContext";
import _uniqueId from "lodash.uniqueid";
import { VQDynamicFormComponent, VQRadio } from "vivaquant-components-library";
import { ETWIProps } from "../et-wi-forms.props";

export const ETFormWI051Main: FC<ETWIProps> = observer((props: ETWIProps) => {
  const {
    wiRev,
    isHardResetPassed,
    failureMode,
    comment,
    setIsHardResetPassed,
  } = useRootStore().wi051FormStore;

  const handleChange = () => {};

  return (
    <>
      <div className="col-container">
        <VQDynamicFormComponent
          item={wiRev}
          customClass="col-container-el"
          errors={props.errors}
          handleChange={handleChange}
          showError={true}
          disabled={props.isDisabled}
          isFormInvalid={props.formInvalid}></VQDynamicFormComponent>
        {!failureMode.isLoading && <VQDynamicFormComponent
          item={failureMode}
          customClass="col-container-el"
          errors={props.errors}
          handleChange={handleChange}
          showError={true}
          disabled={props.isDisabled}
          isFormInvalid={props.formInvalid}></VQDynamicFormComponent>}
      </div>
      <div className="col-container">
        <div className="col-container-el label-input">Hard Reset</div>
      </div>
      <div className="col-container">
        <div className="col-container col-container-el">
          <VQRadio 
            handleChange={() => { setIsHardResetPassed(true); }}
            isChecked={isHardResetPassed === true}
            label="Pass"
            disabled={props.isDisabled}
            name="isHardResetPassed"
            id={_uniqueId()}
          ></VQRadio>
          <VQRadio 
            handleChange={() => { setIsHardResetPassed(false); }}
            isChecked={isHardResetPassed === false}
            label="Fail"
            disabled={props.isDisabled}
            name="isHardResetPassed"
            id={_uniqueId()}
          ></VQRadio>
        </div>
      </div>
      <VQDynamicFormComponent
        item={comment}
        errors={props.errors}
        handleChange={handleChange}
        showError={true}
        disabled={props.isDisabled}
        isFormInvalid={props.formInvalid}></VQDynamicFormComponent>
    </>
  );
});
