export const ADMIN_ROUTES = [
  {
    title: "User Management",
    link: "users",
    permission: true
  },
  {
    title: "Devices",
    link: "devices",
    permission: true
  },
  {
    title: "Configuration",
    link: "route-configuration",
    permission: true
  },
  // {
  //   title: "Data",
  //   link: "data",
  //   permission: true
  // }
];
