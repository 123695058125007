import { Instance, SnapshotIn, flow, types } from "mobx-state-tree";
import { AdminRouteConfigurationListSort, PAGINATION, SortDirection } from "../boot/constants";
import { IObservableArray } from "mobx";
import { BaseFilter } from "../services/filters/base.filter";
import { TApiResponse } from "../types";
import { routeConfigurationApi } from "../services/api";

const RouteConfigurationModel = types
  .model("RouteConfigurationModel", {
    id: types.maybe(types.string),
    devicesCount: types.maybe(types.number),
    name: types.maybe(types.string),
    workInstructions: types.optional(types.array(types.number), [])
  });

export interface IRouteConfigurationModel extends Instance<typeof RouteConfigurationModel> {}
export interface IRouteConfigurationModelSnapShot extends SnapshotIn<typeof RouteConfigurationModel> {}

export const AdminRouteConfigurationListModel = types
  .model("AdminRouteConfigurationListModel", {
    page: 0,
    total: 0,
    searchValue: types.maybe(types.string),
    sortBy: types.optional(types.string, AdminRouteConfigurationListSort.name),
    sortDirection: types.optional(types.number, SortDirection.ASC),
    isLoading: types.optional(types.boolean, true),
    items: types.optional(types.array(RouteConfigurationModel), []),
  })
  .actions(self => ({
    setLoading(isLoading: boolean) {
      self.isLoading = isLoading;
    },
    setList(dto: IRouteConfigurationModelSnapShot[]) {
      self.items = dto as IObservableArray;
    }
  }))
  .actions(self => {
    const setFirstPage = () => {
      self.page = 0;
    };

    const nextPage = () => {
      self.page++;
      getList();
    };

    const previousPage = () => {
      self.page--;
      getList();
    };

    const setDefaultParams = () => {
      self.page = 0;
      self.searchValue = "";
      self.sortBy = AdminRouteConfigurationListSort.name;
      self.sortDirection = SortDirection.ASC;
    };

    const setSearch = (searachValue? : string) => {
      self.searchValue = searachValue;
    };

    const setSortBy = (sortBy: string, sortDirection: number) => {
      self.sortBy = sortBy;
      self.sortDirection = sortDirection;
      getList();
    };

    const getTotalCount = flow(function* getTotalCount() {
      try {
        const filter = new BaseFilter();
        filter.search = self.searchValue || "";

        const result: TApiResponse<number> = yield routeConfigurationApi.getCounters(filter);
        if (result.isOk) {
          self.total = result.data;
          getList();
        }
      } catch (error) {
        console.error(error);
      }
    });

    const getList = flow(function* getList() {
      self.setLoading(true);

      try {
        const rows = self.total && ((self.page * PAGINATION.ROWS_PER_PAGE + PAGINATION.ROWS_PER_PAGE) > self.total)
          ? self.total - (self.page * PAGINATION.ROWS_PER_PAGE)
          : PAGINATION.ROWS_PER_PAGE;

        const filter = new BaseFilter();
        filter.skip = self.page * PAGINATION.ROWS_PER_PAGE;
        filter.take = rows;
        filter.search = self.searchValue || "";
        filter.sortBy = self.sortBy;
        filter.sortDirection = self.sortDirection;

        const result: TApiResponse<IRouteConfigurationModelSnapShot[]>
          = yield routeConfigurationApi.getRouteConfigurationsList(filter);
        if (result.isOk) {
          self.setList(result.data);
          self.setLoading(false);
        } else {
          self.setList([]);
          self.setLoading(false);
        }
      } catch (error) {
        console.error(error);
        self.setList([]);
        self.setLoading(false);
      }
    });
    
    
    return {
      nextPage,
      previousPage,
      getList,
      getTotalCount,
      setSearch,
      setSortBy,
      setFirstPage,
      setDefaultParams,
    };
  });