import { SnapshotIn, applySnapshot, flow, types } from "mobx-state-tree";
import { InputFormModel } from "vivaquant-components-library";
import { ValidationUtils } from "../services/utils";
import { AdminUsersInviteState } from "../states/admin-users-invite.state";
import { ErrorMessages } from "../boot/constants";
import { inviteAPI, userAPI } from "../services/api";
import { ApiResponseDto } from "../models/api";
import { TApiResponse } from "../types";

export const AdminUsersInviteModel = types
  .model("AdminUsersInviteModel", {
    show: types.optional(types.boolean, false),
    isEdit: types.optional(types.boolean, false),
    idUser: types.maybeNull(types.number),
    name: types.maybe(InputFormModel),
    lastName: types.maybe(InputFormModel),
    email: types.maybe(InputFormModel),
    location: types.maybe(InputFormModel),
    typeUser: types.maybe(InputFormModel),
    saveError: types.optional(types.string, "")
  })
  .actions(self => ({
    setEdit(isEdit: boolean) {
      self.isEdit = isEdit;
    },
    setSaveError(error: string) {
      self.saveError = error;
    }
  }))
  .actions(self => {
    const setId = (id: number) => {
      self.idUser = id;
    };

    const resetForm = () => {
      self.isEdit = false;
      self.idUser = null;
      self.saveError = "";
      applySnapshot(self, AdminUsersInviteState);
    };

    const openModal = () => {
      self.show = true;
    };

    const closeModal = () => {
      self.show = false;
      resetForm();
    };

    const validateFormFields = (): {errors: {[key: string]: string}, isValid: boolean} => {
      const errors: {[key: string]: string} = {};
      let isValid: boolean = true;

      if (!self.name?.value) {
        errors.name = ErrorMessages.emptyField;
        isValid = false;
      }

      if (!self.lastName?.value) {
        errors.lastName = ErrorMessages.emptyField;
        isValid = false;
      }

      if (!self.typeUser.value && (!self.typeUser.valueMulti || !self.typeUser.valueMulti[0])) {
        errors.typeUser = ErrorMessages.emptyField;
        isValid = false;
      }

      if (!self.email?.value) {
        errors.email = ErrorMessages.emptyField;
        isValid = false;
      } else {
        if (!ValidationUtils.isEmailValid(self.email?.value)) {
          errors.email = ErrorMessages.emptyField;
          isValid = false;
        }
      }

      return {
        errors,
        isValid
      };
    };

    const saveForm = flow(function* saveForm() {
      const data: any = {
        firstName: self.name.value.trim(),
        lastName: self.lastName.value.trim(),
        email: self.email.value,
        location: self.location.value,
        groups: self.typeUser.valueMulti.map((item: string) => {
          return item;
        })
      };

      try {
        const result = self.isEdit
          //? yield adminUsersApi.updateAdmin(data, self.idUser)
          ? console.log("Update")
          : yield inviteAPI.addAdmin(data);
        if (!result.isOk) {
          self.setSaveError(result.error?.detail || result.error?.title || "Error, Something went wrong!");
          return false;
        }
        return true;
      } catch (error) {
        console.error(error);
        return false;
      }
    });

    const fetchData = flow(function* fetchData(id?: number) {
      if (id) {
        self.idUser = id;
        self.isEdit = true;
      } else {
        self.isEdit = false;
      }
      yield fetchGroupsAllowed();
      if (self.isEdit) {
        fetchAdmin();
      }
    });

    const fetchGroupsAllowed = flow(function* fetchGroupsAllowed() {
      const { data }: ApiResponseDto<any> = yield inviteAPI.getGroupsAllowed();
      const options = data.map((item: any) => {
        return { value: item.id.toString(), label: item.name };
      });
      self.typeUser.setOptions(options);
    });

    const fetchAdmin = flow(function* fetchAdmin() {
      try {
        const response: TApiResponse<any> = yield userAPI.getAdminInfo(self.idUser);
        if (response.isOk) {
          const { data } = response.data;
          self.name.setValue(data.user.firstname || "");
          self.lastName.setValue(data.user.lastname || "");
          self.email.setValue(data.user.email || "");
          self.location.setValue(data.user.location || "");
          if (data.user.groups && data.user.groups.length) {
            self.typeUser.setValueMultiply(data.user.groups.map((el: any) => el.id.toString()));
            self.typeUser.setDefaultMultiValue(data.user.groups.map((el: any) => el.id.toString()));
          }
        }
      } catch (error) {
        console.error(error);
      }
    });

    return {
      openModal,
      closeModal,
      saveForm,
      validateFormFields,
      resetForm,
      fetchData,
      setId
    };
  });

export interface IAdminUsersInviteModelSnapShot extends SnapshotIn<typeof AdminUsersInviteModel> {}