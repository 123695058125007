import styled from "styled-components";
import { theme } from "../../theme/theme";

export const VQUserProfileWrapper = styled.div`
  position: relative;

  .container {
    width: 584px;
    padding-top: 40px;

    .title {
      font-weight: 400;
      font-size: 24px;
      line-height: 28px;
      color: ${theme.colors.text};
      padding-top: 8px;
    }

    form {
      margin-top: 20px;
    }

    .btn-container {
      padding-top: 32px;
      display: flex;
      justify-content: flex-end;
      gap: 16px;
    }

    .signAs {
      font-size: 14px;
      font-weight: 400;
      line-height: 16px;
      letter-spacing: 0em;
      color: ${theme.colors.label};
      padding-bottom: 24px;
      padding-top: 24px;
      margin-top: 16px;
      margin-bottom: 16px;
      border-top: 1px solid ${theme.colors.background};
      border-bottom: 1px solid ${theme.colors.background};

      span {
        font-weight: 500;
        font-size: 15px;
      }

      .title {
        font-size: 18px;
        line-height: 20px;
        color: ${theme.colors.text};
        margin-bottom: 24px;
      }
    }

    .switch-container {
      position: relative;

      .label {
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 16px;
        color: #6D6D6D;
        padding-top: 16px;
        margin-top: 12px;
        border-top: 1px solid ${theme.colors.greyDivider};
      }

      .enable-container {
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 16px;
        color: #6D6D6D;
        padding-top: 8px;
      }

      .switch {
        display: inline-block;
        width: 42px;
        height: 24px;
        position: absolute;
        right: 0;
        top: 12px;
        bottom: 0;
        margin: auto;
      }
      .switch input {
        position: absolute;
        top: -99999px;
        left: -99999px;
      }
      .slider {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #E5E5E5;
        transition: .4s;
        border-radius: 34px;
      }
      .slider:before {
        position: absolute;
        content: "";
        height: 18px;
        width: 18px;
        left: 3px;
        bottom: 3px;
        background-color: #BCBCBC;
        transition: .4s;
        border-radius: 50%;
      }
      input:checked + .slider {
        background-color: #C1D4FB;
      }
      input:focus + .slider {
        box-shadow: 0 0 1px #C1D4FB;
      }
      input:checked + .slider:before {
        transform: translateX(18px);
        background-color: #457EF5;
      }
    }
  }
`;

export const ContainerCodeWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 64px;
  margin-bottom: 32px;
  .container-code {
    width: 580px;
    .label {
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 16px;
      color: ${theme.colors.placeholder};
      padding-top: 40px;
      padding-left: 57px;
    }

    .auth-code-container {
      display: flex;
      justify-content: center;
      margin-top: 16px;
      
      .auth-code-input {
        height: 64px;
        width: 64px;
        border: 1px solid #D1D7E3;
        font-style: normal;
        font-weight: normal;
        font-size: 28px;
        line-height: 28px;
        color: ${theme.colors.text};
        border-radius: 12px;
        text-align: center;
        margin-left: 16px;
      }
      .auth-code-input:first-child {
        margin-left: 0;
      }

      .error {
        border-color: ${theme.colors.red};
      }
    }

    .retry {
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 18px;
      color: ${theme.colors.blue};  
      margin-top: 16px;
      margin-bottom: 16px;
      text-align: center;

      span {
        cursor: pointer;
      }
    }

    .error-code {
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 14px;
      padding-left: 57px;
      color: ${theme.colors.red};
      margin-top: 16px;
      margin-bottom: 26px;
    }
  }
`;