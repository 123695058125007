import { observer } from "mobx-react-lite";
import { FC, FormEventHandler, useState } from "react";
import { useRootStore } from "../../stores/storeContext";
import { Modal } from "react-bootstrap";
import { VQBlueButton, VQIcon, VQLightButton, VQModalClose } from "vivaquant-components-library";
import { ETAdminDevicesAddModalWrapper } from "./et-admin-devices-add-modal.presents";
import { FileUploader } from "react-drag-drop-files";
import CsvIcon from "../../assets/icons/csv.svg";
import DocumentIcon from "../../assets/icons/document.svg";
import ErrorIcon from "../../assets/icons/error.svg";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import { theme } from "../../theme/theme";
import { confirmAlert } from "react-confirm-alert";
import InfoFile from "../../assets/file/ET_devices_import.csv";


export const ETAdminDevicesAddModal: FC = observer(() => {
  const fileTypes = ["CSV", "XLSX"];
  const {
    show,
    isLoading,
    isUploaded,
    errorMessages,
    closeModal,
    checkDevices,
    addDevices,
    progressUpload,
    resetForm,
    setLoading
  } = useRootStore().adminDevicesAddStore;
  const { getTotalCount } = useRootStore().adminDevicesListStore;


  const [file, setFile] = useState(null);

  const hide = () => {
    closeModal();
  };

  const onAddDevice = () => {
    addDevices(file)
      .then((result: boolean) => {
        if (result) {
          setTimeout(() => {
            getTotalCount();
            hide();
            confirmAlert({
              message: "New devices have been successfully added",
              overlayClassName: "sl-success-modal",
              buttons: [
                {
                  label: "Ok",
                  onClick: () => {}
                }
              ]
            });
          }, 1000);
        }
      });
  };

  const onSubmit: FormEventHandler = (event) => {
    event.preventDefault();
    checkDevices(file)
      .then((isSuccessful: boolean) => {
        if (isSuccessful) {
          onAddDevice();
        } else {
          return confirmAlert({
            // eslint-disable-next-line max-len
            message: "The file you're importing contains device serial numbers that already exist in the system. Proceeding will overwrite existing device information. Are you sure you want to continue?",
            overlayClassName: "sl-confirm-modal",
            buttons: [
              {
                label: "Cancel",
                onClick: () => {
                  setLoading(false);
                }
              },
              {
                label: "Confirm",
                className: "confirm-btn",
                onClick: () => { onAddDevice(); }
              }
            ]
          });
        }
      });
    
  };

  const handleUpload = (file: File) => {
    setFile(file);
  };

  const removeFile = () => {
    setFile(null);
    resetForm();
  };

  const getHelpContainer = () => {
    return (
      <div className="help-container">
        <div className="text">
          Need Help? <a href={InfoFile} download="ET_devices_import.csv">
          Download our CSV template</a> to make sure your CSV is formatted correctly.
        </div>
      </div>
    );
  };

  return (
    <Modal
      onHide={hide}
      show={show}
      onShow={removeFile}
      centered
      dialogClassName="invite-admin-modal">
      <Modal.Body>
        <VQModalClose onHide={hide}></VQModalClose>
        <ETAdminDevicesAddModalWrapper>
          <div className="form-container">
            <div className="title">Add New Devices</div>
            <form onSubmit={onSubmit}>
              <div className="upload-container">
                {!file && <FileUploader 
                  handleChange={handleUpload} 
                  name="file" 
                  types={fileTypes}
                >
                  <div className="upload-content">
                    <div className="content">
                      <VQIcon icon={CsvIcon} width="56px" height="56px"></VQIcon>
                      <div className="text">
                        Drag .csv or .xlsx document to start uploading
                      </div>
                      <div className="devider">
                        <div></div>
                        <label>OR</label>
                        <div></div>
                      </div>
                      <VQBlueButton type="button" text="Browse File" click={() => {}}/>
                    </div>

                  </div>
                </FileUploader>}
                {(file && !isLoading && !isUploaded) && <div className="upload-content selected">
                  <div className="content">
                    <VQIcon icon={DocumentIcon} width="56px" height="56px"></VQIcon>
                    <div className="text m-b-40">{file.name}</div>
                    <VQLightButton type="button" text="Remove" click={removeFile}/>
                  </div>
                </div>}
                {(file && isLoading && !isUploaded) && <div className="upload-content selected">
                  <div className="content">
                    <div className="progressbar">
                      <CircularProgressbar
                        value={progressUpload} 
                        text={`${progressUpload}%`}
                        styles={buildStyles({
                          pathColor: `${theme.colors.blue}`,
                          trailColor: `${theme.colors.uploadBorder}`,
                          textColor: `${theme.colors.text}`,
                          textSize: "24px"
                        })}/>
                    </div>
                    <div className="text m-b-40">Adding</div>
                  </div>
                </div>}
                {(isUploaded && errorMessages) && <div className="upload-content selected-error">
                  <div className="content">
                    <VQIcon icon={ErrorIcon} width="56px" height="56px"></VQIcon>
                    <div className="text m-b-40 error">Syntax Error</div>
                    <VQLightButton type="button" text="Remove" click={removeFile}/>
                  </div>  
                </div>} 
              </div>

              {(isUploaded && errorMessages) && <div className="alert-container">
                {errorMessages.map((el: string, index: number) => (
                  <div key={index} className="alert-text">{el}</div>
                ))}
              </div>}

              {getHelpContainer()}
              <div className="btn-container">
                <VQLightButton type="button" text="Cancel" click={hide}/>
                <VQBlueButton 
                  type="submit"
                  isDisabled={!file || isLoading || isUploaded}
                  text="Add Device" 
                  click={() => {}}/>
              </div>
            </form>
          </div>
        </ETAdminDevicesAddModalWrapper>
      </Modal.Body>
    </Modal>
  );
});