import styled from "styled-components";
import { theme } from "../../theme/theme";

export const ETAdminDevicesAddModalWrapper = styled.div`
  .title {
    font-weight: normal;
    font-size: 24px;
    line-height: 28px;
    color: ${theme.colors.text};
  }

  .form-container {
    padding: 40px;

    .upload-container {
      display: flex;
      justify-content: center;
      margin-top: 40px;
      outline: none;
      
      label {
        margin-bottom: 0px;
      }

      input {
        outline: none;
      }

      .upload-content {
        width: 600px;
        height: 278px;
        border-radius: 20px; 
        border: 2px dashed ${theme.colors.uploadBorder}; 
        display: flex;
        justify-content: center;
        padding-top: 40px;
        padding-bottom: 40px;

        .content {
          width: 320px;
          display: flex;
          flex-direction: column;
          align-items: center;

          .text {
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 16px;
            color: ${theme.colors.text};
            width: 230px;
            text-align: center;
            margin-top: 16px;
          }

          .error {
            color: ${theme.colors.red};
          }

          .m-b-40 {
            margin-bottom: 40px;
          }

          .devider {
            display: flex;
            align-items: center;
            gap: 18px;
            margin-top: 16px;
            margin-bottom: 16px;
            width: 100%;

            label {
              font-size: 12px;
              font-style: normal;
              font-weight: 400;
              line-height: 14px;
              color: ${theme.colors.darkGreen};
              margin: 0;
            }

            div {
              height: 1px;
              background-color: ${theme.colors.greyDivider};
              flex: 1;
            }
          }

          .progressbar {
            width: 100px;
            height: 100px;
          }
        }
      }

      .selected {
        border: 2px solid ${theme.colors.uploadBorder};
      }

      .selected-error {
        border: 2px solid ${theme.colors.pink};
      }
    }

    .alert-container {
      border-radius: 18px;
      border: 2px solid ${theme.colors.pink};
      padding: 24px;
      height: 140px;
      width: 600px;
      margin: auto;
      margin-top: 16px;
      overflow-y: scroll;
      position: relative;

      &::-webkit-scrollbar {
        width: 16px;
        background-color: transparent;
      }

      &::-webkit-scrollbar-track {
        background-color: ${theme.colors.pink};
        border-radius: 0 10px 10px 0;
      }

      &::-webkit-scrollbar-thumb {
        background-color: #F9C5C5;
        border: 4px solid transparent;
        background-clip: content-box;
      }

      &::-webkit-scrollbar-button {
        width: 0;
        height: 0;
        display: none;
      }

      .alert-text {
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 18px;
        color: ${theme.colors.red};
      }
    }

    .help-container {
      color: ${theme.colors.text};
      margin-top: 32px;
      margin-bottom: 40px;
      display: flex;
      justify-content: center;
      
      .text {
        width: 270px;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 16px;
        text-align: center; 
        a {
          color: ${theme.colors.text};
          text-decoration: underline;
        }
      }
    }

    .btn-container {
      display: flex;
      justify-content: flex-end;

      div:last-child {
        margin-left: 24px;
      }
    }
  }
`;
