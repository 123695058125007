export enum WI058FailureMode {
  None = 0,
  UnrepairableLeak = 1,
  Other = 2
}

export enum WI058FailureModeLabels {
  None = "None",
  UnrepairableLeak = "Unrepairable Leak",
  Other = "Other"
}