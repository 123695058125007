export enum WI068FailureMode {
  None = 0,
  SDCardFailure = 1,
  MCUnlockError = 2,
  DebugInFailure = 3,
  DebugOutFailure = 4,
  UnableToFindBoard = 5,
  Other = 6,
}

export enum WI068FailureModeLabels {
  None = "None",
  SDCardFailure = "SD Card Failure",
  MCUnlockError = "MC Unlock Error",
  DebugInFailure = "Debug In Failure",
  DebugOutFailure = "Debug Out Failure",
  UnableToFindBoard = "Unable to find board reprogram justification",
  Other = "Other",
}