import { API } from "../../boot/api";
import { IBatche } from "../../models/IBatche";
import { ApiResponseDto } from "../../models/api";
import { TApiResponse } from "../../types";
import { ProcessAPIError } from "../decorators";
import { BaseFilter } from "../filters/base.filter";

class BatchesApi {
  @ProcessAPIError()
  public async getBatchesList(filter: BaseFilter)
  : Promise<TApiResponse<IBatche[]>> {
    const { data } = await API.get("/batches",
      { params: filter });
    return new ApiResponseDto(data);
  }
}

export const batchesApi = new BatchesApi();