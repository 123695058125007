import { SnapshotIn, applySnapshot, flow, types } from "mobx-state-tree";
import { InputFormModel } from "vivaquant-components-library";
import { NCMDispositionState } from "../states/ncm-disposition.state";
import { TApiResponse } from "../types";
import { INCMDispositionResponse } from "../models/INCMDispositionResponse";
import { ncmDevicesApi, routeConfigurationApi } from "../services/api";
import { IRouteConfigurationModelSnapShot } from "./admin-route-configuration-list.store";
import { AdminRouteConfigurationListSort, SortDirection } from "../boot/constants";
import { INCMDispositionRequest } from "../models/INCMDispositionRequest";
import { NcmDeviceActionType } from "../boot/enums/NcmDeviceActionType";
import { NcmDeviceDisposition } from "../boot/enums/NcmDeviceDisposition";
import { RouteConfigurationsFilter } from "../services/filters/route-configurations.filter";
import { NcmDeviceStage } from "../boot/enums/NcmDeviceStage";
import { WorkInstructionType } from "../boot/enums/WorkInstructionType";

export const NCMDispositionModel = types
  .model("NCMDispositionModel", {
    show: types.optional(types.boolean, false),
    id: types.maybeNull(types.string),
    deviceDisposition: types.maybeNull(types.number),
    serialNumber: types.maybeNull(types.string),
    stage: types.maybeNull(types.number),
    ncmNumber: types.maybe(InputFormModel),
    comment: types.maybe(InputFormModel),
    routeConfiguration: types.maybe(InputFormModel),
    canMoveToReleased: types.optional(types.boolean, false),
    baseData: types.frozen(),
    isNextDevice: types.optional(types.boolean, false)
  })
  .actions(self => {
    const resetForm = () => {
      self.id = "";
      self.serialNumber = "";
      self.stage = null;
      self.deviceDisposition = 0;
      self.canMoveToReleased = false;
      self.baseData = {};
      self.isNextDevice = false;
      applySnapshot(self, NCMDispositionState);
    };

    const openForm = () => {
      self.show = true;
    };

    const closeForm = () => {
      self.show = false;
      resetForm();
    };

    const validateField = (action: number): {errors: {[key: string]: string}, isValid: boolean} => {
      const errors: {[key: string]: string} = {};
      let isValid: boolean = true;
      
      if (action === NcmDeviceActionType.Submit) {
        if (!self.comment?.value) {
          errors.comment = "Please fill the field";
          isValid = false;
        } else {
          if (self.comment?.value.length > 300) {
            errors.comment = "Maximum 300 characters required";
            isValid = false;
          }
        }

        if (!self.ncmNumber?.value) {
          errors.ncmNumber = "Please fill the field";
          isValid = false;
        } else {
          if (self.ncmNumber?.value.length !== 5) {
            errors.ncmNumber = "NCM Number must be in NCMR-00000 format";
            isValid = false;
          }
        }

        if (self.deviceDisposition === NcmDeviceDisposition.InProgress && !self.routeConfiguration?.value) {
          errors.routeConfiguration = "Please fill the field";
          isValid = false;
        }
      }

      return {
        errors,
        isValid
      };
    };

    const fetchData = flow(function* fetchData(id: string) {
      try {
        const response: TApiResponse<INCMDispositionResponse> = yield ncmDevicesApi.getNCMDevice(id);
        if (response.isOk) {
          const { data } = response;
          self.id = data.id;
          self.deviceDisposition = data.deviceDisposition;
          self.serialNumber = data.serialNumber;
          self.canMoveToReleased = data.canMoveToReleased;
          self.stage = data.stage;
          
          self.ncmNumber.setValue(data.ncmNumber.toUpperCase().replace("NCMR-", ""));
          self.comment.setValue(data.comment);


          const filter = new RouteConfigurationsFilter();
          filter.take = 9999;
          filter.search = "";
          filter.sortBy = AdminRouteConfigurationListSort.name;
          filter.sortDirection = SortDirection.ASC;
  
          if (self.stage === NcmDeviceStage.NcmWIER) {
            filter.workInstructionsCount = 1;
            filter.workInstructionType = WorkInstructionType.WIER;
          }
          
          const resultRouteConfiguration: TApiResponse<IRouteConfigurationModelSnapShot[]>
            = yield routeConfigurationApi.getRouteConfigurationsList(filter);
  
          if (resultRouteConfiguration.isOk) {
            self.routeConfiguration.setOptions(
              resultRouteConfiguration.data.map((item: IRouteConfigurationModelSnapShot) => {
                return { value: item.id.toString(), label: item.name };
              }));
          } else {
            self.routeConfiguration.setOptions([]);
          }
          
          self.routeConfiguration.setLoading(true);
          const option = self.routeConfiguration.options
            .find((item) => item.value === data.routeConfiguration.id.toString());
          if (option) {
            self.routeConfiguration.setValue(data.routeConfiguration.id.toString());
            self.routeConfiguration.setDefaultValue({ value: option.value.toString(), label: option.label });
          } else {
            self.routeConfiguration.setValue("");
            self.routeConfiguration.setDefaultValue({  value: "", label: "" });
          }
          setTimeout(() => {
            self.routeConfiguration.setLoading(false);
          }, 2);

          self.baseData = {
            ncmNumber: data.ncmNumber.toUpperCase().replace("NCMR-", ""),
            comment: data.comment,
            routeConfiguration: option ? data.routeConfiguration.id : "",
            deviceDisposition: data.deviceDisposition
          };

        }
      } catch (error) {
        console.error(error);
      }
      
    });

    const saveForm = flow(function* saveForm(action: number) {
      try {
        const data: INCMDispositionRequest = {
          deviceId: self.id,
          routeConfigurationId: self.routeConfiguration.value ? self.routeConfiguration.value : null,
          ncmNumber: "NCMR-" + self.ncmNumber.value,
          comment: self.comment.value,
          deviceDisposition: self.deviceDisposition,
          action: action
        };
        const result = yield ncmDevicesApi.saveNCMDevice(data);
        return result;
      } catch (error) {
        console.error(error);
        return error;
      }
    });

    const isFormChanged = () => {
      if ( self.ncmNumber.value !== self.baseData.ncmNumber
        || self.comment.value !== self.baseData.comment
        || self.routeConfiguration.value !== self.baseData.routeConfiguration
        || self.deviceDisposition !== self.baseData.deviceDisposition
      ) {
        return true;
      } else {
        return false;
      }
    };

    const setIsNextDevice = (value: boolean) => {
      self.isNextDevice = value;
    };

    const setDeviceDisposition = (value: number) => {
      self.deviceDisposition = value;
    };

    return {
      fetchData,
      openForm,
      closeForm,
      resetForm,
      validateField,
      saveForm,
      isFormChanged,
      setIsNextDevice,
      setDeviceDisposition
    };
  });

export interface INCMDispositionModelSnapShot extends SnapshotIn<typeof NCMDispositionModel> {}
