import { FC } from "react";
import { PropsType } from "./et-right-form.props";
import { ETRightFormWrapper } from "./et-right-form.presents";
import CloseIcon from "../../assets/icons/close.svg";
import { VQIcon } from "vivaquant-components-library";

export const ETRightForm: FC<PropsType> = (props: PropsType) => {
  return (
    <ETRightFormWrapper>
      <div className="title">
        {props.title}
        {props.subTitle && <div className="sub-title">{props.subTitle}</div>}
      </div>
      {props.onClose && <div className="close" onClick={() => props.onClose()}>
        <VQIcon icon={CloseIcon}></VQIcon>
      </div>}
      {props.children}
    </ETRightFormWrapper>
  );
};