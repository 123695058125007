import { RB_API_URL } from "../../boot/constants";
import { API } from "../../boot/api";
import { ApiResponseDto } from "../../models/api";
import { TApiResponse } from "../../types";
import { ProcessAPIError } from "../decorators";

class ReportApi {
  @ProcessAPIError()
  public async getReport(id: string): Promise<TApiResponse<any>> {
    const result = await API.get(`${RB_API_URL}/reports/e-traveller/${id}`, {
      responseType: "blob",
      headers: {
        "Content-Type": "application/json",
        "Accept": "application/pdf"
      }
    });
    return new ApiResponseDto(result);
  }
}


export const reportApi = new ReportApi();