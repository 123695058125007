import { WI083FailureMode, WI083FailureModeLabels } from "../boot/enums/WI083FailureMode";
import { IWI083FormModelSnapShot } from "../stores/wi083-form.store";

export const WI083FormState: IWI083FormModelSnapShot = {
  wiRev: {
    name: "WI REV",
    value: "",
    type: "input",
    fieldId: "wiRev"
  },
  previousFwVersion: {
    name: "Previous FW Version",
    value: "",
    type: "input",
    fieldId: "previousFwVersion",
  },
  finalFwVersion: {
    name: "Final FW Version",
    value: "",
    type: "input",
    fieldId: "finalFwVersion",
  },
  failureMode: {
    name: "Failure Mode",
    value: WI083FailureMode.None.toString(),
    type: "select",
    fieldId: "failureMode",
    options: Object.keys(WI083FailureMode)
      .filter((v) => isNaN(Number(v)))
      .map((key: string) => {
        return {
          value: WI083FailureMode[key as keyof typeof WI083FailureMode].toString(),
          label: WI083FailureModeLabels[key as keyof typeof WI083FailureMode],
        };
      }),
    defaultValue: { value: WI083FailureMode.None.toString(), label: WI083FailureModeLabels.None },
  },
  comment: {
    name: "Comment",
    value: "",
    type: "textarea",
    fieldId: "comment"
  },
};
