import { Permissions } from "../../services/permission.service";

export const DASHBOARD_ROUTES = [
  {
    link: "in-progress",
    title: "In Progress",
    permission: true
  },
  {
    link: "released",
    title: "Released",
    permission: true
  },
  {
    link: "ncm",
    title: "NCM",
    permission: true
  },
  {
    link: "admin",
    title: "Admin",
    permission: Permissions.UserInvite
  }
];
