export enum ReleasedDeviceStage {
  InStock = 0,
  Shipped = 1,
  FunctionalDemo = 2
}

export enum ReleasedDeviceStageLabels {
  InStock = "In Stock",
  Shipped = "Shipped",
  FunctionalDemo = "Functional Demo"
}