import { API } from "../../boot/api";
import { INCMCountResponse } from "../../models/INCMCountResponse";
import { INCMDispositionRequest } from "../../models/INCMDispositionRequest";
import { INCMDispositionResponse } from "../../models/INCMDispositionResponse";
import { ApiResponseDto } from "../../models/api";
import { INCMModelSnapShot } from "../../stores/ncm-list.store";
import { TApiResponse } from "../../types";
import { ProcessAPIError } from "../decorators";
import { NCMListFilter } from "../filters/ncm.filter";

class NCMDevicesApi {
  @ProcessAPIError()
  public async getCounters(filter: NCMListFilter): Promise<TApiResponse<INCMCountResponse>> {
    const result = await API.get("/ncm-devices/count", { params: filter }).then(r => r.data);
    return new ApiResponseDto(result);
  }

  @ProcessAPIError()
  public async getList(filter: NCMListFilter)
  : Promise<TApiResponse<INCMModelSnapShot[]>> {
    const { data } = await API.get("/ncm-devices",
      { params: filter });
    return new ApiResponseDto(data);
  }

  @ProcessAPIError()
  public async getExport(filter: NCMListFilter): Promise<TApiResponse<any>> {
    const result = await API.get("/ncm-devices/export",
      { params: filter });
    return new ApiResponseDto(result);
  }

  @ProcessAPIError()
  public async getNCMDevice(id: string): Promise<TApiResponse<INCMDispositionResponse>> {
    const { data } = await API.get(`/ncm-devices/${id}`);
    return new ApiResponseDto(data);
  }

  @ProcessAPIError()
  public async saveNCMDevice(data: INCMDispositionRequest): Promise<TApiResponse<string>> {
    const result = await API.patch("/ncm-devices", data);
    return new ApiResponseDto(result.data);
  }
}

export const ncmDevicesApi = new NCMDevicesApi();