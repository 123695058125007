import { WI056FailureMode, WI056FailureModeLabels } from "../boot/enums/WI056FailureMode";
import { IWI056FormModelSnapShot } from "../stores/wi056-form.store";

export const WI056FormState: IWI056FormModelSnapShot = {
  wiRev: {
    name: "WI REV",
    value: "",
    type: "input",
    fieldId: "wiRev"
  },
  programmingFixture: {
    name: "Programming Fixture",
    value: "",
    type: "input",
    fieldId: "programmingFixture"
  },
  failureMode: {
    name: "Failure Mode",
    value: WI056FailureMode.None.toString(),
    type: "select",
    fieldId: "failureMode",
    options: Object.keys(WI056FailureMode)
      .filter((v) => isNaN(Number(v)))
      .map((key: string) => {
        return {
          value: WI056FailureMode[key as keyof typeof WI056FailureMode].toString(),
          label: WI056FailureModeLabels[key as keyof typeof WI056FailureMode],
        };
      }),
    defaultValue: { value: WI056FailureMode.None.toString(), label: WI056FailureModeLabels.None },
  },
  comment: {
    name: "Comment",
    value: "",
    type: "textarea",
    fieldId: "comment"
  },
};
