import { WI051FailureMode, WI051FailureModeLabels } from "../boot/enums/WI051FailureMode";
import { IWI051FormModelSnapShot } from "../stores/wi051-form.store";

export const WI051FormState: IWI051FormModelSnapShot = {
  wiRev: {
    name: "WI REV",
    value: "",
    type: "input",
    fieldId: "wiRev"
  },
  failureMode: {
    name: "Failure Mode",
    value: WI051FailureMode.None.toString(),
    type: "select",
    fieldId: "failureMode",
    options: Object.keys(WI051FailureMode)
      .filter((v) => isNaN(Number(v)))
      .map((key: string) => {
        return {
          value: WI051FailureMode[key as keyof typeof WI051FailureMode].toString(),
          label: WI051FailureModeLabels[key as keyof typeof WI051FailureMode],
        };
      }),
    defaultValue: { value: WI051FailureMode.None.toString(), label: WI051FailureModeLabels.None },
  },
  comment: {
    name: "Comment",
    value: "",
    type: "textarea",
    fieldId: "comment"
  },
};
