import { ETColors } from "./colors";
import { ETOffsets } from "./offsets";
import { ETSize } from "./size";

export const theme = {
  colors: ETColors,
  offsets: ETOffsets,
  size: ETSize
};

export type ETTheme = typeof theme;
