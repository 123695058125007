import styled from "styled-components";
import { theme } from "../../theme/theme";

export const ETAdminWrapper = styled.div`
  position: relative;
  height: ${`calc(100% - ${theme.size.header})`};

  .navigation {
    background: #f7f7f7;
    box-shadow: 8px 8px 16px rgba(0, 0, 0, 0.04);
    height: ${theme.size.header};
    padding-left: ${theme.offsets.mainContent};
    padding-right: ${theme.offsets.mainContent};
    display: flex;
    align-items: center;
    z-index: 2;
    justify-content: center;
  }

  .content {
    margin: 24px 60px 24px 60px;
    background-color: #fff;
    border-radius: 16px;
    box-shadow: 8px 8px 16px 0 #0000000A;
    box-sizing: border-box;
    padding: 24px;
    max-height: ${`calc(100vh - 2 * ${theme.size.header} - 48px)`};
    overflow: hidden;
  }
`;
