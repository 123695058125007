import { AxiosResponseHeaders, RawAxiosResponseHeaders } from "axios";

export class ApiErrorDto {
  public readonly isOk: false = false;
  public readonly error: string;
  public readonly status: number;
  public readonly headers?: RawAxiosResponseHeaders | AxiosResponseHeaders = null;

  constructor(status: number, error: string, headers?: RawAxiosResponseHeaders | AxiosResponseHeaders) {
    this.status = status;
    this.error = error;
    this.headers = headers || null;
  }
}
