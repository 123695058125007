import { Instance, SnapshotIn, cast, flow, types } from "mobx-state-tree";
import { 
  InStockLocationOptions, 
  PAGINATION, 
  ReleasedListCategories, 
  ReleasedListSort, 
  SortDirection 
} from "../boot/constants";
import { IObservableArray } from "mobx";
import { ReleasedListFilter } from "../services/filters/released.filter";
import { TApiResponse } from "../types";
import { IReleasedCountResponse } from "../models/IReleasedCountResponse";
import { releasedDevicesApi } from "../services/api";
import { ExportUtils } from "../services/utils";
import { ReleasedDeviceStage } from "../boot/enums/ReleasedDeviceStage";
import { ReportInfoModel } from "./in-progress-list.store";

const getCategoriesTotals = () => {
  const obj: any = {
    AllCategoryTotal: types.optional(types.number, 0),
  };
  ReleasedListCategories.forEach(el => {
    const keyName = `${el.state}CategoryTotal`;
    obj[keyName] = types.optional(types.number, 0);
  });

  return obj;
} ;

const categoriesTotals = getCategoriesTotals();

const ReleasedModel = types
  .model("ReleasedModel", {
    id: types.maybeNull(types.string),
    serialNumber: types.maybeNull(types.string),
    stage: types.maybeNull(types.number),
    realesedAt: types.maybeNull(types.string),
    shippedAt: types.maybeNull(types.string),
    dispositionedAt: types.maybeNull(types.string),
    inStockLocation: types.maybeNull(types.string),
    functionalDemoLocation: types.maybeNull(types.string),
    report: types.maybeNull(ReportInfoModel)
  });

export interface IReleasedModel extends Instance<typeof ReleasedModel> {}
export interface IReleasedModelSnapShot extends SnapshotIn<typeof ReleasedModel> {}

export const ReleasedListModel = types
  .model("ReleasedListModel", {
    activeCategory: types.optional(types.string, ReleasedListCategories[0].value),
    page: 0,
    total: 0,
    searchValue: types.maybe(types.string),
    sortBy: types.optional(types.string, ReleasedListSort.serialnumber),
    sortDirection: types.optional(types.number, SortDirection.DESC),
    isLoading: types.optional(types.boolean, true),
    items: types.optional(types.array(ReleasedModel), []),
    categoriesTotals: types.optional(types.model({...categoriesTotals}), {}),
    dropDownFilterInstockLocation: types.optional(types.string, ""),
    searchInstockLocationValue: types.optional(types.string, ""),
    dropDownFilterFunctionalDemoLocation: types.optional(types.string, ""),
    searchFunctionalDemoLocationValue: types.optional(types.string, ""),
    functionalDemoLocationOptions:
      types.optional(types.array(types.model({ value: types.string, label: types.string })), [])
  })
  .actions(self => ({
    setLoading(isLoading: boolean) {
      self.isLoading = isLoading;
    },
    setList(dto: IReleasedModelSnapShot[]) {
      self.items = dto as IObservableArray;
    }
  }))
  .actions(self => {
    const setActiveCategory = (value: string, count: number) => {
      self.activeCategory = value;
      self.page = 0;
      self.total = count;
      getList();
    };

    const setFirstPage = () => {
      self.page = 0;
    };

    const setDefaultParams = () => {
      self.activeCategory = ReleasedListCategories[0].value;
      self.page = 0;
      self.searchValue = "";
      self.sortBy = ReleasedListSort.serialnumber;
      self.sortDirection = SortDirection.DESC;
      self.dropDownFilterInstockLocation = "";
      self.searchInstockLocationValue = "";
      self.dropDownFilterFunctionalDemoLocation = "";
      self.searchFunctionalDemoLocationValue = "";
    };

    const setSearchInstockLocationValue = (search: string) => {
      self.searchInstockLocationValue = search;
    };

    const setDropDownFilterInstockLocation = (value: string | number) => {
      self.dropDownFilterInstockLocation = value.toString();
      self.page = 0;
      getTotalCount();
    };

    const setSearchFunctionalDemoLocationValue = (search: string) => {
      self.searchFunctionalDemoLocationValue = search;
    };

    const setDropDownFilterFunctionalDemoLocation = (value: string | number) => {
      self.dropDownFilterFunctionalDemoLocation = value.toString();
      self.page = 0;
      getTotalCount();
    };

    const nextPage = () => {
      self.page++;
      getList();
    };

    const previousPage = () => {
      self.page--;
      getList();
    };

    const setSearch = (searachValue? : string) => {
      self.searchValue = searachValue;
    };

    const setSortBy = (sortBy: string, sortDirection: number) => {
      self.sortBy = sortBy;
      self.sortDirection = sortDirection;
      getList();
    };

    const fetchFunctionalDemoLocationOptions = flow(function* fetchFunctionalDemoLocationOptions() {
      const filter = new ReleasedListFilter();
      filter.take = 9999;
      filter.sortBy = "";
      filter.stage = ReleasedDeviceStage.FunctionalDemo;
      const result: TApiResponse<string[]>
        = yield releasedDevicesApi.getFunctionalDemoLocations(filter);
      if (result.isOk) {
        self.functionalDemoLocationOptions = cast(result.data.map((el: string, index: number) => {
          return { value: index.toString(), label: el };
        }));
      } else {
        self.functionalDemoLocationOptions = cast([]);
      }
    });

    const getList = flow(function* getList() {
      self.setLoading(true);

      try {
        const rows = self.total && ((self.page * PAGINATION.ROWS_PER_PAGE + PAGINATION.ROWS_PER_PAGE) > self.total)
          ? self.total - (self.page * PAGINATION.ROWS_PER_PAGE)
          : PAGINATION.ROWS_PER_PAGE;

        const filter = new ReleasedListFilter();
        filter.skip = self.page * PAGINATION.ROWS_PER_PAGE;
        filter.take = rows;
        filter.search = self.searchValue || "";
        filter.sortBy = self.sortBy;
        filter.sortDirection = self.sortDirection;
        
        if (self.dropDownFilterInstockLocation) {
          filter.inStockLocation = InStockLocationOptions[+self.dropDownFilterInstockLocation].label;
        }

        if (self.dropDownFilterFunctionalDemoLocation) {
          filter.functionalDemoLocation 
            = self.functionalDemoLocationOptions[+self.dropDownFilterFunctionalDemoLocation].label;
        }

        const result: TApiResponse<IReleasedModelSnapShot[]>
          = yield releasedDevicesApi.getReleasedList(filter, self.activeCategory);
        if (result.isOk) {
          self.setList(result.data);
          self.setLoading(false);
        } else {
          self.setList([]);
          self.setLoading(false);
        }
      } catch (error) {
        console.error(error);
        self.setList([]);
        self.setLoading(false);
      }
    });

    const getTotalCount = flow(function* getTotalCount() {
      try {
        const filter = new ReleasedListFilter();
        filter.search = self.searchValue || "";

        if (self.dropDownFilterInstockLocation) {
          filter.inStockLocation = InStockLocationOptions[+self.dropDownFilterInstockLocation].label;
        }

        if (self.dropDownFilterFunctionalDemoLocation) {
          filter.functionalDemoLocation 
            = self.functionalDemoLocationOptions[+self.dropDownFilterFunctionalDemoLocation].label;
        }

        const result: TApiResponse<IReleasedCountResponse> = yield releasedDevicesApi.getCounters(filter);
        if (result.isOk) {

          self.categoriesTotals.AllCategoryTotal = result.data.all;
          
          ReleasedListCategories
            .forEach(el => {
              const keyName = `${el.state}CategoryTotal`;
              self.categoriesTotals[keyName] 
                = Object.prototype.hasOwnProperty.call(result.data, el.state) 
                  ? result.data[el.state as keyof IReleasedCountResponse]
                  : 0;
            });

          ReleasedListCategories.forEach((category: any) => {
            if(self.activeCategory === category.value) {
              const keyName = `${category.state}CategoryTotal`;
              self.total = self.categoriesTotals[keyName];
            }
          }); 

          getList();
        }
      } catch (error) {
        console.error(error);
      }
    });

    const downloadExport = flow(function* downloadExport() {
      const filter = new ReleasedListFilter();
      filter.search = self.searchValue || "";
      filter.sortBy = self.sortBy;
      filter.sortDirection = self.sortDirection;

      if (self.dropDownFilterInstockLocation) {
        filter.inStockLocation = InStockLocationOptions[+self.dropDownFilterInstockLocation].label;
      }

      if (self.dropDownFilterFunctionalDemoLocation) {
        filter.functionalDemoLocation 
          = self.functionalDemoLocationOptions[+self.dropDownFilterFunctionalDemoLocation].label;
      }

      try {
        const exportResult = yield releasedDevicesApi.getExport(filter, self.activeCategory);
        if (!exportResult.isOk) {
          return;
        }

        ExportUtils.downloadCSV(exportResult.data.data,
          `Released_${self.activeCategory || "all"}.csv`);
      } catch (error) {
        console.error(error);
      }
    });

    return {
      nextPage,
      previousPage,
      getList,
      setActiveCategory,
      getTotalCount,
      setSearch,
      setSortBy,
      setFirstPage,
      setDefaultParams,
      downloadExport,
      setSearchInstockLocationValue,
      setDropDownFilterInstockLocation,
      setSearchFunctionalDemoLocationValue,
      setDropDownFilterFunctionalDemoLocation,
      fetchFunctionalDemoLocationOptions
    };
  });