import axios from "axios";
import { ApiErrorDto } from "../../models/api";

const defaultErrorDto = new ApiErrorDto(0, "Failed");

export const ProcessAPIError = (): MethodDecorator => (
  target,
  propertyKey,
  descriptor: PropertyDescriptor
): void => {
  const old = descriptor.value;
  descriptor.value = async function(...args: unknown[]): Promise<unknown> {
    try {
      return await old.apply(this, args);
    } catch (e) {
      if (axios.isCancel(e)) {
        return new ApiErrorDto(0, "Cancel");
      }

      if (!axios.isAxiosError(e)) {
        throw e;
      }

      const response = e.response;
      if (!response) {
        return defaultErrorDto;
      }
      const url = response.config.url;
      const method = response.config.method!.toUpperCase();
      console.error(`API error [${response.status}] for [${method} ${url}]: \n`, response.data);
      if (response.status === 401) {
        localStorage.removeItem("token");
        window.location.reload();
      }
      return new ApiErrorDto(response.status, response.data, response.headers);
    }
  };
};
