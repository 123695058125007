export enum WI050FailureMode {
  None = 0,
  VibrationFailure = 1,
  FailedPost = 2,
  LedFailureAfterHardReset = 3,
  PhysicalDamage = 4,
  DeviceNotReleased = 5,
  Other = 6
}

export enum WI050FailureModeLabels {
  None = "None",
  VibrationFailure = "Vibration Failure",
  FailedPost = "Failed POST",
  LedFailureAfterHardReset = "LED Failure after hard reset",
  PhysicalDamage = "Physical damage",
  DeviceNotReleased = "Device not released",
  Other = "Other"
}