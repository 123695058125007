import { WI048FailureMode, WI048FailureModeLabels } from "../boot/enums/WI048FailureMode";
import { IWI048FormModelSnapShot } from "../stores/wi048-form.store";

export const WI048FormState: IWI048FormModelSnapShot = {
  wiRev: {
    name: "WI REV",
    value: "",
    type: "input",
    fieldId: "wiRev"
  },
  dmm: {
    name: "DMM",
    value: "",
    type: "input",
    fieldId: "dmm"
  },
  ecgSimulator: {
    name: "ECG Simulator",
    value: "",
    type: "input",
    fieldId: "ecgSimulator"
  },
  testFixture: {
    name: "Test Fixture",
    value: "",
    type: "input",
    fieldId: "testFixture"
  },
  wiFiSignalRssi: {
    name: "WiFi Signal RSSI",
    value: "",
    type: "input",
    fieldId: "wiFiSignalRssi"
  },
  wiFiCurrent: {
    name: "WiFi Current (mA)",
    value: "",
    type: "input",
    fieldId: "wiFiCurrent"
  },
  state1Current: {
    name: "State = 1 Current (A)",
    value: "",
    type: "input",
    fieldId: "state1Current"
  },
  state4Current: {
    name: "State = 4 Current (A)",
    value: "",
    type: "input",
    fieldId: "state4Current"
  },
  modemCurrent: {
    name: "Modem Current (mA)",
    value: "",
    type: "input",
    fieldId: "modemCurrent"
  },
  failureMode: {
    name: "Failure Mode",
    value: WI048FailureMode.None.toString(),
    type: "select",
    fieldId: "failureMode",
    options: Object.keys(WI048FailureMode)
      .filter((v) => isNaN(Number(v)))
      .map((key: string) => {
        return {
          value: WI048FailureMode[key as keyof typeof WI048FailureMode].toString(),
          label: WI048FailureModeLabels[key as keyof typeof WI048FailureMode],
        };
      }),
    defaultValue: { value: WI048FailureMode.None.toString(), label: WI048FailureModeLabels.None },
  },
  comment: {
    name: "Comment",
    value: "",
    type: "textarea",
    fieldId: "comment"
  },
};
