import { observer } from "mobx-react-lite";
import { FC } from "react";
import { useRootStore } from "../../../stores/storeContext";
import { VQCheckbox, VQDynamicFormComponent } from "vivaquant-components-library";
import { ETWIProps } from "../et-wi-forms.props";

export const ETFormWI058Main: FC<ETWIProps> = observer((props: ETWIProps) => {
  const {
    wiRev,
    noneHasGlue,
    connectorHasGlue, 
    housingSeamHasGlue,
    raLeadHasGlue,
    laLeadHasGlue,
    rlLeadHasGlue,
    llLeadHasGlue,
    otherHasGlue,
    failureMode,
    comment,
    setBooleanValue,
    errorOther
  } = useRootStore().wi058FormStore;

  const handleChange = () => {};

  return (
    <>
      <div className="col-container">
        <VQDynamicFormComponent
          item={wiRev}
          customClass="col-container-el"
          errors={props.errors}
          handleChange={handleChange}
          showError={true}
          disabled={props.isDisabled}
          isFormInvalid={props.formInvalid}></VQDynamicFormComponent>
        {!failureMode.isLoading && <VQDynamicFormComponent
          item={failureMode}
          customClass="col-container-el"
          errors={props.errors}
          handleChange={handleChange}
          showError={true}
          disabled={props.isDisabled}
          isFormInvalid={props.formInvalid}></VQDynamicFormComponent>}
      </div>
          
      <div className="label-input">Glue Added</div>
      <div className="col-container-3">
        <VQCheckbox
          handleChange={() => { setBooleanValue("noneHasGlue", !noneHasGlue); }}
          isChecked={noneHasGlue}
          label="None"
          disabled={props.isDisabled}
          name="noneHasGlue"
        ></VQCheckbox>
        <VQCheckbox
          handleChange={() => { setBooleanValue("connectorHasGlue", !connectorHasGlue); }}
          isChecked={connectorHasGlue}
          label="Connector"
          disabled={props.isDisabled}
          name="connectorHasGlue"
        ></VQCheckbox>
      </div>
      <div className="col-container-3">
        <VQCheckbox
          handleChange={() => { setBooleanValue("housingSeamHasGlue", !housingSeamHasGlue); }}
          isChecked={housingSeamHasGlue}
          label="Housing seam"
          disabled={props.isDisabled}
          name="housingSeamHasGlue"
        ></VQCheckbox>
        <VQCheckbox
          handleChange={() => { setBooleanValue("raLeadHasGlue", !raLeadHasGlue); }}
          isChecked={raLeadHasGlue}
          label="RA Lead"
          disabled={props.isDisabled}
          name="raLeadHasGlue"
        ></VQCheckbox>
      </div>
      <div className="col-container-3">
        <VQCheckbox
          handleChange={() => { setBooleanValue("laLeadHasGlue", !laLeadHasGlue); }}
          isChecked={laLeadHasGlue}
          label="LA Lead"
          disabled={props.isDisabled}
          name="laLeadHasGlue"
        ></VQCheckbox>
        <VQCheckbox
          handleChange={() => { setBooleanValue("rlLeadHasGlue", !rlLeadHasGlue); }}
          isChecked={rlLeadHasGlue}
          label="RL Lead"
          disabled={props.isDisabled}
          name="rlLeadHasGlue"
        ></VQCheckbox>
      </div>
      <div className="col-container-3">
        <VQCheckbox
          handleChange={() => { setBooleanValue("llLeadHasGlue", !llLeadHasGlue); }}
          isChecked={llLeadHasGlue}
          label="LL Lead"
          disabled={props.isDisabled}
          name="llLeadHasGlue"
        ></VQCheckbox>
        <VQCheckbox
          handleChange={() => { setBooleanValue("otherHasGlue", !otherHasGlue); }}
          isChecked={otherHasGlue}
          label="Other"
          disabled={props.isDisabled}
          name="otherHasGlue"
        ></VQCheckbox>
      </div>

      <VQDynamicFormComponent
        item={comment}
        errors={props.errors}
        handleChange={handleChange}
        showError={true}
        disabled={props.isDisabled}
        isFormInvalid={props.formInvalid}></VQDynamicFormComponent>
      {errorOther && <div className="invalid-text-error">{errorOther}</div>}
    </>
  );
});
