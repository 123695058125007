import debounce from "lodash.debounce";
import { observer } from "mobx-react-lite";
import { FC, useMemo } from "react";
import { 
  VQGreenButton,
  VQInputSearch,
  VQPagination,
  VQTableSearchSection
} from "vivaquant-components-library";
import { Permissions } from "../../services/permission.service";
import { PAGINATION } from "../../boot/constants";
import { useRootStore } from "../../stores/storeContext";
import { ETAdminDevicesAddModal } from "../et-admin-devices-add-modal";
import { ETAdminDevicesListHeaderWrapper } from "./et-admin-devices-list-header.presents";

export const ETAdminDevicesListHeader: FC = observer(() => {
  const {
    setSearch,
    searchValue,
    getTotalCount,
    page,
    total,
    nextPage,
    previousPage,
    setFirstPage,
  } = useRootStore().adminDevicesListStore;
  const { hasPermission } = useRootStore().userStore;
  const { resetForm, openModal } = useRootStore().adminDevicesAddStore;
  const { isOpenedRightForm } = useRootStore().globalStore;

  const openImport = () => {
    resetForm();
    openModal();
  };

  const handleSearchChange = (value: string) => {
    setSearch(value);
    refreshData();
  };

  const refreshData = useMemo(
    () => debounce(() => {
      setFirstPage();
      getTotalCount();
    }, 1000), []);

  return(
    <ETAdminDevicesListHeaderWrapper>
      <VQTableSearchSection>
        <VQInputSearch
          disabled={isOpenedRightForm}
          handleChange={handleSearchChange}
          placeholder={"Search by Serial Number and Batch ID"}
          value={searchValue}
          autoComplete={false}/>
        <div className="left">
          <VQGreenButton
            click={openImport}
            text="Add Devices"
            isDisabled={isOpenedRightForm || !hasPermission(Permissions.DevicesImport)}
            width="254px"></VQGreenButton>
          <VQPagination
            disabled={isOpenedRightForm}
            next={nextPage}
            previous={previousPage}
            total={total}
            countOnPage={PAGINATION.ROWS_PER_PAGE}
            disableControls={true}
            page={page}/>
        </div>
      </VQTableSearchSection>
      <ETAdminDevicesAddModal></ETAdminDevicesAddModal>
    </ETAdminDevicesListHeaderWrapper>
  );
});