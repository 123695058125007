import { SnapshotIn, applySnapshot, flow, types, cast, Instance } from "mobx-state-tree";
import { InputFormModel } from "vivaquant-components-library";
import { AdminRouteConfigurationAddState } from "../states/admin-route-configuration-add.state";
import { TApiResponse } from "../types";
import { routeConfigurationApi } from "../services/api";
import { IRouteConfigurationResponse } from "../models/IRouteConfigurationResponse";
import { StringsUtils } from "../services/utils";
import { WorkInstructionType } from "../boot/enums/WorkInstructionType";
import { IAddRouteConfiguration } from "../models/IAddRouteConfiguration";
import { IEditRouteConfiguration } from "../models/IEditRouteConfiguration";
import _uniqueId from "lodash.uniqueid";
import { IObservableArray } from "mobx";

const WorkInstructionsItemModel = types
  .model("WorkInstructionsItemModel", {
    wi: types.maybeNull(InputFormModel),
    errorWI: types.maybeNull(types.string),
  });

export interface IWorkInstructionsItemModel extends Instance<typeof WorkInstructionsItemModel> {}


export const AdminRouteConfigurationAddModel = types
  .model("AdminRouteConfigurationAddModel", {
    show: types.optional(types.boolean, false),
    isEdit: types.optional(types.boolean, false),
    isSubmited: types.optional(types.boolean, false),
    id: types.maybeNull(types.string),
    name: types.maybe(InputFormModel),
    hasAnyDevices: types.maybeNull(types.boolean),
    workInstructions: types.maybeNull(types.optional(types.array(WorkInstructionsItemModel), [])),
    baseData: types.frozen()
  })
  .actions(self => ({
    setEdit(isEdit: boolean) {
      self.isEdit = isEdit;
    },
    setWorkInstructions(list: IWorkInstructionsItemModel[]) {
      self.workInstructions = list as IObservableArray;
    },
    getWorkInstructionData(wi: number | string, options: any[]) {
      const data = {
        name: "Work Instruction",
        value: "",
        type: "select",
        fieldId: `wi-${_uniqueId()}`,
        options,
        defaultValue: { value: "", label: "" },
      };
      const option = data.options.find((item: any) => item.value === wi.toString());
      if (option) {
        data.value = wi.toString();
        data.defaultValue = { value: option.value, label: option.label };
      }
      return data;
    },
  }))
  .actions(self => {

    const getOptions = () => {
      self.workInstructions.map((item: IWorkInstructionsItemModel) => {
        const { options, fieldId } = item.wi;
        const data: any =  options.map((option: any) => {
          const el = self.workInstructions.find((el: IWorkInstructionsItemModel) => {
            return (el.wi.fieldId !== fieldId) && (option.value === el.wi.value);
          });
          return {
            ...option,
            isDisabled: el ? true : false
          };
        });
        item.wi.setOptions(data);
        return data;
      });
    };
    const setId = (id: string) => {
      self.id = id;
    };

    const resetForm = () => {
      self.isEdit = false;
      self.id = null;
      self.isSubmited = false;
      applySnapshot(self, AdminRouteConfigurationAddState);
      self.hasAnyDevices = null;
      self.workInstructions = null;
      self.baseData = {};
    };

    const openForm = () => {
      self.show = true;
    };

    const closeForm = () => {
      self.show = false;
      resetForm();
    };

    const validateWI = () => {
      let isValid = true;
      self.workInstructions.forEach((el: IWorkInstructionsItemModel) => {
        if (!el.wi?.value) {
          el.errorWI = "Please fill the field";
          isValid = false;
        } else {
          el.errorWI = null;
        }
      });

      return isValid;
    };

    const validateField = (): {errors: {[key: string]: string}, isValid: boolean} => {
      const errors: {[key: string]: string} = {};
      let isValid: boolean = true;

      if (!self.name?.value) {
        errors.name = "Please fill the field";
        isValid = false;
      } else if (self.name?.value.length > 8) {
        errors.name = "Title should be limited to 8 symbols";
        isValid = false;
      }

      if(!validateWI()) {
        isValid = false;
      }

      return {
        errors,
        isValid
      };
    };

    const fetchData = flow(function* fetchData(id?: string) {
      if (id) {
        self.id = id;
        self.isEdit = true;
      } else {
        self.isEdit = false;
      }
      if (self.isEdit) {
        try {
          const response: TApiResponse<IRouteConfigurationResponse>
            = yield routeConfigurationApi.getRouteConfiguration(self.id);
          if (response.isOk) {
            const { data } = response;
            self.name.setValue(data.name || "");
            self.hasAnyDevices = data.hasAnyDevices;

            let options = Object.keys(WorkInstructionType)
              .filter((v) => isNaN(Number(v)))
              .map((key: string) => {
                return {
                  label: StringsUtils.addWIDashes(key),
                  value: WorkInstructionType[key as keyof typeof WorkInstructionType].toString(),
                };
              });

            self.workInstructions = cast(data.workInstructions.map((item: number) => {
              const data: IWorkInstructionsItemModel = cast({
                wi: self.getWorkInstructionData(item, options),
                errorWI: null
              });
              return data;
            }));

            getOptions();

            self.baseData = {
              name: data.name,
              workInstructions: data.workInstructions || []
            };
          }
        } catch (error) {
          console.error(error);
        }
      }
    });

    const isFormChanged = () => {
      if ( self.name.value !== self.baseData.name) {
        return true;
      } else if (self.workInstructions.length !== self.baseData.workInstructions.length) {
        return true;
      } else if (self.workInstructions.length === self.baseData.workInstructions.length) {
        let result = false;
        self.workInstructions.forEach((el: IWorkInstructionsItemModel, index: number) => {
          if (el.wi.value !== self.baseData.workInstructions[index]?.toString()) {
            result = true;
          } 
        });
        return result;
      }
      else {
        return false;
      }
    };

    const addWI = () => {
      let options = Object.keys(WorkInstructionType)
        .filter((v) => isNaN(Number(v)))
        .map((key: string) => {
          return {
            label: StringsUtils.addWIDashes(key),
            value: WorkInstructionType[key as keyof typeof WorkInstructionType].toString(),
          };
        });

      const data: IWorkInstructionsItemModel = cast({
        wi: self.getWorkInstructionData("", options),
        errorWI: null
      });
      if (!self.workInstructions) {
        const arr = [];
        arr.push(data);
        self.workInstructions = cast(arr);
      } else {
        self.workInstructions.push(data);
      }
      getOptions();
    };

    const deleteWI = (item: IWorkInstructionsItemModel) => {
      self.setWorkInstructions(self.workInstructions.filter(((el) => el.wi.fieldId !== item.wi.fieldId)));
      getOptions();
    };

    const saveForm = flow(function* saveForm() {
      self.isSubmited = true;
      if (self.workInstructions.length === 0) {
        return { error: { detail: "No Work Instructions selected" } };
      }

      try {
        let result = null;
        if (!self.isEdit) {
          const data: IAddRouteConfiguration = {
            name: self.name.value,
            workInstructions: self.workInstructions.map((el) => +el.wi.value)
          };
          result =  yield routeConfigurationApi.addRouteConfiguration(data);
        } else {
          const data: IEditRouteConfiguration = {
            id: self.id,
            name: self.name.value,
            workInstructions: self.workInstructions.map((el) => +el.wi.value)
          };
          result = yield routeConfigurationApi.editRouteConfiguration(data);
        }
        return result;
      } catch (error) {
        console.error(error);
        return error;
      }
    });

    const updateWorkInstructions = (list: IWorkInstructionsItemModel[]) => {
      self.setWorkInstructions(list);
    };

    return {
      openForm,
      closeForm,
      saveForm,
      validateField,
      resetForm,
      fetchData,
      addWI,
      deleteWI,
      setId,
      updateWorkInstructions,
      getOptions,
      isFormChanged
    };
  });

export interface IAdminRouteConfigurationAddModelSnapShot extends SnapshotIn<typeof AdminRouteConfigurationAddModel> {}
