import { observer } from "mobx-react-lite";
import { FC, useEffect, useRef, useState } from "react";
import { useRootStore } from "../../../stores/storeContext";
import { message } from "antd";
import { confirmAlert } from "react-confirm-alert";
import { 
  VQBlueButton,
  VQCheckbox,
  VQGreenButton, 
  VQIcon, 
  VQRedButton 
} from "vivaquant-components-library";
import CloseIcon from "../../../assets/icons/close.svg";
import { WorkInstructionActionType } from "../../../boot/enums/WorkInstructionActionType";
import { WI068FailureMode } from "../../../boot/enums/WI068FailureMode";
import { WIFormService } from "../../../services/wi-form.service";
import { ETFormWI068Main } from "./et-form-wi068-main";

export const ETFormWI068: FC = observer(() => {
  const {
    failureMode,
    comment,
    validateField,
    saveForm,
    closeForm,
    isFormChanged,
    deviceId,
    isNextDevice,
    setIsNextDevice,
    fetchData,
  } = useRootStore().wi068FormStore;
  const { 
    getTotalCount, 
    items, page, 
    nextPage, 
    total, 
    activeCategory, 
    itemBatchIdNextPage 
  } = useRootStore().inProgressListStore;
  const { setGlobalSpiner, setOpenedRightForm } = useRootStore().globalStore;

  useEffect(() => {
    window.addEventListener("beforeunload", alertUser);
    return () => {
      window.removeEventListener("beforeunload", alertUser);
    };
  }, []);
  const alertUser = (e: any) => {
    if (isFormChanged()) {
      e.preventDefault();
    }
  };

  const formRef = useRef(null);
  useEffect(() => {
    if (formRef && formRef.current) {
      const el = document.getElementsByName("wiRev");
      if (el && el[0]) {
        el[0].focus();
      }
    }
  }, [deviceId]);

  const [formInvalid, setFormInvalid] = useState(false);
  const [errors, setErrors] = useState({});

  const validateForm = async(action: number) => {
    const { errors, isValid } = await validateField(action);
    setErrors(errors);
    return isValid;
  };

  const hide = () => {
    getTotalCount();
    setFormInvalid(false);
    setErrors({});
    setOpenedRightForm(false);
    closeForm();
  };

  const openNextDevice = (action: number) => {
    WIFormService.openNextDevice(
      items,
      deviceId, 
      action, 
      page, 
      total, 
      setFormInvalid, 
      setErrors, 
      setGlobalSpiner, 
      fetchData,
      nextPage,
      hide,
      getTotalCount,
      itemBatchIdNextPage
    );
  };

  const onSubmit = (action: number) => {
    WIFormService.onSubmit(
      action, 
      activeCategory ? isNextDevice : false,
      message,
      validateForm, 
      setFormInvalid, 
      saveForm, 
      setGlobalSpiner, 
      openNextDevice, 
      hide
    );
  };

  const onClose = () => {
    WIFormService.onClose(isFormChanged, confirmAlert, hide);
  };

  return (
    <>
      <div className="close" onClick={onClose}>
        <VQIcon icon={CloseIcon}></VQIcon>
      </div>
      <form onSubmit={() => {}} ref={formRef}>
        <div className="fields-container">
          {activeCategory && <VQCheckbox
            handleChange={() => { setIsNextDevice(!isNextDevice); }}
            isChecked={isNextDevice}
            label="Open next device"
            name="isNextDevice"
          ></VQCheckbox>}
          <ETFormWI068Main errors={errors} formInvalid={formInvalid}></ETFormWI068Main>
        </div>
        <div className="btn-container">
          <VQRedButton
            click={() => {onSubmit(WorkInstructionActionType.Fail);}}
            isDisabled={failureMode.value === WI068FailureMode.None.toString() || !comment.value}
            text="Fail"
            type="button"
            width="184px"></VQRedButton>
          <VQBlueButton
            click={() => {onSubmit(WorkInstructionActionType.None);}}
            text="Save"
            type="button"
            width="184px"></VQBlueButton>
          <VQGreenButton
            click={() => {onSubmit(WorkInstructionActionType.Pass);}}
            isDisabled={failureMode.value !== WI068FailureMode.None.toString()}
            text="Pass"
            type="button"
            width="184px"></VQGreenButton>
        </div>
      </form>
    </>
  );
});
