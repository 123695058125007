import { IProfileStoreModelSnapShot } from "../stores/profile.store";

export const ProfileInitialState: IProfileStoreModelSnapShot = {
  firstName: {
    name: "First Name",
    value: "",
    type: "input",
    fieldId: "firstName"
  },
  lastName: {
    name: "Last Name",
    value: "",
    type: "input",
    fieldId: "lastName"
  },
  email: {
    name: "Email",
    value: "",
    type: "input",
    fieldId: "email"
  },
  username: {
    name: "Username",
    value: "",
    type: "input",
    fieldId: "username"
  },
  location: {
    name: "Location",
    value: "",
    type: "input",
    fieldId: "location",
  },
  typeUser: {
    name: "Role",
    value: "",
    type: "multiply",
    fieldId: "typeUser",
    options: [],
    isDisabled: true
  },
};
