import { observer } from "mobx-react-lite";
import React, { FC, useEffect, useState } from "react";
import { useRootStore } from "../../stores/storeContext";
import { VQBlueButton, VQDynamicFormComponent, VQIcon, VQLightButton, VQTooltip } from "vivaquant-components-library";
import InfoIcon from "../../assets/icons/info.svg";
import { IWorkInstructionsItemModel } from "../../stores/admin-route-configuration-add.store";
import { message } from "antd";
import TrashIcon from "../../assets/icons/trash.svg";
import DragIcon from "../../assets/icons/drag.svg";
import { ItemInterface, ReactSortable } from "react-sortablejs";
import { confirmAlert } from "react-confirm-alert";
import CloseIcon from "../../assets/icons/close.svg";
import { WIFormService } from "../../services/wi-form.service";
import _uniqueId from "lodash.uniqueid";

export const ETAdminRouteConfigurationAdd: FC = observer(() => {
  const {
    name,
    hasAnyDevices,
    workInstructions,
    closeForm,
    addWI,
    deleteWI,
    saveForm,
    validateField,
    updateWorkInstructions,
    getOptions,
    isEdit,
    isFormChanged
  } = useRootStore().adminRouteConfigurationAddStore;
  const {
    getTotalCount
  } = useRootStore().adminRouteConfigurationListStore;
  const { setGlobalSpiner, setOpenedRightForm } = useRootStore().globalStore;

  useEffect(() => {
    window.addEventListener("beforeunload", alertUser);
    return () => {
      window.removeEventListener("beforeunload", alertUser);
    };
  }, []);
  const alertUser = (e: any) => {
    if (isFormChanged()) {
      e.preventDefault();
    }
  };

  const [formInvalid, setFormInvalid] = useState(false);
  const [errors, setErrors] = useState({});
  const handleChange = () => {};

  const [state, setState] 
  = useState<ItemInterface[]>((workInstructions || []).map((el: IWorkInstructionsItemModel) => { 
    return { id: el.wi.fieldId, chosen: false };
  }));

  useEffect(() => {
    setState((workInstructions || []).map((el: IWorkInstructionsItemModel) => { 
      return { id: el.wi.fieldId, chosen: false };
    }));
  }, [JSON.stringify(workInstructions)]);

  useEffect(() => {
    const arr: IWorkInstructionsItemModel[] = [];
    state.forEach((stateItem: ItemInterface) => {
      const el = workInstructions.find((item: IWorkInstructionsItemModel) => {
        return item.wi.fieldId === stateItem.id;
      });
      if (el) arr.push(el);
    });
    updateWorkInstructions(arr);  
  }, [state]);

  const validateForm = () => {
    const { errors, isValid } = validateField();
    setErrors(errors);
    return isValid;
  };

  const onSubmit: React.FormEventHandler = (event) => {
    event.preventDefault();
    if (!validateForm()) {
      setFormInvalid(true);
    } else {
      setFormInvalid(false);
      setGlobalSpiner(true);
      saveForm()
        .then((res: any) => {
          setGlobalSpiner(false);
          if (res.isOk) {
            getTotalCount();
            hide();
            confirmAlert({
              message: `New Route Configuration has been successfully ${isEdit ? "edited" : "added"}`,
              overlayClassName: "sl-success-modal",
              buttons: [
                {
                  label: "Ok",
                  onClick: () => {}
                }
              ]
            });
          } else {
            const key = "updatable";
            message.error({
              content: res.error?.detail || res.error?.title || "Error, Something went wrong!",
              key,
              duration: 3
            });
          }
        })
        .catch((error: any) => {
          setGlobalSpiner(false);
          const key = "updatable";
          message.error({
            content: error?.detail || error?.title || "Error, Something went wrong!",
            key,
            duration: 3
          });
        });
      
    }
  };

  const hide = () => {
    setFormInvalid(false);
    setErrors({});
    setOpenedRightForm(false);
    closeForm();
  };

  const onClose = () => {
    WIFormService.onClose(isFormChanged, confirmAlert, hide);
  };

  return (
    <>
      <div className="close" onClick={onClose}>
        <VQIcon icon={CloseIcon}></VQIcon>
      </div>
      <form onSubmit={onSubmit}>
        {hasAnyDevices && <div className="info">
          <VQIcon icon={InfoIcon}></VQIcon>
          <div>You cannot edit the route configuration that contains devices.</div>
        </div>}
        <div className="fields-container">
          <VQDynamicFormComponent
            item={name}
            errors={errors}
            handleChange={handleChange}
            showError={true}
            disabled={hasAnyDevices}
            isFormInvalid={formInvalid}></VQDynamicFormComponent>
          <div className="label">Route</div>
          <ReactSortable list={state} setList={setState}>
            {(workInstructions || []).map((item: IWorkInstructionsItemModel) => (
              <div className={`wi-container ${hasAnyDevices ? "disabled" : ""}`} key={item.wi.fieldId}>
                <VQDynamicFormComponent
                  customClass="wi-container-el"
                  item={item.wi}
                  errors={{ [item.wi.fieldId]: item.errorWI}}
                  handleChange={getOptions}
                  showError={true}
                  disabled={hasAnyDevices}
                  isFormInvalid={formInvalid}></VQDynamicFormComponent>
                <div className="wi-container-el" style={{marginTop: "48px", cursor: "grab"}}>
                  <VQIcon icon={DragIcon} height="24px" width="24px"></VQIcon>
                </div>
                <div className="wi-container-el" onClick={() => deleteWI(item)} style={{marginTop: "40px"}}>
                  <VQTooltip
                    id={`tooltip-${_uniqueId()}`}
                    class="vq-tooltip"
                    tooltipBlock={<div>Delete Work Instruction</div>}>
                    <VQIcon icon={TrashIcon} height="24px" width="24px" padding="8px"></VQIcon>
                  </VQTooltip>
                </div>
              </div>
            ))}
          </ReactSortable>
          
          
          {!hasAnyDevices && <span onClick={addWI} className="ad-wi">+ Add Work Instructions</span>}
        </div>
        <div className="btn-container">
          <VQLightButton type="button" text="Cancel" click={hide}/>
          <VQBlueButton type="submit" text="Save" click={() => {}} isDisabled={hasAnyDevices}/>
        </div>
      </form>
    </>
  );
});
