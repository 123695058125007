import styled from "styled-components";
import { theme } from "../../theme/theme";

export const LayoutWrapper = styled.div<{greyedBg: boolean}>`
  height: 100vh;
  width: 100vw;
  box-sizing: border-box;
  overflow-y: auto;
  position: relative;
  background: ${props => props.greyedBg ? theme.colors.background : theme.colors.light};
`;
