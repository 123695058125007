import { observer } from "mobx-react-lite";
import { FC, useEffect, useState } from "react";
import { useRootStore } from "../../stores/storeContext";
import { 
  VQBlueButton,
  VQCheckbox, 
  VQDynamicFormComponent, 
  VQGreenButton, 
  VQIcon, 
  VQRadio 
} from "vivaquant-components-library";
import CloseIcon from "../../assets/icons/close.svg";
import { WIFormService } from "../../services/wi-form.service";
import { confirmAlert } from "react-confirm-alert";
import { NcmDeviceActionType } from "../../boot/enums/NcmDeviceActionType";
import { message } from "antd";
import { INCMModel } from "../../stores/ncm-list.store";
import { PAGINATION } from "../../boot/constants";
import _uniqueId from "lodash.uniqueid";
import { NcmDeviceDisposition, NcmDeviceDispositionLabel } from "../../boot/enums/NcmDeviceDisposition";
import { ETNCMDispositionWrapper } from "./et-ncm-disposition.presents";

export const ETNCMDisposition: FC = observer(() => {
  const {
    id,
    routeConfiguration,
    ncmNumber,
    comment,
    validateField,
    saveForm,
    closeForm,
    isFormChanged,
    isNextDevice,
    setIsNextDevice,
    fetchData,
    setDeviceDisposition,
    deviceDisposition,
    canMoveToReleased,
    serialNumber
  } = useRootStore().ncmDispositionStore;

  const { 
    getTotalCount, 
    items, 
    page, 
    nextPage, 
    total
  } = useRootStore().ncmListStore;
  const { setGlobalSpiner, setOpenedRightForm } = useRootStore().globalStore;


  useEffect(() => {
    window.addEventListener("beforeunload", alertUser);
    return () => {
      window.removeEventListener("beforeunload", alertUser);
    };
  }, []); 
  const alertUser = (e: any) => {
    if (isFormChanged()) {
      e.preventDefault();
    }
  };

  const [formInvalid, setFormInvalid] = useState(false);
  const [errors, setErrors] = useState({});
  const handleChange = () => {};
  const handleChangeNumber = (e: any, item: any) => {
    item.setValue(item.value.replace(/[^0-9.]/g, ""));
    if (item.value.length > 5) {
      item.setValue(item.value.slice(0, 5));
    }
  };

  const validateForm = (action: number) => {
    const { errors, isValid } = validateField(action);
    setErrors(errors);
    return isValid;
  };

  const hide = () => {
    getTotalCount();
    setFormInvalid(false);
    setErrors({});
    setOpenedRightForm(false);
    closeForm();
  };

  const onClose = () => {
    WIFormService.onClose(isFormChanged, confirmAlert, hide);
  };

  const openNextDevice = (action: number) => {
    const index = items.findIndex((el: INCMModel) => el.id === id);
    if (index !== -1) {
      if (action === NcmDeviceActionType.None) {
        setFormInvalid(false);
        setErrors({});
        if ((index + 1) <= items.length - 1) {
          setGlobalSpiner(true);
          fetchData(items[index + 1].id).then(() => { setGlobalSpiner(false); });
        } else if ((page + 1) * PAGINATION.ROWS_PER_PAGE < total) {
          return nextPage()
            .then((resultItems: any) => {
              setGlobalSpiner(true);
              fetchData(resultItems[0].id).then(() => { setGlobalSpiner(false); });
            });
        } else {
          hide();
        }
      } else {
        setFormInvalid(false);
        setErrors({});
        getTotalCount()
          .then((resultItems: any) => {
            if(index <= resultItems.length) {
              if(resultItems[index]) {
                setGlobalSpiner(true);
                fetchData(resultItems[index].id).then(() => { setGlobalSpiner(false); });
              } else {
                hide();
              }
            } else {
              hide();
            }
          });
      }
    } else {
      hide();
    }
  };

  const save = (action: number) => {
    setGlobalSpiner(true);
    return saveForm(action)
      .then((res: any) => {
        setGlobalSpiner(false);
        if (res.isOk) {
          const key = "updatable";
          message.success({ content: "Your form has been saved successfully!", key, duration: 3 });
          if (isNextDevice) {
            openNextDevice(action);
          } else {
            hide();
          }
        } else {
          const key = "updatable";
          message.error({
            content: res.error?.detail || res.error?.title || "Error, Something went wrong!",
            key,
            duration: 3
          });
        }
      })
      .catch((error: any) => {
        setGlobalSpiner(false);
        const key = "updatable";
        message.error({
          content: error?.detail || error?.title || "Error, Something went wrong!",
          key,
          duration: 3
        });
      });
  };

  const onSubmit = (action: number) => {
    if (!validateForm(action)) {
      setFormInvalid(true);
    } else {
      setFormInvalid(false);
      if (action === NcmDeviceActionType.Submit) {
        return confirmAlert({
          // eslint-disable-next-line max-len
          message: `Are you sure you want to disposition ${serialNumber} with ${"NCMR-" + ncmNumber.value} to the '${NcmDeviceDispositionLabel[NcmDeviceDisposition[deviceDisposition] as keyof typeof NcmDeviceDisposition]}' stage?`,
          overlayClassName: "sl-confirm-modal",
          buttons: [
            {
              label: "No",
              onClick: () => {}
            },
            {
              label: "Yes",
              className: "confirm-btn",
              onClick: () => { save(action); }
            }
          ]
        });
      } else {
        save(action);
      }
      
    }
  };

  return (
    <ETNCMDispositionWrapper>
      <div className="close" onClick={onClose}>
        <VQIcon icon={CloseIcon}></VQIcon>
      </div>
      <form onSubmit={() => {}}>
        <div className="fields-container">
          <VQCheckbox
            handleChange={() => { setIsNextDevice(!isNextDevice); }}
            isChecked={isNextDevice}
            label="Open next device"
            name="isNextDevice"
          ></VQCheckbox>

          <div>
            <div className="col-container">
              <div className="col-container-el label-input">Disposition of Device</div>
            </div>
            <div className="col-container">
              <div className="col-container-el flex-inherit">
                <VQRadio 
                  handleChange={() => { setDeviceDisposition(NcmDeviceDisposition.InProgress); }}
                  isChecked={deviceDisposition === NcmDeviceDisposition.InProgress}
                  label={NcmDeviceDispositionLabel.InProgress}
                  name="deviceDisposition"
                  id={_uniqueId()}
                ></VQRadio>
              </div>
              <div className={`col-container-el flex-inherit ${!canMoveToReleased ? "disabled" : ""}`}>
                <VQRadio 
                  handleChange={() => { setDeviceDisposition(NcmDeviceDisposition.Released); }}
                  isChecked={deviceDisposition === NcmDeviceDisposition.Released}
                  label={NcmDeviceDispositionLabel.Released}
                  name="deviceDisposition"
                  id={_uniqueId()}
                  disabled={!canMoveToReleased}
                ></VQRadio>
              </div>
              <div className="col-container-el flex-inherit">
                <VQRadio 
                  handleChange={() => { setDeviceDisposition(NcmDeviceDisposition.Decommissioned); }}
                  isChecked={deviceDisposition === NcmDeviceDisposition.Decommissioned}
                  label={NcmDeviceDispositionLabel.Decommissioned}
                  name="deviceDisposition"
                  id={_uniqueId()}
                ></VQRadio>
              </div>
            </div> 
          </div>

          <div className="col-container">
            {!routeConfiguration.isLoading && <VQDynamicFormComponent
              disabled={deviceDisposition !== NcmDeviceDisposition.InProgress}
              item={routeConfiguration}
              customClass="col-container-el"
              errors={errors}
              handleChange={handleChange}
              showError={true}
              isFormInvalid={formInvalid}></VQDynamicFormComponent>}
            <div className="col-container-el ncm-number-el">
              <VQDynamicFormComponent
                item={ncmNumber}
                errors={errors}
                handleChange={handleChangeNumber}
                showError={true}
                isFormInvalid={formInvalid}></VQDynamicFormComponent>
            </div>
          </div>
          <VQDynamicFormComponent
            item={comment}
            errors={errors}
            handleChange={handleChange}
            showError={true}
            isFormInvalid={formInvalid}></VQDynamicFormComponent>
        </div>
        <div className="btn-container">
          <VQBlueButton
            click={() => {onSubmit(NcmDeviceActionType.None);}}
            text="Save"
            type="button"
            width="184px"></VQBlueButton>
          <VQGreenButton
            click={() => {onSubmit(NcmDeviceActionType.Submit);}}
            isDisabled={!deviceDisposition}
            text="Submit"
            type="button"
            width="184px"></VQGreenButton>
        </div>
      </form>
    </ETNCMDispositionWrapper>
  );
});