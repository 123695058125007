import { observer } from "mobx-react-lite";
import React, { useState } from "react";
import { useRootStore } from "../../stores/storeContext";
import { Modal } from "react-bootstrap";
import {
  VQDynamicFormComponent,
  VQModalClose,
  VQLightButton,
  VQBlueButton,
  VQMessageError
} from "vivaquant-components-library";
import { SlAdminInviteModalWrapper } from "./et-admin-users-invite-modal.presents";
import { confirmAlert } from "react-confirm-alert";

export const ETAdminUsersInviteModal = observer(() => {
  const {
    show,
    closeModal,
    validateFormFields,
    name,
    lastName,
    email,
    location,
    typeUser,
    saveForm,
    isEdit,
    saveError
  } = useRootStore().adminUsersInviteStore;

  const { getTotalCount } = useRootStore().adminUsersListStore;
  const { setGlobalSpiner } = useRootStore().globalStore;

  const [formInvalid, setFormInvalid] = useState(false);
  const [errors, setErrors] = useState({});

  function validateForm() {
    const { errors, isValid } = validateFormFields();
    setErrors(errors);
    return isValid;
  }

  const handleChange = () => {};

  const onSubmit: React.FormEventHandler = (event) => {
    event.preventDefault();
    if (!validateForm()) {
      setFormInvalid(true);
    } else {
      setFormInvalid(false);
      setGlobalSpiner(true);
      saveForm()
        .then((result: boolean) => {
          if (result) {
            getTotalCount();
            hide();
            setGlobalSpiner(false);
            confirmAlert({
              message: isEdit ? "Administrator updated" : "Invitation has been sent!",
              overlayClassName: "sl-success-modal",
              buttons: [
                {
                  label: "Ok",
                  onClick: () => {}
                }
              ]
            });
          } else {
            setGlobalSpiner(false);
          }
        });
    }
  };

  const hide = () => {
    setFormInvalid(false);
    setErrors({});
    closeModal();
  };

  return (
    <Modal
      onHide={hide}
      show={show}
      centered
      dialogClassName="invite-admin-modal">
      <Modal.Body>
        <VQModalClose onHide={hide}></VQModalClose>
        <SlAdminInviteModalWrapper>
          <div className="form-container">
            <div className="title">{isEdit ? "Edit" : "Invite"}</div>
            <form onSubmit={onSubmit}>
              <div className="fields-container">
                {saveError && <VQMessageError text={saveError}></VQMessageError>}
                <div className="col-container">
                  <VQDynamicFormComponent
                    item={name}
                    errors={errors}
                    handleChange={handleChange}
                    customClass="col-container-el"
                    showError={true}
                    isFormInvalid={formInvalid}></VQDynamicFormComponent>
                  <VQDynamicFormComponent
                    item={lastName}
                    errors={errors}
                    handleChange={handleChange}
                    customClass="col-container-el"
                    showError={true}
                    isFormInvalid={formInvalid}></VQDynamicFormComponent>
                </div>
                <div className="col-container">
                  <VQDynamicFormComponent
                    item={email}
                    errors={errors}
                    handleChange={handleChange}
                    customClass="col-container-el"
                    showError={true}
                    isFormInvalid={formInvalid}></VQDynamicFormComponent>
                  <VQDynamicFormComponent
                    item={location}
                    errors={errors}
                    handleChange={handleChange}
                    customClass="col-container-el"
                    showError={true}
                    isFormInvalid={formInvalid}></VQDynamicFormComponent>
                </div>
                <div className="col-container">
                  <VQDynamicFormComponent
                    item={typeUser}
                    errors={errors}
                    handleChange={handleChange}
                    customClass="col-container-el"
                    showError={true}
                    isFormInvalid={formInvalid}></VQDynamicFormComponent>
                  <div className="col-container-el"></div>
                </div>
              </div>
              <div className="btn-container">
                <VQLightButton type="button" text="Cancel" click={hide}/>
                <VQBlueButton type="submit" text={isEdit ? "Save" : "Invite"} click={() => {}}/>
              </div>
            </form>
          </div>
        </SlAdminInviteModalWrapper>
      </Modal.Body>
    </Modal>
  );
});
