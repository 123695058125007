import { observer } from "mobx-react-lite";
import { FC } from "react";
import { VQGreenButton, VQTableSearchSection } from "vivaquant-components-library";
import { useRootStore } from "../../stores/storeContext";
import { Permissions } from "../../services/permission.service";
import { ETAdminUsersInviteModal } from "../et-admin-users-invite-modal";

export const ETAdminUsersListHeader: FC = observer(() => {
  const { setGlobalSpiner } = useRootStore().globalStore;
  const { resetForm, openModal, fetchData } = useRootStore().adminUsersInviteStore;
  const { hasPermission } = useRootStore().userStore;

  // const handleSearchChange = (value: string) => {
  //   setSearch(value);
  //   refreshData();
  // };

  // const refreshData = useMemo(
  //   () => debounce(() => {
  //     setFirstPage();
  //     getTotalCount();
  //   }, 1000), []);

  const openCreate = () => {
    resetForm();
    setGlobalSpiner(true);
    return fetchData().then(() => {
      openModal();
      setGlobalSpiner(false);
    });
  };

    
  return (
    <>
      <VQTableSearchSection>
        {/* <VQInputSearch
          handleChange={handleSearchChange}
          placeholder={"Search"}
          value={searchValue}
          autoComplete={false}/> */}
        {hasPermission(Permissions.UserInvite) && <div className="left">
          <VQGreenButton
            click={openCreate}
            text="Invite"
            isDisabled={!hasPermission(Permissions.UserInvite)}
            width="254px"></VQGreenButton>
        </div>}
      </VQTableSearchSection>
      {/* <VQTableCategoriesSection>
        {AdminListCategories.map((item, index) => (
          <VQCategoryItem
            key={index}
            onChange={changeListCategory}
            title={item.title}
            icon={activeCategory === item.value ? item.iconActive : item.icon}
            count={categoriesTotals[`${item.value}CategoryTotal`]}
            value={item.value}
            isActive={activeCategory === item.value}
          ></VQCategoryItem>
        ))}
        <div className="pagination">
          <VQPagination
            next={nextPage}
            previous={previousPage}
            total={total}
            countOnPage={PAGINATION.ROWS_PER_PAGE}
            page={page}/>
        </div>
      </VQTableCategoriesSection> */}
      <ETAdminUsersInviteModal></ETAdminUsersInviteModal>
    </>
  );
});
