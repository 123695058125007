import styled from "styled-components";
import { theme } from "../../theme/theme";

export const HeaderWrapper = styled.div<{shadow?: boolean}>`
  background: ${theme.colors.light};
  ${props => props.shadow ? "box-shadow: 8px 8px 16px rgba(0, 0, 0, 0.04);" : ""}
  height: ${theme.size.header};
  padding-left: ${theme.offsets.mainContent};
  padding-right: ${theme.offsets.mainContent};
  display: flex;
  align-items: center;
  z-index: 1;
  &.center {
    justify-content: center;
  }
  &.top {
    position: sticky;
    width: 100%;
    top: 0;
    z-index: 1;
    justify-content: center;

    .logo {
      position: absolute;
      left: 60px;
    }
  }
  .logo {
    height: 49px;
    width: auto;
    margin-right: 70px;
  }
  .logo-access {
    height: 64px;
    width: auto;
    margin-top: 24px;
  }
  .profile-img {
    box-shadow: 8px 8px 16px rgba(38, 86, 186, 0.04);
    border-radius: 8px;
    height: 40px;
    width: 40px;
    overflow: hidden;
    margin-left: 24px;
    margin-right: 8px;
    img {
      display: block;
      width: 100%;
      height: auto;
    }
  }

  .dropdown-wrapper {
    position: absolute;
    right: 60px;

    .dropdown-item div:after {
      display: none;
    }
  }
`;
