import { SnapshotIn, applySnapshot, flow, types } from "mobx-state-tree";
import { InputFormModel } from "vivaquant-components-library";
import { WI050FormState } from "../states/wi050-form.state";
import { WorkInstructionActionType } from "../boot/enums/WorkInstructionActionType";
import { TApiResponse } from "../types";
import { IWI050Response } from "../models/IWI050Response";
import { devicesApi } from "../services/api";
import { IWI050Request } from "../models/IWI050Request";

export const WI050FormModel = types
  .model("WI050FormModel", {
    show: types.optional(types.boolean, false),
    deviceId: types.maybeNull(types.string),
    batchId: types.maybeNull(types.string),
    serialNumber: types.maybeNull(types.string),
    wiRev: types.maybe(InputFormModel),
    failureMode: types.maybe(InputFormModel),
    comment: types.maybe(InputFormModel),
    stage: types.maybeNull(types.number),
    baseData: types.frozen(),
    isNextDevice: types.optional(types.boolean, true)
  })
  .actions(self => {
    const resetForm = () => {
      self.deviceId = "";
      self.batchId = "";
      self.serialNumber = "";
      self.stage = 0;
      self.baseData = {};
      self.isNextDevice = true;
      applySnapshot(self, WI050FormState);
    };

    const openForm = () => {
      self.show = true;
    };

    const closeForm = () => {
      self.show = false;
      resetForm();
    };

    const validateField = (action: number): {errors: {[key: string]: string}, isValid: boolean} => {
      const errors: {[key: string]: string} = {};
      let isValid: boolean = true;

      if (action === WorkInstructionActionType.Pass) {
        if (!self.wiRev?.value) {
          errors.wiRev = "Please fill the field";
          isValid = false;
        }
      }

      return {
        errors,
        isValid
      };
    };

    const fetchData = flow(function* fetchData(
      id: string, 
      itemBatchIdNext: string, 
      isTransferData?: boolean, 
      isHistorical?: boolean
    ) {
      try {
        const response: TApiResponse<IWI050Response> = yield devicesApi.getWI050(id, isHistorical);
        if (response.isOk) {
          const { data } = response;
          self.deviceId = data.deviceId;
          self.batchId = data.batchId;
          self.serialNumber = data.serialNumber;
          self.stage = data.stage;
          self.wiRev.setValue(
            data.wiRev ? data.wiRev 
              : isTransferData ? self.wiRev.value : ""
          );
          
          self.failureMode.setLoading(true);
          self.failureMode.setValue(data.failureMode.toString());
          const option = self.failureMode.options.find((item) => item.value === data.failureMode.toString());
          if (option) {
            self.failureMode.setDefaultValue({ value: option.value.toString(), label: option.label });
          }
          setTimeout(() => {
            self.failureMode.setLoading(false);
          }, 2);
          self.comment.setValue(data.comment || "");

          if (self.batchId !== itemBatchIdNext) {
            self.isNextDevice = false;
          }

          self.baseData = {
            wiRev: data.wiRev,
            comment: data.comment,
            failureMode: data.failureMode,
          };
        }
      } catch (error) {
        console.error(error);
      }
    });

    const saveForm = flow(function* saveForm(action: number, isHistorical?: boolean) {
      try {
        const data: IWI050Request = {
          deviceId: self.deviceId,
          wiRev: self.wiRev.value,
          comment: self.comment.value,
          failureMode: +self.failureMode.value,
          action: action
        };
        if (isHistorical) {
          delete data.action;
        }
        const result =  yield devicesApi.saveWI050(data, isHistorical);
        if (isHistorical) {
          fetchData(self.deviceId, "", false, true);
        }
        return result;
      } catch (error) {
        console.error(error);
        return error;
      }
    });

    const isFormChanged = () => {
      if (!self.baseData) {
        return false;
      }
      if ( self.wiRev.value !== self.baseData.wiRev
        || self.comment.value !== self.baseData.comment
        || +self.failureMode.value !== self.baseData.failureMode
      ) {
        return true;
      } else {
        return false;
      }
    };

    const setIsNextDevice = (value: boolean) => {
      self.isNextDevice = value;
    };

    return {
      fetchData,
      openForm,
      closeForm,
      resetForm,
      validateField,
      saveForm,
      isFormChanged,
      setIsNextDevice
    };
  });

export interface IWI050FormModelSnapShot extends SnapshotIn<typeof WI050FormModel> {}
