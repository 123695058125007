export enum WI119FailureMode {
  None = 0,
  Unresponsive = 1,
  DeviceNotReleased = 2,
  Labeling = 3,
  PhysicalDamage = 4,
  DocumentationError = 5,
  ChargeFailure = 6,
  Other = 7
}

export enum WI119FailureModeLabels {
  None = "None",
  Unresponsive = "Unresponsive",
  DeviceNotReleased = "Device not released",
  Labeling = "Labeling",
  PhysicalDamage = "Physical Damage",
  DocumentationError = "Documentation error",
  ChargeFailure = "Charge failure",
  Other = "Other"
}

