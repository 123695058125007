export enum ETColors {
  /** Color: #FFF */
  light = "#FFF",
  /** Color: #E5E5E5 */
  background = "#E5E5E5",
  /** Color: #F3F3F3 */
  backgroundTableHeader = "#F3F3F3",
  /** Color: #EEF6FE */
  backgroundMessage = "#EEF6FE",
  /** Color: #FEEEEE */
  backgroundMessageError = "#FEEEEE",
  /** Color: #484848 */
  text = "#484848",
  /** Color: rgba(72, 72, 72, 0.7) */
  textTableHeader = "rgba(72, 72, 72, 0.7)",
  /** Color: #457EF5 */
  blue = "#457EF5",
  /** Color: #B9B9B9 */
  greyBorder = "#B9B9B9",
  /** Color: #F45B5B */
  red = "#F45B5B",
  /** Color: #52CA84 */
  green = "#52CA84",
  /** Color: #A4A4A4 */
  darkGreen = "#A4A4A4",
  /** Color: #333333 */
  darkGrey = "#333333",
  /** Color: #6D6D6D */
  placeholder = "#6D6D6D",
  /** Color: #656B72 */
  label = "#656B72",
  /** Color: #D8D8D8 */
  split = "#D8D8D8",
  /** Color: #e8e8e8 */
  disableBackground = "#e8e8e8",
  /** Color: #7F7F7F */
  duration = "#7F7F7F",
  /** Color: #FFC42E */
  yellow = "#FFC42E",
  /** Color: #2F2F2FB2 */
  backgroundSemiDark = "#2F2F2FB2",
  /** Color: #2B2B2B */
  textWhiteButton = "#2B2B2B",
  /** Color: #E6EDFB */
  lightBlue = "#E6EDFB",
  /** Color: #B6B6B6 */
  inactiveText = "#B6B6B6",
  /** Color: #F1F8FF */
  hoveredAction = "#F1F8FF",
  /** Color: #D1D7E3 */
  greyDivider = "#D1D7E3",
  /** Color: #C1D4FB */
  uploadBorder = "#C1D4FB",
  /** Color: #FBE0E0 */
  pink = "#FBE0E0"
}
