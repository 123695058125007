export enum WI049FailureMode {
  None = 0,
  BoardPowerOnFailure = 1,
  CannotCompleteHardReset = 2,
  FailedPost = 3,
  LedFailure = 4,
  Other = 5
}

export enum WI049FailureModeLabels {
  None = "None",
  BoardPowerOnFailure = "Board Power On Failure",
  CannotCompleteHardReset = "Cannot Complete Hard Reset",
  FailedPost = "Failed POST",
  LedFailure = "LED failure",
  Other = "Other"
}