import { API } from "../../boot/api";
import { ApiResponseDto } from "../../models/api";
import { TApiResponse } from "../../types";
import { ProcessAPIError } from "../decorators";


class InviteApi {
  @ProcessAPIError()
  public async isInviteTokenValid(token: string): Promise<TApiResponse<boolean>> {
    const result = await API.get(`invite/${token}/is-valid`);
    return new ApiResponseDto(result.data);
  }

  @ProcessAPIError()
  public async completeInvite(link: string, username: string, password: string): Promise<TApiResponse<null>> {
    await API.patch("/invite/complete", { link, username, password });
    return new ApiResponseDto(null);
  }

  // @ProcessAPIError()
  // public async getCounters(filter: BaseFilter): Promise<ApiResponseType<{
  //   all: number,
  //   salesRep: number,
  //   support: number,
  //   superusers: number,
  //   accounting: number,
  //   executive: number,
  //   production: number
  // }>> {
  //   const result = await API.get("/admin/counters", { params: filter }).then(r => r.data);
  //   return new ApiResponseDto(result);
  // }

  // @ProcessAPIError()
  // public async getAdmins(filter: BaseFilter, groupName: string)
  //   : Promise<ApiResponseDto<TPaginated<IAdminModelSnapShot[]>>> {
  //   const { data } = await API.get("/employees/detailed",
  //     { params: groupName ? { ...filter, groupName } : filter });
  //   return new ApiResponseDto(data);
  // }

  @ProcessAPIError()
  public async getGroupsAllowed() : Promise<ApiResponseDto<any>> {
    const { data } = await API.get("/invite/allowed-groups");
    return new ApiResponseDto(data);
  }

  @ProcessAPIError()
  public async addAdmin(data: {
    firstName: string,
    lastName: string,
    email: string,
    phone: string,
    groups: [string] }
  ) {
    const result = await API.post("/invite", data);
    return new ApiResponseDto(result);
  }

  // @ProcessAPIError()
  // public async updateAdmin(data: {
  //   firstName: string,
  //   lastName: string,
  //   email: string,
  //   phone: string,
  //   groups: [number] },
  // id: number
  // ) {
  //   const result = await API.put(`/employees/${id}`, data);
  //   return new ApiResponseDto(result);
  // }

  // @ProcessAPIError()
  // public async deleteAdmin(id: number) {
  //   const result = await API.delete(`/employees/${id}`);
  //   return new ApiResponseDto(result);
  // }

}

export const inviteAPI = new InviteApi();
