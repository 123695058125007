import { SnapshotIn, applySnapshot, flow, types } from "mobx-state-tree";
import { InputFormModel } from "vivaquant-components-library";
import { WI058FormState } from "../states/wi058-form.state";
import { WorkInstructionActionType } from "../boot/enums/WorkInstructionActionType";
import { TApiResponse } from "../types";
import { IWI058Response } from "../models/IWI058Response";
import { devicesApi } from "../services/api";
import { IWI058Request } from "../models/IWI058Request";

export const WI058FormModel = types
  .model("WI058FormModel", {
    show: types.optional(types.boolean, false),
    deviceId: types.maybeNull(types.string),
    batchId: types.maybeNull(types.string),
    serialNumber: types.maybeNull(types.string),
    wiRev: types.maybe(InputFormModel),
    noneHasGlue: types.optional(types.boolean, false),
    connectorHasGlue: types.optional(types.boolean, false),
    housingSeamHasGlue: types.optional(types.boolean, false),
    raLeadHasGlue: types.optional(types.boolean, false),
    laLeadHasGlue: types.optional(types.boolean, false),
    rlLeadHasGlue: types.optional(types.boolean, false),
    llLeadHasGlue: types.optional(types.boolean, false),
    otherHasGlue: types.optional(types.boolean, false),
    failureMode: types.maybe(InputFormModel),
    comment: types.maybe(InputFormModel),
    stage: types.maybeNull(types.number),
    baseData: types.frozen(),
    errorOther: types.optional(types.string, ""),
    isNextDevice: types.optional(types.boolean, true)
  })
  .actions(self => {
    const resetForm = () => {
      self.deviceId = "";
      self.batchId = "";
      self.serialNumber = "";
      self.stage = 0;
      self.baseData = {};
      self.noneHasGlue = false;
      self.connectorHasGlue = false; 
      self.housingSeamHasGlue = false; 
      self.raLeadHasGlue = false; 
      self.laLeadHasGlue = false; 
      self.rlLeadHasGlue = false; 
      self.llLeadHasGlue = false; 
      self.otherHasGlue = false;
      self.errorOther = "";
      self.isNextDevice = true;
      applySnapshot(self, WI058FormState);
    };

    const setBooleanValue = (
      key: "noneHasGlue" 
        | "connectorHasGlue" 
        | "housingSeamHasGlue"
        | "raLeadHasGlue"
        | "laLeadHasGlue"
        | "rlLeadHasGlue"
        | "llLeadHasGlue"
        | "otherHasGlue",
      value: boolean) => {
      if(key === "noneHasGlue" && value === true) {
        self.noneHasGlue = true;
        self.connectorHasGlue = false; 
        self.housingSeamHasGlue = false; 
        self.raLeadHasGlue = false; 
        self.laLeadHasGlue = false; 
        self.rlLeadHasGlue = false; 
        self.llLeadHasGlue = false; 
        self.otherHasGlue = false;
      } else {
        self.noneHasGlue = false;
        self[key] = value;
      }
    };

    const openForm = () => {
      self.show = true;
    };

    const closeForm = () => {
      self.show = false;
      resetForm();
    };

    const validateField = (action: number): {errors: {[key: string]: string}, isValid: boolean} => {
      const errors: {[key: string]: string} = {};
      let isValid: boolean = true;

      if (action === WorkInstructionActionType.Pass) {
        if (!self.wiRev?.value) {
          errors.wiRev = "Please fill the field";
          isValid = false;
        }

        if (self.otherHasGlue && !self.comment?.value) {
          self.errorOther = "Please fill the field";
          isValid = false;
        } else {
          self.errorOther = "";
        }
      }

      return {
        errors,
        isValid
      };
    };

    const fetchData = flow(function* fetchData(
      id: string, 
      itemBatchIdNext: string, 
      isTransferData?: boolean, 
      isHistorical?: boolean
    ) {
      try {
        const response: TApiResponse<IWI058Response> = yield devicesApi.getWI058(id, isHistorical);
        if (response.isOk) {
          const { data } = response;
          self.deviceId = data.deviceId;
          self.batchId = data.batchId;
          self.serialNumber = data.serialNumber;
          self.stage = data.stage;
          self.wiRev.setValue(
            data.wiRev ? data.wiRev 
              : isTransferData ? self.wiRev.value : ""
          );
          self.failureMode.setLoading(true);
          self.failureMode.setValue(data.failureMode.toString());
          const option = self.failureMode.options.find((item) => item.value === data.failureMode.toString());
          if (option) {
            self.failureMode.setDefaultValue({ value: option.value.toString(), label: option.label });
          }
          setTimeout(() => {
            self.failureMode.setLoading(false);
          }, 2);
          self.comment.setValue(data.comment || "");

          if (self.batchId !== itemBatchIdNext) {
            self.isNextDevice = false;
          }

          const isNone = () => {
            if (!data.connectorHasGlue
              && !data.housingSeamHasGlue
              && !data.raLeadHasGlue
              && !data.laLeadHasGlue
              && !data.rlLeadHasGlue
              && !data.llLeadHasGlue
              && !data.otherHasGlue
            ) {
              return true;
            } else {
              return false;
            }
          };

          self.noneHasGlue = isNone();
          self.connectorHasGlue = data.connectorHasGlue; 
          self.housingSeamHasGlue = data.housingSeamHasGlue; 
          self.raLeadHasGlue = data.raLeadHasGlue; 
          self.laLeadHasGlue = data.laLeadHasGlue; 
          self.rlLeadHasGlue = data.rlLeadHasGlue; 
          self.llLeadHasGlue = data.llLeadHasGlue; 
          self.otherHasGlue = data.otherHasGlue;

          self.baseData = {
            wiRev: data.wiRev,
            noneHasGlue: isNone(),
            connectorHasGlue: data.connectorHasGlue,
            housingSeamHasGlue: data.housingSeamHasGlue,
            raLeadHasGlue: data.raLeadHasGlue,
            laLeadHasGlue: data.laLeadHasGlue,
            rlLeadHasGlue: data.rlLeadHasGlue,
            llLeadHasGlue: data.llLeadHasGlue,
            otherHasGlue: data.otherHasGlue,
            comment: data.comment,
            failureMode: data.failureMode,
          };

        }
      } catch (error) {
        console.error(error);
      }
    });

    const saveForm = flow(function* saveForm(action: number, isHistorical?: boolean) {
      try {
        const data: IWI058Request = {
          deviceId: self.deviceId,
          wiRev: self.wiRev.value,
          connectorHasGlue: self.connectorHasGlue,
          housingSeamHasGlue: self.housingSeamHasGlue,
          raLeadHasGlue: self.raLeadHasGlue,
          laLeadHasGlue: self.laLeadHasGlue,
          rlLeadHasGlue: self.rlLeadHasGlue,
          llLeadHasGlue: self.llLeadHasGlue,
          otherHasGlue: self.otherHasGlue,
          comment: self.comment.value,
          failureMode: +self.failureMode.value,
          action: action
        };
        if (isHistorical) {
          delete data.action;
        }
        const result =  yield devicesApi.saveWI058(data, isHistorical);
        if (isHistorical) {
          fetchData(self.deviceId, "", false, true);
        }
        return result;
      } catch (error) {
        console.error(error);
        return error;
      }
    });

    const isFormChanged = () => {
      if (!self.baseData) {
        return false;
      }
      if ( self.wiRev.value !== self.baseData.wiRev
        || self.noneHasGlue!== self.baseData.noneHasGlue
        || self.connectorHasGlue !== self.baseData.connectorHasGlue
        || self.housingSeamHasGlue !== self.baseData.housingSeamHasGlue
        || self.raLeadHasGlue !== self.baseData.raLeadHasGlue
        || self.laLeadHasGlue !== self.baseData.laLeadHasGlue
        || self.rlLeadHasGlue !== self.baseData.rlLeadHasGlue
        || self.llLeadHasGlue !== self.baseData.llLeadHasGlue
        || self.otherHasGlue !== self.baseData.otherHasGlue
        || self.comment.value !== self.baseData.comment
        || +self.failureMode.value !== self.baseData.failureMode
      ) {
        return true;
      } else {
        return false;
      }
    };

    const setIsNextDevice = (value: boolean) => {
      self.isNextDevice = value;
    };

    return {
      fetchData,
      openForm,
      closeForm,
      resetForm,
      validateField,
      saveForm,
      isFormChanged,
      setBooleanValue,
      setIsNextDevice
    };
  });

export interface IWI058FormModelSnapShot extends SnapshotIn<typeof WI058FormModel> {}
