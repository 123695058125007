import { FC, useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Logo from "../../assets/img/rhythm-express-by-viva-quant.png";
import { observer } from "mobx-react-lite";
import { useRootStore } from "../../stores/storeContext";
import { HeaderWrapper } from "./et-header.presents";
import { DASHBOARD_ROUTES } from "./et-header-links";
import { ETNavItem } from "../et-nav-item";
import ExitIcon from "../../assets/icons/exit.svg";
import ProfileIcon from "../../assets/icons/profile.svg";
import DropdownIcon from "../../assets/icons/dropdown.svg";
import { theme } from "../../theme/theme";
import { VQDropdown } from "vivaquant-components-library";
import { WIFormService } from "../../services/wi-form.service";
import { confirmAlert } from "react-confirm-alert";
import { StringsUtils } from "../../services/utils";
import { WorkInstructionType } from "../../boot/enums/WorkInstructionType";

export const ETHeader: FC = observer(() => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const stores = useRootStore();
  const { hasPermission, profile, logout } = useRootStore().userStore;
  const { setOpenedRightForm } = useRootStore().globalStore;

  const getWIName = (value: number) => {
    return StringsUtils.getWIStoreName(WorkInstructionType[value]);
  };
  
  const currentPage = useMemo(() => {
    return pathname.split("/")[1];
  }, [pathname]);

  const username = useMemo(() => {
    return profile.firstname && profile.lastname
      ? `${profile.firstname} ${profile.lastname}`
      : profile.email;
  }, [profile.firstname, profile.lastname, profile.email]);

  const actionHandler = (action: Function) => {
    let closeForm: Function = null;
    let isFormChanged: Function = null;
    let selectedStore: any = {};
    let storeName = "";

    for (const [key, value] of Object.entries(stores)) {
      if (value.show) {
        closeForm = value.closeForm;
        isFormChanged = value.isFormChanged;
        selectedStore = value;
        storeName = key;
      }
    }

    if (closeForm && isFormChanged) {
      const hide = () => {
        setOpenedRightForm(false);
        closeForm();
        action();
      };

      if (storeName === "adminDevicesEditStore") {
        const { isFormChanged: isFormChangedWI } 
          = stores[getWIName(+selectedStore?.workInstruction?.value) as keyof typeof stores];
        WIFormService.onClose(() => { 
          return isFormChanged(selectedStore.isWIFormOpened ? isFormChangedWI : null); 
        }, confirmAlert, hide);
      } else {
        return WIFormService.onClose(isFormChanged, confirmAlert, hide);
      }
    } else {
      action();
    }
  };

  const profileActions: any = [{
    text: "Profile",
    color: theme.colors.text,
    icon: ProfileIcon,
    action: () => {
      return actionHandler(() => {
        return navigate("/profile");
      });
    }
  }, {
    text: "Exit",
    color: theme.colors.red,
    icon: ExitIcon,
    action: () => {
      return actionHandler(() => {
        logout();
        return navigate("/login");
      });
    }
  }];

  return (
    <HeaderWrapper shadow={true} className={"top"}>
      <img className="logo" src={Logo} alt="Rhythm express logo"/>
      {DASHBOARD_ROUTES
        .map(({ link, title, permission }) =>
          !permission || hasPermission(permission)
            ? <ETNavItem
              key={link}
              isActive={link === currentPage}
              link={link}
            >
              {title}
            </ETNavItem>
            : null)
      }
      <div className="dropdown-wrapper">
        <VQDropdown
          text={`${username} (${profile.group})`}
          icon={DropdownIcon}
          actions={profileActions}
          rotateIcon={true}></VQDropdown>
      </div>
    </HeaderWrapper>
  );
});
