import { observer } from "mobx-react-lite";
import { FC } from "react";
import { useRootStore } from "../../stores/storeContext";
import { InProgressListSort, PAGINATION, SortDirection } from "../../boot/constants";
import { VQIcon, VQIconSort, VQNoResult, VQTableLoader, VQTableSection, VQTooltip } from "vivaquant-components-library";
import { IInProgressModel } from "../../stores/in-progress-list.store";
import SortListIcon from "../../assets/icons/sort-list.svg";
import CopyIcon from "../../assets/icons/copy.svg";
import _uniqueId from "lodash.uniqueid";
import CopyToClipboard from "react-copy-to-clipboard";
import { message } from "antd";
import { WorkInstructionType } from "../../boot/enums/WorkInstructionType";
import ExecuteIcon from "../../assets/icons/execute.svg";
import ReporteIcon from "../../assets/icons/report.svg";
import { DateUtils, StringsUtils } from "../../services/utils";
import { WIFormService } from "../../services/wi-form.service";
import { confirmAlert } from "react-confirm-alert";
import { Permissions } from "../../services/permission.service";

export const ETInProgressListTable: FC = observer(() => {
  const {
    items,
    isLoading,
    setSortBy,
    activeCategory,
    itemBatchIdNextPage
  } = useRootStore().inProgressListStore;
  const { setGlobalSpiner, setOpenedRightForm, isOpenedRightForm } = useRootStore().globalStore;
  const { openModal, getReport } = useRootStore().reportStore;

  const { resetForm: resetFormWI041, fetchData: fetchDataWI041, openForm: openFormWI041, deviceId: deviceIdWI041  }
    = useRootStore().wi041FormStore;
  const { resetForm: resetFormWI068, fetchData: fetchDataWI068, openForm: openFormWI068, deviceId: deviceIdWI068  }
    = useRootStore().wi068FormStore;
  const { resetForm: resetFormWI056, fetchData: fetchDataWI056, openForm: openFormWI056, deviceId: deviceIdWI056  }
    = useRootStore().wi056FormStore;
  const { resetForm: resetFormWI083, fetchData: fetchDataWI083, openForm: openFormWI083, deviceId: deviceIdWI083  }
    = useRootStore().wi083FormStore;
  const { resetForm: resetFormWI048, fetchData: fetchDataWI048, openForm: openFormWI048, deviceId: deviceIdWI048  }
    = useRootStore().wi048FormStore;
  const { resetForm: resetFormWI049, fetchData: fetchDataWI049, openForm: openFormWI049, deviceId: deviceIdWI049  }
    = useRootStore().wi049FormStore;
  const { resetForm: resetFormWI057, fetchData: fetchDataWI057, openForm: openFormWI057, deviceId: deviceIdWI057  }
    = useRootStore().wi057FormStore;
  const { resetForm: resetFormWI050, fetchData: fetchDataWI050, openForm: openFormWI050, deviceId: deviceIdWI050  }
    = useRootStore().wi050FormStore;
  const { resetForm: resetFormWI058, fetchData: fetchDataWI058, openForm: openFormWI058, deviceId: deviceIdWI058  }
    = useRootStore().wi058FormStore;
  const { resetForm: resetFormWI051, fetchData: fetchDataWI051, openForm: openFormWI051, deviceId: deviceIdWI051  }
    = useRootStore().wi051FormStore;
  const { resetForm: resetFormWI119, fetchData: fetchDataWI119, openForm: openFormWI119, deviceId: deviceIdWI119  }
    = useRootStore().wi119FormStore;
  const { resetForm: resetFormWI059ER, fetchData: fetchDataWI059ER,
    openForm: openFormWI059ER, deviceId: deviceIdWI059ER  }
    = useRootStore().wi059ERFormStore;

  const stores = useRootStore();

  const { hasPermission } = useRootStore().userStore;
  const isReportRead = hasPermission(Permissions.ReportRead);

  const getOpenedDeviceId = () => {
    return deviceIdWI041 
    || deviceIdWI068
    || deviceIdWI056
    || deviceIdWI083
    || deviceIdWI048
    || deviceIdWI049
    || deviceIdWI057
    || deviceIdWI050
    || deviceIdWI058
    || deviceIdWI051
    || deviceIdWI119
    || deviceIdWI059ER;
  };

  const showCopied = () => {
    const key = "updatable";
    message.success({ content: "Copied", key, duration: 2 });
  };

  const checkOpenedForm = (action: Function) => {
    let closeForm: Function = null;
    let isFormChanged: Function = null;
    Object.values(stores).forEach((store) => {
      if (store.show) {
        closeForm = store.closeForm;
        isFormChanged = store.isFormChanged;
      }
    });

    if (closeForm && isFormChanged) {
      const hide = () => {
        setOpenedRightForm(false);
        closeForm();
        action();
      };
      WIFormService.onClose(isFormChanged, confirmAlert, hide);
    } else {
      action();
    }
  };

  const onExecute = (id: string, stage: number) => {
    let resetForm: Function;
    let fetchData: Function;
    let openForm: Function;
    switch (stage) {
      case WorkInstructionType.WI041:
        resetForm = resetFormWI041;
        fetchData = fetchDataWI041;
        openForm = openFormWI041;
        break;
      case WorkInstructionType.WI068:
        resetForm = resetFormWI068;
        fetchData = fetchDataWI068;
        openForm = openFormWI068;
        break;
      case WorkInstructionType.WI056:
        resetForm = resetFormWI056;
        fetchData = fetchDataWI056;
        openForm = openFormWI056;
        break;
      case WorkInstructionType.WI083:
        resetForm = resetFormWI083;
        fetchData = fetchDataWI083;
        openForm = openFormWI083;
        break;
      case WorkInstructionType.WI048:
        resetForm = resetFormWI048;
        fetchData = fetchDataWI048;
        openForm = openFormWI048;
        break;
      case WorkInstructionType.WI049:
        resetForm = resetFormWI049;
        fetchData = fetchDataWI049;
        openForm = openFormWI049;
        break;
      case WorkInstructionType.WI057:
        resetForm = resetFormWI057;
        fetchData = fetchDataWI057;
        openForm = openFormWI057;
        break;
      case WorkInstructionType.WI050:
        resetForm = resetFormWI050;
        fetchData = fetchDataWI050;
        openForm = openFormWI050;
        break;
      case WorkInstructionType.WI058:
        resetForm = resetFormWI058;
        fetchData = fetchDataWI058;
        openForm = openFormWI058;
        break;
      case WorkInstructionType.WI051:
        resetForm = resetFormWI051;
        fetchData = fetchDataWI051;
        openForm = openFormWI051;
        break;
      case WorkInstructionType.WI119:
        resetForm = resetFormWI119;
        fetchData = fetchDataWI119;
        openForm = openFormWI119;
        break;
      case WorkInstructionType.WIER:
        resetForm = resetFormWI059ER;
        fetchData = fetchDataWI059ER;
        openForm = openFormWI059ER;
        break;
    }

    if (!resetForm) return;

    checkOpenedForm(() => {
      resetForm();
      setOpenedRightForm(true);
      setGlobalSpiner(true);

      let nextDevice = null;
      const index = items.findIndex((el: IInProgressModel) => el.id === id);
      if (index !== -1 && index === items.length - 1) {
        nextDevice = itemBatchIdNextPage;
      } else if (index !== -1 && index < items.length - 1) {
        nextDevice = items[index + 1].batchId;
      }
      return fetchData(id, nextDevice).then(() => {
        openForm();
        setGlobalSpiner(false);
      });
    });
  };

  const onReport = (id: string, serialNumber: string, date: string) => {
    setGlobalSpiner(true);
    return getReport(id, StringsUtils.reportName(serialNumber, date))
      .then((msg: string) => {
        if (msg) {
          confirmAlert({
            message: msg,
            overlayClassName: "sl-warning-modal",
            buttons: [
              {
                label: "Ok",
                onClick: () => {}
              }
            ]
          });
        } else {
          openModal();
        }
        
        setGlobalSpiner(false);
      });
  };

  const getContent = () => {
    if (isLoading) {
      return (
        <VQTableLoader
          columns={isOpenedRightForm ? 5 : 8}
          rows={PAGINATION.ROWS_PER_PAGE}/>
      );
    }

    return (
      <tbody>
        {items.map((item: IInProgressModel, index: number) => {
          return (
            <tr key={index} className={`${getOpenedDeviceId() === item.id ? "tr-selected" : ""}`}>
              <td>
                <div className="td-item">
                  {item.batchId || "-"}
                </div>
              </td>
              <td>
                <div className="td-item">
                  {item.routeConfiguration?.name || "-"}
                </div>
              </td>
              <td>
                <div className="td-item">
                  {item.serialNumber || "-"}
                </div>
              </td>
              <td>
                <div className="td-item">
                  {StringsUtils.addWIDashes(WorkInstructionType[item.stage]) || "-"}
                </div>
              </td>
              {!isOpenedRightForm && <td>
                <div className="td-item">
                  {item.imei || "-"}
                </div>
              </td>}
              {!isOpenedRightForm && <td>
                <div className="td-item">
                  {item.iccid
                    ? <VQTooltip
                      id={`tooltip-${_uniqueId()}`}
                      class="vq-tooltip"
                      tooltipBlock={<div>Copy</div>}>
                      <div className="td-nowrap copy">
                        <span>{item.iccid}</span>
                        <span className="copy-icon">
                          <CopyToClipboard
                            text={item.iccid}
                            onCopy={() => showCopied()}>
                            <VQIcon icon={CopyIcon} height="20px" width="20px"></VQIcon>
                          </CopyToClipboard>
                        </span>
                      </div>
                    </VQTooltip>
                    : "-"}
                </div>
              </td>}
              {!isOpenedRightForm && <td>
                <div className="td-item">
                  {item.boardReversion || "-"}
                </div>
              </td>}
              {activeCategory === WorkInstructionType.WIER.toString() && <td>
                <div className="td-item">
                  {item.movedToStageAt ? DateUtils.formatDate(item.movedToStageAt) : "-"}
                </div>
              </td>}
              <td className="action">
                <div className="td-item">
                  <div className="td-item actions-wrappwer">
                    <div
                      className={"action-item"}
                      onClick={() => { onExecute(item.id, item.stage); }}>
                      <VQTooltip
                        id={`tooltip-${_uniqueId()}`}
                        class="vq-tooltip"
                        tooltipBlock={<div>Edit</div>}>
                        <VQIcon 
                          icon={ExecuteIcon}
                          padding="0 8px"/>
                      </VQTooltip>
                    </div>
                    <div
                      className={`action-item ${!isOpenedRightForm && isReportRead && item.report ? "" : "disabled"}`}
                      style={{margin: "0"}}
                      onClick={() => { onReport(item.report.id, item.serialNumber, item.report.updatedAt); }}>
                      <VQTooltip
                        id={`tooltip-${_uniqueId()}`}
                        class="vq-tooltip"
                        tooltipBlock={<div>Open Device Report</div>}>
                        <VQIcon 
                          icon={ReporteIcon}
                          padding="0 8px"/>
                      </VQTooltip>
                    </div>
                  </div>
                </div>
              </td>
            </tr>
          );
        })}
      </tbody>
    );
  };

  const sort = (sortBy: string, sortDirection: number) => {
    return setSortBy(sortBy, sortDirection);
  };


  return (
    <div className="margin-table scroll-table">
      <VQTableSection>
        <thead>
          <tr>
            <th>
              <div className="sort">
                <div className="sort-actions">
                  <div onClick={() => sort(InProgressListSort.batchId, SortDirection.ASC)}>
                    <VQIconSort icon={SortListIcon}></VQIconSort>
                  </div>
                  <div onClick={() => sort(InProgressListSort.batchId, SortDirection.DESC)} className="bottom-sort">
                    <VQIconSort icon={SortListIcon}></VQIconSort>
                  </div>
                </div>
                <div className="sort-title">Batch ID</div>
              </div>
            </th>
            <th>
              <div className="sort">
                <div className="sort-actions">
                  <div onClick={() => sort(InProgressListSort.routeConfiguration, SortDirection.ASC)}>
                    <VQIconSort icon={SortListIcon}></VQIconSort>
                  </div>
                  <div onClick={() => sort(InProgressListSort.routeConfiguration, SortDirection.DESC)}
                    className="bottom-sort">
                    <VQIconSort icon={SortListIcon}></VQIconSort>
                  </div>
                </div>
                <div className="sort-title">Route Configuration</div>
              </div>
            </th>
            <th>
              <div className="sort">
                <div className="sort-actions">
                  <div onClick={() => sort(InProgressListSort.serialNumber, SortDirection.ASC)}>
                    <VQIconSort icon={SortListIcon}></VQIconSort>
                  </div>
                  <div onClick={() => sort(InProgressListSort.serialNumber, SortDirection.DESC)}
                    className="bottom-sort">
                    <VQIconSort icon={SortListIcon}></VQIconSort>
                  </div>
                </div>
                <div className="sort-title">Serial Number</div>
              </div>
            </th>
            <th>
              <div className="sort">
                <div className="sort-actions">
                  <div onClick={() => sort(InProgressListSort.stage, SortDirection.ASC)}>
                    <VQIconSort icon={SortListIcon}></VQIconSort>
                  </div>
                  <div onClick={() => sort(InProgressListSort.stage, SortDirection.DESC)} className="bottom-sort">
                    <VQIconSort icon={SortListIcon}></VQIconSort>
                  </div>
                </div>
                <div className="sort-title">Stage</div>
              </div>
            </th>
            {!isOpenedRightForm && <th>IMEI</th>}
            {!isOpenedRightForm && <th>ICCID</th>}
            {!isOpenedRightForm && <th>Board Revision</th>}
            {activeCategory === WorkInstructionType.WIER.toString() && <th>
              <div className="sort">
                <div className="sort-actions">
                  <div onClick={() => sort(InProgressListSort.movedtostageat, SortDirection.ASC)}>
                    <VQIconSort icon={SortListIcon}></VQIconSort>
                  </div>
                  <div onClick={() => sort(InProgressListSort.movedtostageat, SortDirection.DESC)} 
                    className="bottom-sort">
                    <VQIconSort icon={SortListIcon}></VQIconSort>
                  </div>
                </div>
                <div className="sort-title">Date</div>
              </div>
            </th>}
            <th className="action">Actions</th>
          </tr>
        </thead>
        {isLoading && getContent()}
        {items.length > 0 && !isLoading && getContent()}
        {items.length === 0 && !isLoading && <VQNoResult/> }
      </VQTableSection>
    </div>
  );
});
