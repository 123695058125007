export enum WI059ERFailureMode {
  None = 0,
  VibrationFailure = 1,
  PostFailure = 2,
  NoCommunication = 3,
  ChargeFailure = 4,
  EcgFailure = 5,
  CellSignalFailure = 6,
  Labeling = 7,
  Unresponsive = 8,
  ResetLoop = 9,
  PhysicalDamage = 10,
  Other = 11
}

export enum WI059ERFailureModeLabels {
  None = "None",
  VibrationFailure = "Vibration Failure",
  PostFailure = "POST Failure",
  NoCommunication = "No Communication",
  ChargeFailure = "Charge failure",
  EcgFailure = "ECG failure",
  CellSignalFailure = "Cell Signal failure",
  Labeling = "Labeling",
  Unresponsive = "Unresponsive",
  ResetLoop = "Reset Loop",
  PhysicalDamage = "Physical Damage",
  Other = "Other"
}