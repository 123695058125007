import { Instance, SnapshotIn, applySnapshot, flow, types } from "mobx-state-tree";
import { InputFormModel } from "vivaquant-components-library";
import { WI059ERFormState } from "../states/wi059er-form.state";
import { WorkInstructionActionType } from "../boot/enums/WorkInstructionActionType";
import { TApiResponse } from "../types";
import { IWI059ERResponse } from "../models/IWI059ERResponse";
import { devicesApi } from "../services/api";
import { IWI059ERRequest } from "../models/IWI059ERRequest";
import { WI059ERDispositionOfDevice } from "../boot/enums/WI059ERDispositionOfDevice";

export const WI059EROptionsModel = types
  .model("WI059EROptionsModel", {
    isPassed: types.maybeNull(types.boolean),
    updatedAt: types.maybeNull(types.string),
    lastEditor: types.maybeNull(types.model({
      id: types.maybeNull(types.string),
      firstname: types.maybeNull(types.string),
      lastname: types.maybeNull(types.string)
    }))
  });

export interface IWI059EROptionsModel extends Instance<typeof WI059EROptionsModel> {}
export interface IWI059EROptionsModelSnapShot extends SnapshotIn<typeof WI059EROptionsModel> {}

export const WI059ERFormModel = types
  .model("WI059ERFormModel", {
    show: types.optional(types.boolean, false),
    deviceId: types.maybeNull(types.string),
    batchId: types.maybeNull(types.string),
    serialNumber: types.maybeNull(types.string),
    wiRev: types.maybe(InputFormModel),
    failureMode: types.maybe(InputFormModel),
    comment: types.maybe(InputFormModel),
    stage: types.maybeNull(types.number),
    dispositionOfDevice: types.maybeNull(types.number),
    visualInspection: types.maybeNull(WI059EROptionsModel),
    activeLeadTestFixture: types.maybeNull(WI059EROptionsModel),
    connectorCleanupFit: types.maybeNull(WI059EROptionsModel),
    legsGluedProperly: types.maybeNull(WI059EROptionsModel),
    deviceCharging: types.maybeNull(WI059EROptionsModel),
    hardReset: types.maybeNull(WI059EROptionsModel),
    transitionToReady: types.maybeNull(WI059EROptionsModel),
    deviceInRecordingShowing3xGreenBlink: types.maybeNull(WI059EROptionsModel),
    cellSignalMoreThen100: types.maybeNull(WI059EROptionsModel),
    deviceReleasedAndShowsSolidGreen: types.maybeNull(WI059EROptionsModel),
    leakTest: types.maybeNull(WI059EROptionsModel),
    backLabelReApplied: types.maybeNull(WI059EROptionsModel),
    deviceCleaned: types.maybeNull(WI059EROptionsModel),
    baseData: types.frozen(),
    isNextDevice: types.optional(types.boolean, false),
    errorOther: types.optional(types.string, ""),
  })
  .actions(self => {
    const resetForm = () => {
      self.deviceId = "";
      self.batchId = "";
      self.serialNumber = "";
      self.stage = 0;
      self.baseData = {};
      self.isNextDevice = false;
      self.errorOther = "";
      applySnapshot(self, WI059ERFormState);
      self.dispositionOfDevice = null;
      self.visualInspection = null;
      self.activeLeadTestFixture = null;
      self.connectorCleanupFit = null;
      self.legsGluedProperly = null;
      self.deviceCharging = null;
      self.hardReset = null;
      self.transitionToReady = null;
      self.deviceInRecordingShowing3xGreenBlink = null;
      self.cellSignalMoreThen100 = null;
      self.deviceReleasedAndShowsSolidGreen = null;
      self.leakTest = null;
      self.backLabelReApplied = null;
      self.deviceCleaned = null;
    };

    const getIsAllPass = () => {
      return self.visualInspection.isPassed 
        && self.activeLeadTestFixture.isPassed
        && self.connectorCleanupFit.isPassed
        && self.legsGluedProperly.isPassed
        && self.deviceCharging.isPassed
        && self.hardReset.isPassed
        && self.transitionToReady.isPassed
        && self.deviceInRecordingShowing3xGreenBlink.isPassed
        && self.cellSignalMoreThen100.isPassed
        && self.deviceReleasedAndShowsSolidGreen.isPassed
        && self.leakTest.isPassed
        && self.backLabelReApplied.isPassed
        && self.deviceCleaned.isPassed;
    };

    const getIsAllNotNull = () => {
      return self.visualInspection.isPassed !== null 
        && self.activeLeadTestFixture.isPassed !== null 
        && self.connectorCleanupFit.isPassed !== null 
        && self.legsGluedProperly.isPassed !== null 
        && self.deviceCharging.isPassed !== null 
        && self.hardReset.isPassed !== null 
        && self.transitionToReady.isPassed !== null 
        && self.deviceInRecordingShowing3xGreenBlink.isPassed !== null 
        && self.cellSignalMoreThen100.isPassed !== null 
        && self.deviceReleasedAndShowsSolidGreen.isPassed !== null 
        && self.leakTest.isPassed !== null 
        && self.backLabelReApplied.isPassed !== null 
        && self.deviceCleaned.isPassed !== null ;
    };

    const setDispositionOfDevice = (value: number) => {
      self.dispositionOfDevice = value;
    };

    const setBooleanValue = (
      key: "visualInspection" 
        | "activeLeadTestFixture" 
        | "connectorCleanupFit"
        | "legsGluedProperly" 
        | "deviceCharging"
        | "hardReset" 
        | "transitionToReady"
        | "deviceInRecordingShowing3xGreenBlink" 
        | "cellSignalMoreThen100"
        | "deviceReleasedAndShowsSolidGreen" 
        | "leakTest"
        | "backLabelReApplied" 
        | "deviceCleaned", 
      value: boolean
    ) => {
      self[key].isPassed = value;
    };

    const openForm = () => {
      self.show = true;
    };

    const closeForm = () => {
      self.show = false;
      resetForm();
    };

    const validateField = (action: number): {errors: {[key: string]: string}, isValid: boolean} => {
      const errors: {[key: string]: string} = {};
      let isValid: boolean = true;

      if (action === WorkInstructionActionType.Pass) {
        if (!self.wiRev?.value) {
          errors.wiRev = "Please fill the field";
          isValid = false;
        }

        if (self.dispositionOfDevice === WI059ERDispositionOfDevice.Engineering && !self.comment?.value) {
          self.errorOther = "Please fill the field";
          isValid = false;
        } else {
          self.errorOther = "";
        }
      }
      
      return {
        errors,
        isValid
      };
    };

    const fetchData = flow(function* fetchData(
      id: string, 
      itemBatchIdNext: string, 
      isTransferData?: boolean, 
      isHistorical?: boolean
    ) {
      try {
        const response: TApiResponse<IWI059ERResponse> = yield devicesApi.getWI059ER(id, isHistorical);
        if (response.isOk) {
          const { data } = response;
          self.deviceId = data.deviceId;
          self.batchId = data.batchId;
          self.serialNumber = data.serialNumber;
          self.stage = data.stage;
          self.wiRev.setValue(
            data.wiRev ? data.wiRev 
              : isTransferData ? self.wiRev.value : ""
          );
          self.failureMode.setLoading(true);
          self.failureMode.setValue(data.failureMode.toString());
          const option = self.failureMode.options.find((item) => item.value === data.failureMode.toString());
          if (option) {
            self.failureMode.setDefaultValue({ value: option.value.toString(), label: option.label });
          }
          setTimeout(() => {
            self.failureMode.setLoading(false);
          }, 2);
          self.comment.setValue(data.comment || "");

          self.dispositionOfDevice = data.dispositionOfDevice;
          self.visualInspection = data.visualInspection;
          self.activeLeadTestFixture = data.activeLeadTestFixture;
          self.connectorCleanupFit = data.connectorCleanupFit;
          self.legsGluedProperly = data.legsGluedProperly;
          self.deviceCharging = data.deviceCharging;
          self.hardReset = data.hardReset;
          self.transitionToReady = data.transitionToReady;
          self.deviceInRecordingShowing3xGreenBlink = data.deviceInRecordingShowing3xGreenBlink;
          self.cellSignalMoreThen100 = data.cellSignalMoreThen100;
          self.deviceReleasedAndShowsSolidGreen = data.deviceReleasedAndShowsSolidGreen;
          self.leakTest = data.leakTest;
          self.backLabelReApplied = data.backLabelReApplied;
          self.deviceCleaned = data.deviceCleaned;

          if (self.batchId !== itemBatchIdNext) {
            self.isNextDevice = false;
          }

          self.baseData = {
            wiRev: data.wiRev,
            comment: data.comment,
            failureMode: data.failureMode,
            dispositionOfDevice: data.dispositionOfDevice,
            visualInspection: data.visualInspection,
            activeLeadTestFixture: data.activeLeadTestFixture,
            connectorCleanupFit: data.connectorCleanupFit,
            legsGluedProperly: data.legsGluedProperly,
            deviceCharging: data.deviceCharging,
            hardReset: data.hardReset,
            transitionToReady: data.transitionToReady,
            deviceInRecordingShowing3xGreenBlink: data.deviceInRecordingShowing3xGreenBlink,
            cellSignalMoreThen100: data.cellSignalMoreThen100,
            deviceReleasedAndShowsSolidGreen: data.deviceReleasedAndShowsSolidGreen,
            leakTest: data.leakTest,
            backLabelReApplied: data.backLabelReApplied,
            deviceCleaned: data.deviceCleaned,
          };

        }
      } catch (error) {
        console.error(error);
      }
    });

    const saveForm = flow(function* saveForm(action: number, isHistorical?: boolean) {
      try {
        const data: IWI059ERRequest = {
          deviceId: self.deviceId,
          wiRev: self.wiRev.value,
          comment: self.comment.value,
          failureMode: +self.failureMode.value,
          action: action,
          isVisualInspectionPassed: self.visualInspection.isPassed,
          isActiveLeadTestFixturePassed: self.activeLeadTestFixture.isPassed,
          isConnectorCleanupFitPassed: self.connectorCleanupFit.isPassed,
          isLegsGluedProperlyPassed: self.legsGluedProperly.isPassed,
          isDeviceChargingPassed: self.deviceCharging.isPassed,
          isHardResetPassed: self.hardReset.isPassed,
          isTransitionToReadyPassed: self.transitionToReady.isPassed,
          isDeviceInRecordingShowing3xGreenBlinkPassed: self.deviceInRecordingShowing3xGreenBlink.isPassed,
          isCellSignalMoreThen100Passed: self.cellSignalMoreThen100.isPassed,
          isDeviceReleasedAndShowsSolidGreenPassed: self.deviceReleasedAndShowsSolidGreen.isPassed,
          isLeakTestPassed: self.leakTest.isPassed,
          isBackLabelReAppliedPassed: self.backLabelReApplied.isPassed,
          isDeviceCleanedPassed: self.deviceCleaned.isPassed,
          dispositionOfDevice: self.dispositionOfDevice
        };
        if (isHistorical) {
          delete data.action;
        }
        const result =  yield devicesApi.saveWI059ER(data, isHistorical);
        if (isHistorical) {
          fetchData(self.deviceId, "", false, true);
        }
        return result;
      } catch (error) {
        console.error(error);
        return error;
      }
    });


    const isFormChanged = () => {
      if (!self.baseData) {
        return false;
      }
      if ( self.wiRev.value !== self.baseData.wiRev
        || self.comment.value !== self.baseData.comment
        || +self.failureMode.value !== self.baseData.failureMode
        || self.dispositionOfDevice !== self.baseData.dispositionOfDevice
        || self.visualInspection?.isPassed !== self.baseData.visualInspection?.isPassed
        || self.activeLeadTestFixture?.isPassed !== self.baseData.activeLeadTestFixture?.isPassed
        || self.connectorCleanupFit?.isPassed !== self.baseData.connectorCleanupFit?.isPassed
        || self.legsGluedProperly?.isPassed !== self.baseData.legsGluedProperly?.isPassed
        || self.deviceCharging?.isPassed !== self.baseData.deviceCharging?.isPassed
        || self.hardReset?.isPassed !== self.baseData.hardReset?.isPassed
        || self.transitionToReady?.isPassed !== self.baseData.transitionToReady?.isPassed
        || self.deviceInRecordingShowing3xGreenBlink?.isPassed 
          !== self.baseData.deviceInRecordingShowing3xGreenBlink?.isPassed
        || self.cellSignalMoreThen100?.isPassed !== self.baseData.cellSignalMoreThen100?.isPassed
        || self.deviceReleasedAndShowsSolidGreen?.isPassed !== self.baseData.deviceReleasedAndShowsSolidGreen?.isPassed
        || self.leakTest?.isPassed !== self.baseData.leakTest?.isPassed
        || self.backLabelReApplied?.isPassed !== self.baseData.backLabelReApplied?.isPassed
        || self.deviceCleaned?.isPassed !== self.baseData.deviceCleaned?.isPassed
      ) {
        return true;
      } else {
        return false;
      }
    };

    const setIsNextDevice = (value: boolean) => {
      self.isNextDevice = value;
    };

    return {
      fetchData,
      openForm,
      closeForm,
      resetForm,
      validateField,
      saveForm,
      isFormChanged,
      setIsNextDevice,
      setBooleanValue,
      setDispositionOfDevice,
      getIsAllPass,
      getIsAllNotNull
    };
  });


export interface IWI059ERFormModelSnapShot extends SnapshotIn<typeof WI059ERFormModel> {}
