import styled from "styled-components";
import { theme } from "../../theme/theme";

export const ETAdminDevicesListHeaderWrapper = styled.div`
  .edit-btn {
    button {
      background: ${theme.colors.light};
      border: 1px solid ${theme.colors.background};
      color: ${theme.colors.text};
      font-weight: 500;
      font-size: 14px;
      line-height: 16px;
      border-radius: 10px;
      width: 170px;
      padding-top: 11px;
      padding-bottom: 11px;
      padding-left: 12px;
      text-align: center;
      letter-spacing: -0.01em;
      display: flex;
      align-items: center;
  
      .text {
        padding-left: 8px;
      }
    }
  }
`;