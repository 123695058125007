import { observer } from "mobx-react-lite";
import React, { FC, LegacyRef, useEffect, useMemo, useRef, useState } from "react";
import { VQBlueButton, VQDynamicFormComponent, VQIcon, VQLightButton } from "vivaquant-components-library";
import CloseIcon from "../../assets/icons/close.svg";
import { useRootStore } from "../../stores/storeContext";
import { confirmAlert } from "react-confirm-alert";
import { message } from "antd";
import debounce from "lodash.debounce";
import { ETAdminDevicesEditWrapper } from "./et-admin-devices-edit.presents";
import { WIFormService } from "../../services/wi-form.service";
import { FunctionUtils, StringsUtils } from "../../services/utils";
import { WorkInstructionType } from "../../boot/enums/WorkInstructionType";
import { ETFormWI041Main } from "../et-wi-forms/et-form-wi041";
import { WorkInstructionActionType } from "../../boot/enums/WorkInstructionActionType";
import InfoIcon from "../../assets/icons/info2.svg";
import { ETFormWI068Main } from "../et-wi-forms/et-form-wi068/et-form-wi068-main";
import { ETFormWI056Main } from "../et-wi-forms/et-form-wi056/et-form-wi056-main";
import { ETFormWI083Main } from "../et-wi-forms/et-form-wi083/et-form-wi083-main";
import { ETFormWI048Main } from "../et-wi-forms/et-form-wi048/et-form-wi048-main";
import { ETFormWI049Main } from "../et-wi-forms/et-form-wi049/et-form-wi049-main";
import { ETFormWI057Main } from "../et-wi-forms/et-form-wi057/et-form-wi057-main";
import { ETFormWI050Main } from "../et-wi-forms/et-form-wi050/et-form-wi050-main";
import { ETFormWI058Main } from "../et-wi-forms/et-form-wi058/et-form-wi058-main";
import { ETFormWI051Main } from "../et-wi-forms/et-form-wi051/et-form-wi051-main";
import { ETFormWI119Main } from "../et-wi-forms/et-form-wi119";
import { ETFormWI059ERMain } from "../et-wi-forms/et-form-wi059er/et-form-wi059er-main";

export const ETAdminDevicesEdit: FC = observer(() => {
  const {
    id,
    batchNumber,
    routeConfiguration,
    workInstruction,
    closeForm,
    saveForm,
    validateField,
    isFormChanged,
    batchNumbersList,
    getBatchNumbersList,
    resetForm,
    setIsWIFormOpened,
    isWIFormOpened,
    setSelectedWIEditable,
    isSelectedWIEditable,
    baseWorkInstruction,
    selectedWIOldValue,
    setSelectedWIOldValue,
    setLoading,
    isLoading
  } = useRootStore().adminDevicesEditStore;
  const {
    getTotalCount,
  } = useRootStore().adminDevicesListStore;
  const { setGlobalSpiner, setOpenedRightForm } = useRootStore().globalStore;
  const store = useRootStore();

  useEffect(() => {
    
    window.addEventListener("beforeunload", alertUser);
    return () => {
      window.removeEventListener("beforeunload", alertUser);
    };
  }, []);
  const alertUser = (e: any) => {
    const { isWIFormOpened: isWIFormOpenedAction } = store.adminDevicesEditStore;
    const { isFormChanged: isFormChangedWI } = store[getWIName(+workInstruction.value) as keyof typeof store];

    if (isFormChanged(isWIFormOpenedAction ? isFormChangedWI : null)) {
      e.preventDefault();
    }
  };
  
  const [showHelp, setShowHelp] = useState(false);

  const batchNumberInputRef: LegacyRef<any> | undefined = useRef(null);

  const [formInvalid, setFormInvalid] = useState(false);
  const [errors, setErrors] = useState({});
  const [errorsWI, setErrorsWI] = useState({});
  const handleChange = () => {};

  const getSubLabelWI = () => {
    const option = workInstruction?.options?.find((item: any) => item.value === workInstruction.value);
    if (option) {
      return option.subLabel;
    } else {
      return "";
    }
  };

  const getWIName = (value: number) => {
    return StringsUtils.getWIStoreName(WorkInstructionType[value]);
  };

  const handleChangeWorkInstruction = (optionSelected: any) => {
    const setWIOptionValue = (optionSelectedValue: string) => {
      workInstruction.setLoading(true);
      workInstruction.setValue(optionSelectedValue);
      const option = workInstruction.options.find((item: any) => item.value === optionSelectedValue);
      if (option) {
        workInstruction.setDefaultValue({ value: option.value.toString(), label: option.label });
      }
      setTimeout(() => {
        workInstruction.setLoading(false);
      }, 2);
    };

    const action = () => {
      const { 
        resetForm: resetFormWI, 
        fetchData: fetchDataWI
      } = store[getWIName(+optionSelected.value) as keyof typeof store];

      if (!resetFormWI) return;
      
      setSelectedWIOldValue(optionSelected.value);
      setWIOptionValue(optionSelected.value);
      
      resetFormWI();
      setErrorsWI({});
      setGlobalSpiner(true);
      return fetchDataWI(id, "", false, true).then(() => {
        setIsWIFormOpened(true);
        
        const option = baseWorkInstruction?.find((item: any) => item.value === optionSelected.value);
        if (option) {
          setSelectedWIEditable(option.isEditable);
        } else {
          setSelectedWIEditable(false);
        }
        
        setGlobalSpiner(false);
      });
    };

    if ( workInstruction.value === selectedWIOldValue) {
      return setWIOptionValue(selectedWIOldValue);
    }

    setLoading(true);

    const {isFormChanged: isFormChangedWICurent } = selectedWIOldValue 
      ? store[getWIName(+selectedWIOldValue) as keyof typeof store]
      : { isFormChanged: null };


    if (isFormChangedWICurent) {
      const hide = () => {
        action();
        setLoading(false);
      };
      const cancel = () => {
        setWIOptionValue(selectedWIOldValue);
        setLoading(false);
      };
      WIFormService.onClose(isFormChangedWICurent, confirmAlert, hide, cancel);
    } else {
      action();
      setLoading(false);
    }
  };

  useEffect(() => {
    handleFocusIn();
  }, [JSON.stringify(batchNumbersList)]);

  useEffect(() => {
    document.addEventListener("focusin", handleFocusIn);
    document.addEventListener("focusout", handleFocusIn);
    return () => {
      document.removeEventListener("focusin", handleFocusIn);
      document.addEventListener("focusout", handleFocusIn);
    };
  }, []);

  const handleFocusIn = () => {
    if (batchNumberInputRef.current) {
      const el = batchNumberInputRef.current.getElementsByTagName("input");
      if (batchNumbersList.length && el && document.activeElement === el[0]) {
        return setShowHelp(true);
      } else {
        return setShowHelp(false);
      }
    }
    return setShowHelp(false);
  };

  const handleChangeBatchNumber = () => {
    refreshDataChangeBatchNumber();
  };

  const refreshDataChangeBatchNumber = useMemo(
    () => debounce(() => {
      getBatchNumbersList();
    }, 200), []);

  const hide = () => {
    getTotalCount();
    setFormInvalid(false);
    setErrors({});
    setErrorsWI({});
    setOpenedRightForm(false);
    closeForm();
    
    resetForm();
    const { resetForm: resetFormWI } = store[getWIName(+workInstruction.value) as keyof typeof store];
    if (resetFormWI) {
      resetFormWI();
    }
  };

  const onClose = () => {
    const { isFormChanged: isFormChangedWI } = store[getWIName(+workInstruction.value) as keyof typeof store];
    WIFormService.onClose(() => { return isFormChanged(isWIFormOpened ? isFormChangedWI : null); }, confirmAlert, hide);
  };

  const validateForm = async() => {
    const { validateField: validateFieldWI } = store[getWIName(+workInstruction.value) as keyof typeof store];

    const { errors, isValid } = validateField();
    console.log(validateFieldWI(WorkInstructionActionType.None));
    const { errors: errorsWI, isValid: isValidWI } = await validateFieldWI(WorkInstructionActionType.None);
    setErrors(errors);
    setErrorsWI(errorsWI);
    return isWIFormOpened ? isValid && isValidWI : isValid;
  };

  const onSubmit: React.FormEventHandler = (event) => {
    event.preventDefault();

    const saveFormFunction = () => {
      setGlobalSpiner(true);
      
      const tasks = [];
      if (isFormChanged()) {
        tasks.push(saveForm());
      }

      const {
        saveForm: saveFormWI,
        isFormChanged: isFormChangedWI
      } = store[getWIName(+workInstruction.value) as keyof typeof store];

      if (isWIFormOpened && isSelectedWIEditable && isFormChangedWI()) {
        tasks.push(saveFormWI(0, true));
      }

      Promise.all(tasks)
        .then((res: any) => {
          setGlobalSpiner(false);
          if (!res.length) {
            const key = "updatable";
            return message.info({ content: "No data for save!", key, duration: 3 });
          }
          if (res[0].isOk) {
            confirmAlert({
              message: "Your form has been saved successfully!",
              overlayClassName: "sl-success-modal",
              buttons: [
                {
                  label: "Ok",
                  onClick: () => {}
                }
              ]
            });
          } else {
            const key = "updatable";
            message.error({
              content: res.error?.detail || res.error?.title || "Error, Something went wrong!",
              key,
              duration: 3
            });
          }
        })
        .catch((error: any) => {
          setGlobalSpiner(false);
          const key = "updatable";
          message.error({
            content: error?.detail || error?.title || "Error, Something went wrong!",
            key,
            duration: 3
          });
        });
    };

    if (FunctionUtils.returnsPromise(validateForm)) {
      validateForm()
        .then((result: boolean) => {
          if (!result) {
            setFormInvalid(true);
          } else {
            setFormInvalid(false);
            return saveFormFunction();
          }
        });
    } else {
      if (!validateForm()) {
        setFormInvalid(true);
      } else {
        setFormInvalid(false);
        return saveFormFunction();
      }
    }
  };

  const getWIform = () => {
    let form = <></>;
    
    switch (+workInstruction.value) {
      case WorkInstructionType.WI041:
        form = <ETFormWI041Main 
          errors={errorsWI} formInvalid={formInvalid} isDisabled={!isSelectedWIEditable}></ETFormWI041Main>;
        break;

      case WorkInstructionType.WI068:
        form = <ETFormWI068Main 
          errors={errorsWI} formInvalid={formInvalid} isDisabled={!isSelectedWIEditable}></ETFormWI068Main>;
        break;

      case WorkInstructionType.WI056:
        form = <ETFormWI056Main 
          errors={errorsWI} formInvalid={formInvalid} isDisabled={!isSelectedWIEditable}></ETFormWI056Main>;
        break;

      case WorkInstructionType.WI083:
        form = <ETFormWI083Main 
          errors={errorsWI} formInvalid={formInvalid} isDisabled={!isSelectedWIEditable}></ETFormWI083Main>;
        break;

      case WorkInstructionType.WI048:
        form = <ETFormWI048Main 
          errors={errorsWI} formInvalid={formInvalid} isDisabled={!isSelectedWIEditable}></ETFormWI048Main>;
        break;

      case WorkInstructionType.WI049:
        form = <ETFormWI049Main 
          errors={errorsWI} formInvalid={formInvalid} isDisabled={!isSelectedWIEditable}></ETFormWI049Main>;
        break;

      case WorkInstructionType.WI057:
        form = <ETFormWI057Main 
          errors={errorsWI} formInvalid={formInvalid} isDisabled={!isSelectedWIEditable}></ETFormWI057Main>;
        break;
      
      case WorkInstructionType.WI050:
        form = <ETFormWI050Main 
          errors={errorsWI} formInvalid={formInvalid} isDisabled={!isSelectedWIEditable}></ETFormWI050Main>;
        break;

      case WorkInstructionType.WI058:
        form = <ETFormWI058Main 
          errors={errorsWI} formInvalid={formInvalid} isDisabled={!isSelectedWIEditable}></ETFormWI058Main>;
        break;

      case WorkInstructionType.WI051:
        form = <ETFormWI051Main 
          errors={errorsWI} formInvalid={formInvalid} isDisabled={!isSelectedWIEditable}></ETFormWI051Main>;
        break;

      case WorkInstructionType.WI119:
        form = <ETFormWI119Main 
          errors={errorsWI} formInvalid={formInvalid} isDisabled={!isSelectedWIEditable}></ETFormWI119Main>;
        break;

      case WorkInstructionType.WIER:
        form = <ETFormWI059ERMain 
          errors={errorsWI} 
          formInvalid={formInvalid} 
          isDisabled={!isSelectedWIEditable} 
          isDisabledLastEditor={true}></ETFormWI059ERMain>;
        break;
    
      default:
        break;
    }

    return form;
  };

  return (
    <ETAdminDevicesEditWrapper>
      <div className="close" onClick={onClose}>
        <VQIcon icon={CloseIcon}></VQIcon>
      </div>
      <form onSubmit={onSubmit}>
        <div className="fields-container">
          <div className="col-container">
            <div className="col-container-el help-container" ref={batchNumberInputRef}>
              <VQDynamicFormComponent
                item={batchNumber}
                errors={errors}
                handleChange={handleChangeBatchNumber}
                showError={true}
                isFormInvalid={formInvalid}></VQDynamicFormComponent>
              {showHelp && <div className="help">
                {batchNumbersList.map((name: string, index: number) => (
                  <div key={index} className={"help-item"}>{name}</div>
                ))}
              </div>}
            </div>
            
            <div className="col-container-el">
              <VQDynamicFormComponent
                item={routeConfiguration}
                errors={errors}
                handleChange={handleChange}
                showError={true}
                disabled={true}
                isFormInvalid={formInvalid}></VQDynamicFormComponent>
            </div>
          </div>
          <div className="col-container">
            <div className="col-container-el">
              {!workInstruction.isLoading && <VQDynamicFormComponent
                item={workInstruction}
                errors={errors}
                handleChange={handleChangeWorkInstruction}
                showError={true}
                isFormInvalid={formInvalid}></VQDynamicFormComponent>}
            </div>
            <div className="col-container-el"></div>
          </div>
        </div>
        
        {(isWIFormOpened && !isLoading) && <>
          <div className="form-name">
            <div>
              {StringsUtils.addWIDashes(WorkInstructionType[+workInstruction.value])}
            </div>
            {getSubLabelWI() && <div className="form-info">
              {getSubLabelWI()}
              <VQIcon icon={InfoIcon} width="20px" height="20px"></VQIcon>
            </div>}
          </div>
          <div className="fields-container">
            {getWIform()}
          </div>
        </>}

        <div className="btn-container">
          <div className="btn-container">
            <VQLightButton type="button" text="Cancel" click={hide}/>
            <VQBlueButton type="submit" text="Save" click={() => {}}/>
          </div>
        </div>
      </form>
    </ETAdminDevicesEditWrapper>
  );
});