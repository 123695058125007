import { types } from "mobx-state-tree";
import { ValidationUtils } from "../../services/utils";

export const LoginFormModel = types
  .model("LoginFormModel", {
    email: types.optional(types.string, ""),
    password: types.optional(types.string, ""),
    invalidEmail: types.optional(types.boolean, false),
    invalidPassword: types.optional(types.boolean, false),
    isSubmit: types.optional(types.boolean, false),
    isShowPassword: types.optional(types.boolean, false),
    isErrorLogin: types.optional(types.boolean, false),
    tokenReCaptcha: types.optional(types.string, ""),
    isLoading: types.optional(types.boolean, false)
  })
  .actions(self => {
    const validateEmail = () => {
      self.invalidEmail = !ValidationUtils.isLoginValueValid(self.email);
    };

    const validatePassword = () => {
      self.invalidPassword = !ValidationUtils.isPasswordValid(self.password);
    };

    const setEmail = (value: string) => {
      self.email = value;
      setErrorLogin(false);
      validateEmail();
    };

    const setPassword = (value: string) => {
      self.password = value;
      setErrorLogin(false);
      validatePassword();
    };

    const setShowPassword = () => {
      self.isShowPassword = !self.isShowPassword;
    };

    const setSubmit = () => {
      self.isSubmit = true;
      validateEmail();
      validatePassword();
    };

    const setErrorLogin = (value: boolean) => {
      self.isErrorLogin = value;
    };

    const setTokenReCaptcha = (value: string) => {
      if (!self.tokenReCaptcha) {
        self.tokenReCaptcha = value;
      }
    };

    const setLoading = (isLoading: boolean) => {
      self.isLoading = isLoading;
    };

    const cleanData = () => {
      self.email = "";
      self.password = "";
      self.isSubmit = false;
    };

    return {
      setEmail,
      setPassword,
      validateEmail,
      validatePassword,
      setSubmit,
      setShowPassword,
      setErrorLogin,
      setTokenReCaptcha,
      setLoading,
      cleanData
    };
  });
