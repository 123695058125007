import { observer } from "mobx-react-lite";
import { FC, useRef, useState } from "react";
import { Modal } from "react-bootstrap";
import { useRootStore } from "../../stores/storeContext";
import { ETReportModalWrapper } from "./et-report-modal.presents";
import { Document, Page, pdfjs } from "react-pdf";
import { VQButtonExport, VQIcon } from "vivaquant-components-library";
import CloseIcon from "../../assets/icons/close.svg";

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

export const ETReportModal: FC = observer(() => {
  const {
    show,
    closeModal,
    urlPDF,
    downloadReport
  } = useRootStore().reportStore;

  const [pagesCount, setPages] = useState(0);
  const pdfRef = useRef<HTMLDivElement>(null);

  const getPages = () => {
    const pages = [];
    for (let i = 1; i <= pagesCount; i++) {
      pages.push(<div>
        <Page pageNumber={i} width={1067} renderTextLayer={false} renderAnnotationLayer={false}/>
      </div>);
    }

    return pages;
  };
  
  return (
    <Modal
      onHide={() => { closeModal(); }}
      show={show}
      centered
      dialogClassName="report-modal">
      <Modal.Body>
        <ETReportModalWrapper>
          <div className="top-section">
            <div>
              <VQButtonExport 
                text={"Download Report"}
                click={() => { downloadReport(); }}
              ></VQButtonExport>
            </div>
            <div className="close" onClick={() => { closeModal(); }}>
              <VQIcon icon={CloseIcon}></VQIcon>
            </div>
          </div>
          {urlPDF && <div className="report" ref={pdfRef}>
            <Document
              file={urlPDF}
              onLoadSuccess={(pdf) => {
                setPages(pdf.numPages);
              }}>
              {pagesCount > 0 && getPages()}
            </Document>
          </div>}
        </ETReportModalWrapper>
      </Modal.Body>
    </Modal>
  );
});
