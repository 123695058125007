import { DateUtils } from "./date.utils";

export class StringsUtils {

  public static addWIDashes(name?: string) : string {
    if (name === "WIER") {
      return "WI-059-ER";
    }
    return name.slice(0, 2) + "-" + name.slice(2);
  }

  public static getWIStoreName(name?: string) : string {
    if (name === "WIER") {
      return "wi059ERFormStore";
    }
    return name.toLowerCase() + "FormStore";
  }

  public static addNCMWIDashes(name?: string) : string {
    if (name === "NcmWIER") {
      return "WI-059";
    }
    if (name === "Released") {
      return "Released";
    }
    return name.replace("Ncm", "").slice(0, 2) + "-" + name.replace("Ncm", "").slice(2);
  }

  public static addNCMWIFullDashes(name?: string) : string {
    if (name === "NcmWIER") {
      return "NCM WI-059";
    }
    if (name === "Released") {
      return "Released";
    }
    return "NCM " + name.replace("Ncm", "").slice(0, 2) + "-" + name.replace("Ncm", "").slice(2);
  }

  public static hideEmail(email : string) : string {
    let hide = email.split("@")[0].length - 2;
    const r = new RegExp(".{"+hide+"}@", "g");
    return email.replace(r, "***@" );
  }

  public static reportName(serialNumber: string, date: string): string {
    return `${serialNumber}_Traveler_${date ? DateUtils.formatYYY_MM_DD(date) : ""}.pdf`;
  }
}
