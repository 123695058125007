import { Instance, SnapshotIn, flow, types } from "mobx-state-tree";
import { InProgressListCategories, InProgressListSort, PAGINATION, SortDirection } from "../boot/constants";
import { IObservableArray } from "mobx";
import { InProgressListFilter } from "../services/filters/in-progress-list.filter";
import { TApiResponse } from "../types";
import { IInProgressDevicesCountResponse } from "../models/IInProgressDevicesCountResponse";
import { devicesApi } from "../services/api";
import { WorkInstructionType } from "../boot/enums/WorkInstructionType";
import { ExportUtils } from "../services/utils";
import { DeviceStatus } from "../boot/enums/DeviceStatus";

const getCategoriesTotals = () => {
  const obj: any = {
    AllCategoryTotal: types.optional(types.number, 0),
  };
  Object.keys(WorkInstructionType)
    .filter((v) => isNaN(Number(v)))
    .forEach((key: string) => {
      const keyName = `${key}CategoryTotal`;
      obj[keyName] = types.optional(types.number, 0);
    });

  return obj;
} ;

const categoriesTotals = getCategoriesTotals();

export const RouteConfigurationModel = types
  .model("RouteConfigurationModel", {
    id: types.maybe(types.string),
    name: types.maybeNull(types.string)
  });

export const ReportInfoModel = types
  .model("ReportInfoModel", {
    id: types.maybe(types.string),
    updatedAt: types.maybeNull(types.string)
  });

const InProgressModel = types
  .model("InProgressModel", {
    id: types.maybe(types.string),
    batchId: types.maybeNull(types.string),
    routeConfiguration: types.maybeNull(RouteConfigurationModel),
    movedToStageAt: types.maybeNull(types.string),
    serialNumber: types.maybeNull(types.string),
    stage: types.maybeNull(types.number),
    imei: types.maybeNull(types.string),
    iccid: types.maybeNull(types.string),
    boardReversion: types.maybeNull(types.string),
    report: types.maybeNull(ReportInfoModel)
  });

export interface IInProgressModel extends Instance<typeof InProgressModel> {}
export interface IInProgressModelSnapShot extends SnapshotIn<typeof InProgressModel> {}

export const InProgressListModel = types
  .model("InProgressListModel", {
    activeCategory: types.optional(types.string, InProgressListCategories[0].value),
    page: 0,
    total: 0,
    searchValue: types.maybe(types.string),
    sortBy: types.optional(types.string, InProgressListSort.batchId),
    sortDirection: types.optional(types.number, SortDirection.DESC),
    isLoading: types.optional(types.boolean, true),
    items: types.optional(types.array(InProgressModel), []),
    itemBatchIdNextPage: types.maybeNull(types.string),
    categoriesTotals: types.optional(types.model({...categoriesTotals}), {})
  })
  .actions(self => ({
    setLoading(isLoading: boolean) {
      self.isLoading = isLoading;
    },
    setList(dto: IInProgressModelSnapShot[]) {
      self.items = dto as IObservableArray;
    }
  }))
  .actions(self => {
    const setActiveCategory = (value: string, count: number) => {
      self.activeCategory = value;
      self.page = 0;
      self.total = count;
      getList();
    };

    const setFirstPage = () => {
      self.page = 0;
    };

    const setDefaultParams = () => {
      self.activeCategory = InProgressListCategories[0].value;
      self.page = 0;
      self.searchValue = "";
      self.sortBy = InProgressListSort.batchId;
      self.sortDirection = SortDirection.DESC;
    };

    const nextPage = () => {
      self.page++;
      return getList();
    };

    const previousPage = () => {
      self.page--;
      getList();
    };

    const setSearch = (searachValue? : string) => {
      self.searchValue = searachValue;
    };

    const setSortBy = (sortBy: string, sortDirection: number) => {
      self.sortBy = sortBy;
      self.sortDirection = sortDirection;
      getList();
    };

    const getList = flow(function* getList() {
      self.setLoading(true);

      try {
        const rows = self.total && ((self.page * PAGINATION.ROWS_PER_PAGE + PAGINATION.ROWS_PER_PAGE) > self.total)
          ? self.total - (self.page * PAGINATION.ROWS_PER_PAGE)
          : PAGINATION.ROWS_PER_PAGE + 1;

        const filter = new InProgressListFilter();
        filter.status = DeviceStatus.InProgress;
        filter.skip = self.page * PAGINATION.ROWS_PER_PAGE;
        filter.take = rows;
        filter.search = self.searchValue || "";
        filter.sortBy = self.sortBy;
        filter.sortDirection = self.sortDirection;

        if (self.activeCategory) {
          filter.stage = self.activeCategory;
        }

        const result: TApiResponse<IInProgressModelSnapShot[]>
          = yield devicesApi.getInProgressList(filter);
        if (result.isOk) {
          if (result.data.length === PAGINATION.ROWS_PER_PAGE + 1) {
            self.itemBatchIdNextPage = result.data[PAGINATION.ROWS_PER_PAGE].batchId;
            result.data.pop();
            self.setList(result.data);
          } else {
            self.setList(result.data);
          }
          
          
          self.setLoading(false);
          return self.items;
        } else {
          self.setList([]);
          self.setLoading(false);
          return self.items;
        }
      } catch (error) {
        console.error(error);
        self.setList([]);
        self.setLoading(false);
      }
    });

    const downloadExport = flow(function* downloadExport() {
      const filter = new InProgressListFilter();
      filter.status = DeviceStatus.InProgress;
      filter.search = self.searchValue || "";
      filter.sortBy = self.sortBy;
      filter.sortDirection = self.sortDirection;

      if (self.activeCategory) {
        filter.stage = self.activeCategory;
      }

      try {
        const exportResult = yield devicesApi.getExport(filter);
        if (!exportResult.isOk) {
          return;
        }

        ExportUtils.downloadCSV(exportResult.data.data,
          `In_progress_${self.activeCategory 
            ? +self.activeCategory === WorkInstructionType.WIER 
              ? "WI-059-ER" 
              :  WorkInstructionType[+self.activeCategory] 
            : "All"}.csv`);
      } catch (error) {
        console.error(error);
      }
    });

    const getTotalCount = flow(function* getTotalCount() {
      try {
        const filter = new InProgressListFilter();
        filter.status = DeviceStatus.InProgress;
        filter.search = self.searchValue || "";

        const result: TApiResponse<IInProgressDevicesCountResponse> = yield devicesApi.getCounters(filter);
        if (result.isOk) {

          self.categoriesTotals.AllCategoryTotal = result.data.all;
          const keysWorkInstructionType = Object.keys(WorkInstructionType)
            .filter((v) => isNaN(Number(v)));
          
          keysWorkInstructionType
            .forEach((key: string) => {
              const keyName = `${key}CategoryTotal`;
              self.categoriesTotals[keyName] 
                = Object.prototype.hasOwnProperty.call(result.data, key.charAt(0).toLocaleLowerCase() + key.slice(1)) 
                  ? result.data[key.charAt(0).toLocaleLowerCase()
                    + key.slice(1) as keyof IInProgressDevicesCountResponse]
                  : 0;
            });

          self.categoriesTotals.WIERCategoryTotal = result.data.wier;

          InProgressListCategories.forEach((category: any) => {
            if(self.activeCategory === category.value) {
              const keyName = `${category.state}CategoryTotal`;
              self.total = self.categoriesTotals[keyName];
            }
          }); 

          return getList();
        }
      } catch (error) {
        console.error(error);
      }
    });


    return {
      nextPage,
      previousPage,
      getList,
      setActiveCategory,
      getTotalCount,
      setSearch,
      setSortBy,
      setFirstPage,
      setDefaultParams,
      downloadExport
    };
  });
