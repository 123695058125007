import { IAdminRouteConfigurationAddModelSnapShot } from "../stores/admin-route-configuration-add.store";

export const AdminRouteConfigurationAddState: IAdminRouteConfigurationAddModelSnapShot = {
  name: {
    name: "Title",
    value: "",
    type: "input",
    fieldId: "name"
  },
};
