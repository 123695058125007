import { observer } from "mobx-react-lite";
import { FC, useEffect, useRef, useState } from "react";
import { useRootStore } from "../../../stores/storeContext";
import { WorkInstructionActionType } from "../../../boot/enums/WorkInstructionActionType";
import { message } from "antd";
import { confirmAlert } from "react-confirm-alert";
import CloseIcon from "../../../assets/icons/close.svg";
import {
  VQBlueButton, 
  VQCheckbox, 
  VQGreenButton, 
  VQIcon, 
  VQRadio, 
  VQRedButton 
} from "vivaquant-components-library";
import { WI059ERFailureMode } from "../../../boot/enums/WI059ERFailureMode";
import { Permissions } from "../../../services/permission.service";
import _uniqueId from "lodash.uniqueid";
import { ETFormWI059ERWrapper } from "./et-form-wi059er.presents";
import { WI059ERDispositionOfDevice } from "../../../boot/enums/WI059ERDispositionOfDevice";
import { WIFormService } from "../../../services/wi-form.service";
import { ETFormWI059ERMain } from "./et-form-wi059er-main";

export const ETFormWI059ER: FC = observer(() => {
  const {
    failureMode,
    comment,
    validateField,
    saveForm,
    closeForm,
    isFormChanged,
    deviceId,
    isNextDevice,
    setIsNextDevice,
    fetchData,
    setDispositionOfDevice,
    dispositionOfDevice,
    getIsAllPass,
    getIsAllNotNull,

    visualInspection,
    activeLeadTestFixture,
    connectorCleanupFit,
    legsGluedProperly,
    deviceCharging,
    hardReset,
    transitionToReady,
    deviceInRecordingShowing3xGreenBlink,
    cellSignalMoreThen100,
    deviceReleasedAndShowsSolidGreen,
    leakTest,
    backLabelReApplied,
    deviceCleaned,
  } = useRootStore().wi059ERFormStore;
  const { 
    getTotalCount, 
    items, page, 
    nextPage, 
    total, 
    activeCategory, 
    itemBatchIdNextPage 
  } = useRootStore().inProgressListStore;
  const { setGlobalSpiner, setOpenedRightForm } = useRootStore().globalStore;
  const { hasPermission } = useRootStore().userStore;

  useEffect(() => {
    window.addEventListener("beforeunload", alertUser);
    return () => {
      window.removeEventListener("beforeunload", alertUser);
    };
  }, []);
  const alertUser = (e: any) => {
    if (isFormChanged()) {
      e.preventDefault();
    }
  };

  const formRef = useRef(null);
  useEffect(() => {
    if (formRef && formRef.current) {
      const el = document.getElementsByName("wiRev");
      if (el && el[0]) {
        el[0].focus();
      }
    }
  }, [deviceId]);

  const isAdmin = hasPermission(Permissions.DeviceWI59ERPassFail);
  const isEditDispositionOfDevice = hasPermission(Permissions.DeviceWI59EREditDispositionOfDevice);

  const [formInvalid, setFormInvalid] = useState(false);
  const [errors, setErrors] = useState({});

  const validateForm = (action: number) => {
    const { errors, isValid } = validateField(action);
    setErrors(errors);
    return isValid;
  };

  const hide = () => {
    getTotalCount();
    setFormInvalid(false);
    setErrors({});
    setOpenedRightForm(false);
    closeForm();
  };

  const openNextDevice = (action: number) => {
    WIFormService.openNextDevice(
      items,
      deviceId, 
      action, 
      page, 
      total, 
      setFormInvalid, 
      setErrors, 
      setGlobalSpiner, 
      fetchData,
      nextPage,
      hide,
      getTotalCount,
      itemBatchIdNextPage
    );
  };

  const onSubmit = (action: number) => {
    WIFormService.onSubmit(
      action, 
      activeCategory ? isNextDevice : false,
      message,
      validateForm, 
      setFormInvalid, 
      saveForm, 
      setGlobalSpiner, 
      openNextDevice, 
      hide
    );
  };

  const onClose = () => {
    WIFormService.onClose(isFormChanged, confirmAlert, hide);
  };

  const isPassDisabled = () => {
    if (!isAdmin) {
      return true;
    } else if (failureMode.value !== WI059ERFailureMode.None.toString()) {
      return true;
    } else if (!dispositionOfDevice) {
      return true;
    } else if (dispositionOfDevice === WI059ERDispositionOfDevice.FinishedGoodsInventory && !getIsAllPass()) {
      return true;
    } else if (dispositionOfDevice === WI059ERDispositionOfDevice.FunctionalDemo) {
      if (getIsAllNotNull()) {
        return false;
      } else {
        return true;
      }
    } else if (dispositionOfDevice === WI059ERDispositionOfDevice.NonconformingMaterial) {
      return true;
    } else if (dispositionOfDevice === WI059ERDispositionOfDevice.Engineering) {
      return false;
    } else {
      return false;
    }
  };

  const isFailDisabled = () => {
    if (!isAdmin) {
      return true;
    } else if (dispositionOfDevice === WI059ERDispositionOfDevice.FinishedGoodsInventory) {
      return true;
    } else if (dispositionOfDevice === WI059ERDispositionOfDevice.FunctionalDemo) {
      return true;
    } else if (dispositionOfDevice === WI059ERDispositionOfDevice.Engineering) {
      return true;
    } else if (failureMode.value === WI059ERFailureMode.None.toString()) {
      return true;
    } else if (!comment.value) {
      return true;
    }  else {
      return false;
    }
  };

  let isPassDisabledValue = isPassDisabled();
  let isFailDisabledValue = isFailDisabled();

  useEffect(() => {
    isPassDisabledValue = isPassDisabled();
    isFailDisabledValue = isFailDisabled();
  }, [
    visualInspection.isPassed,
    activeLeadTestFixture.isPassed,
    connectorCleanupFit.isPassed,
    legsGluedProperly.isPassed,
    deviceCharging.isPassed,
    hardReset.isPassed,
    transitionToReady.isPassed,
    deviceInRecordingShowing3xGreenBlink.isPassed,
    cellSignalMoreThen100.isPassed,
    deviceReleasedAndShowsSolidGreen.isPassed,
    leakTest.isPassed,
    backLabelReApplied.isPassed,
    deviceCleaned.isPassed,
  ]);

  return (
    <ETFormWI059ERWrapper>
      <div className="close" onClick={onClose}>
        <VQIcon icon={CloseIcon}></VQIcon>
      </div>
      <form onSubmit={() => {}} ref={formRef}>
        <div className="fields-container">
          {activeCategory && <VQCheckbox
            handleChange={() => { setIsNextDevice(!isNextDevice); }}
            isChecked={isNextDevice}
            label="Open next device"
            name="isNextDevice"
          ></VQCheckbox>}

          <ETFormWI059ERMain errors={errors} formInvalid={formInvalid}>
            <div>
              <div className="col-container">
                <div className="col-container-el label-input">Disposition of Device</div>
              </div>
              <div className={`col-container ${!isEditDispositionOfDevice ? "disabled" : ""}`}>
                <div className="col-container-el">
                  <VQRadio 
                    handleChange={() => { setDispositionOfDevice(WI059ERDispositionOfDevice.FinishedGoodsInventory); }}
                    isChecked={dispositionOfDevice === WI059ERDispositionOfDevice.FinishedGoodsInventory}
                    label="Finished Goods Inventory"
                    name="dispositionOfDevice"
                    disabled={!isEditDispositionOfDevice}
                    id={_uniqueId()}
                  ></VQRadio>
                </div>
                <div className="col-container-el" style={{flex: "1.5"}}>
                  <VQRadio 
                    handleChange={() => { setDispositionOfDevice(WI059ERDispositionOfDevice.FunctionalDemo); }}
                    isChecked={dispositionOfDevice === WI059ERDispositionOfDevice.FunctionalDemo}
                    label="Functional Demo"
                    name="dispositionOfDevice"
                    disabled={!isEditDispositionOfDevice}
                    id={_uniqueId()}
                  ></VQRadio>
                </div>
              </div>
              <div className={`col-container ${!isEditDispositionOfDevice ? "disabled" : ""}`}>
                <div className="col-container-el">
                  <VQRadio 
                    handleChange={() => { setDispositionOfDevice(WI059ERDispositionOfDevice.NonconformingMaterial); }}
                    isChecked={dispositionOfDevice === WI059ERDispositionOfDevice.NonconformingMaterial}
                    label="Nonconforming Material"
                    name="dispositionOfDevice"
                    disabled={!isEditDispositionOfDevice}
                    id={_uniqueId()}
                  ></VQRadio>
                </div>
                <div className="col-container-el" style={{flex: "1.5"}}>
                  <VQRadio 
                    handleChange={() => { setDispositionOfDevice(WI059ERDispositionOfDevice.Engineering); }}
                    isChecked={dispositionOfDevice === WI059ERDispositionOfDevice.Engineering}
                    label="Engineering"
                    name="dispositionOfDevice"
                    disabled={!isEditDispositionOfDevice}
                    id={_uniqueId()}
                  ></VQRadio>
                </div>
              </div>
            </div>
          </ETFormWI059ERMain>

        </div>
        <div className="btn-container">
          <VQRedButton
            click={() => {onSubmit(WorkInstructionActionType.Fail);}}
            isDisabled={isFailDisabledValue}
            text="Fail"
            type="button"
            width="184px"></VQRedButton>
          <VQBlueButton
            click={() => {onSubmit(WorkInstructionActionType.None);}}
            text="Save"
            type="button"
            width="184px"></VQBlueButton>
          <VQGreenButton
            click={() => {onSubmit(WorkInstructionActionType.Pass);}}
            isDisabled={isPassDisabledValue}
            text="Pass"
            type="button"
            width="184px"></VQGreenButton>
        </div>
      </form>
    </ETFormWI059ERWrapper>
  );
});