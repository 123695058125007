
export class FunctionUtils {

  public static isPromise(p : any) {
    if (typeof p === "object" && typeof p.then === "function") {
      return true;
    }
  
    return false;
  }
  

  public static returnsPromise(f : Function) {
    if (
      f.constructor.name === "AsyncFunction" || (typeof f === "function" && this.isPromise(f()))
    ) {
      return true;
    }
  
    return false;
  }

}
