import { observer } from "mobx-react-lite";
import * as React from "react";
import { FC } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { LoginPage } from "../pages/Authorization/Login";
import { useRootStore } from "../stores/storeContext";
import { ForgotPasswordPage } from "../pages/Authorization/ForgotPassword";
import { RecoveryPasswordPage } from "../pages/Authorization/RecoveryPassword";
import { InvitationPage } from "../pages/Authorization/Invitation";
import { VQSpinner } from "vivaquant-components-library";
import { ETLayout } from "../components/et-layout";
import { ProfilePage } from "../pages/Profile";
import { ETAdmin } from "../components/et-admin";
import { AdminDevicesPage, AdminUsersPage } from "../pages/Admin";
import { InProgresPage } from "../pages/InProgress";
import { AdminRouteConfigurationPage } from "../pages/Admin/RouteConfiguration";
import { Permissions } from "../services/permission.service";
import { ReleasedPage } from "../pages/Released";
import { NCMPage } from "../pages/NCM";


export const GlobalRoutes: FC = observer(() => {
  const { isAuth, profile, hasPermission } = useRootStore().userStore;
  const { showGlobalSpiner } = useRootStore().globalStore;
  useRootStore().userStore.checkAuthorized();

  if (typeof useRootStore().userStore.isAuth === "undefined") {
    return (<></>);
  } else {
    if (isAuth && profile?.permissions) {
      const isShowAdminPage = hasPermission(Permissions.UserInvite);
      return (
        <>
          {showGlobalSpiner && <div className="global-spiner">
            <VQSpinner></VQSpinner>
          </div>}
          <Routes>
            <Route path="/" element={<ETLayout/>}>
              <Route path="profile" element={<ProfilePage/>}/>
              <Route path="in-progress" element={<InProgresPage></InProgresPage>}/>
              <Route path="released" element={<ReleasedPage></ReleasedPage>}/>
              <Route path="ncm" element={<NCMPage></NCMPage>}/>
              
              {isShowAdminPage && <Route path="/admin" element={<ETAdmin></ETAdmin>}>
                <Route path="users" element={<AdminUsersPage></AdminUsersPage>}/>
                <Route path="devices" element={<AdminDevicesPage></AdminDevicesPage>} />
                <Route path="route-configuration" 
                  element={<AdminRouteConfigurationPage></AdminRouteConfigurationPage>}/>
                <Route index element={<Navigate to="users" replace={true}/>}/>
              </Route>}
              
              <Route path="*" element={<Navigate to={"in-progress"} replace={true}/>} />
              <Route path="/" element={<Navigate to={"in-progress"} replace={true}/>} />
            </Route>
          </Routes>
        </>
      );
    }

    if (!isAuth) {
      return (
        <Routes>
          <Route path="/login" element={<LoginPage/>} />
          <Route path="/forgot-password" element={<ForgotPasswordPage/>}/>
          <Route path={"/recovery-password/:token"} element={<RecoveryPasswordPage/>} />
          <Route path={"/invitation/:token"} element={<InvitationPage/>}/>
          <Route path="*" element={<Navigate to={"/login"}/>} />
        </Routes>
      );
    }
  }
});
