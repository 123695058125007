import styled from "styled-components";
import { theme } from "../../theme/theme";

export const ETRightFormWrapper = styled.div`
  padding: 24px;
  background-color: ${theme.colors.light};
  width: 630px;
  position: absolute;
  right: 0;
  top: ${theme.size.header};
  bottom: 0;

  .info {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    padding: 24px;
    background-color: #F6F6F6;
    color: ${theme.colors.text};
    border-radius: 8px;
    display: flex;
    gap: 8px;
    align-items: center;
  }

  form {
    overflow-x: auto;
    padding-bottom: 96px;
    height: calc(100% - 96px);
    margin-left: -10px;
    margin-right: -10px;
    padding-left: 10px;
    padding-right: 10px;
  }

  .title {
    font-size: 32px;
    font-style: normal;
    font-weight: 500;
    line-height: 36px;
    color: ${theme.colors.text};
    padding-bottom: 40px;
    
    .sub-title {
      padding-top: 8px;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 18px;
    }
  }

  

  .label {
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    margin-top: 14px;
    margin-bottom: 24px;
  }

  .label-input {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    color: ${theme.colors.label};
    margin-bottom: 24px;
    margin-top: 10px;
  }

  .invalid-text-error {
    display: block;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    color: ${theme.colors.red};
    margin-top: 4px;
  }

  .ad-wi {
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: -0.14px;
    color: ${theme.colors.blue};
    cursor: pointer;
  }

  .wi-container {
    display: flex;
    gap: 16px;
    align-items: flex-start;
    background-color: ${theme.colors.light};
    
    .wi-container-el {
      cursor: pointer;
      padding-left: 2px;
    }

    .wi-container-el:first-child {
      flex: 1;
    }
  }

  .disabled {
    pointer-events: none;
    cursor: default;

    img {
      opacity: 0.4;
    }
  }

  .close {
    position: absolute;
    right: 24px;
    top: 24px;
    cursor: pointer;
  }

  .col-container {
    display: flex;
    gap: 24px;
    
    .col-container-el {
      flex: 1;

      .vq-select__control--is-focused {
        border-color: #2684FF !important;
        box-shadow: none;
      }
      .vq-select__control--menu-is-open {
        border-bottom-color: transparent !important;
      }
    }
  }

  .col-container-3 {
    column-count: 3;
  }

  .flex-inherit {
    flex: inherit !important;

    label {
      line-height: 20px;
    }
  }

  .btn-container {
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
    padding: 24px;
    display: flex;
    justify-content: flex-end;
    gap: 16px;
    background-color: ${theme.colors.light};
    box-shadow: 0px -8px 16px 0px rgba(0, 0, 0, 0.04); 
  }
`;