import { cast, flow, types } from "mobx-state-tree";
import { API } from "../boot/api";

export const AdminDevicesAddModel = types
  .model("AdminDevicesAddModel", {
    show: types.optional(types.boolean, false),
    isLoading: types.optional(types.boolean, false),
    isUploaded: types.optional(types.boolean, false),
    progressUpload: types.optional(types.number, 0),
    errorMessages: types.maybeNull(types.array(types.string))
  })
  .actions(self => ({
    setProgress(progress: number) {
      self.progressUpload = progress;
    },
    setLoading(isLoading: boolean) {
      self.isLoading = isLoading;
    },
    setUploaded(isUploaded: boolean) {
      self.isUploaded = isUploaded;
    },
  }))
  .actions(self => {
    const resetForm = () => {
      self.isLoading = false;
      self.isUploaded = false;
      self.setProgress(0);
      self.errorMessages = null;
    };

    const openModal = () => {
      self.show = true;
    };

    const closeModal = () => {
      self.show = false;
      resetForm();
    };

    const checkDevices = flow(function* checkDevices(file: File) {
      try {
        self.setProgress(0);
        self.isLoading = true;
        const formData = new FormData();
        formData.append("file", file);
        const { data } = yield API.post("/devices/import/validate-overriding", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
          onUploadProgress: (progressEvent) => {
            const percentCompleted = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total / 2
            );
            self.setProgress(percentCompleted);
          }
        });
        if (data.isSuccessful) {
          return true;
        } else {
          return false;
        }
      } catch (e) {
        console.error(e);
        self.errorMessages = cast(["Error, Something went wrong!"]);
        self.isLoading = false;
        self.isUploaded = true;
        return false;
      }
    });

    const addDevices = flow(function* addDevices(file: File) {
      try {
        self.isLoading = true;
        const formData = new FormData();
        formData.append("file", file);
        const { data } = yield API.post("/devices/import", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
          onUploadProgress: (progressEvent) => {
            const percentCompleted = Math.round(
              50 + (progressEvent.loaded * 100) / progressEvent.total / 2
            );
            self.setProgress(percentCompleted);
          }
        });

        setTimeout(() => {
          self.setLoading(false);
          self.setUploaded(true);
        }, 1000);
        
        if (data.isSuccessful) {
          self.errorMessages = null;
          return true;
        } else {
          self.errorMessages = data.errorMessages;
          return false;
        }
      } catch (e) {
        console.error(e);
        self.errorMessages = cast(["Error, Something went wrong!"]);
        self.isLoading = false;
        self.isUploaded = true;
        return false;
      }
      
    });

    return {
      openModal,
      closeModal,
      resetForm,
      addDevices,
      checkDevices
    };
  });