import { IAdminDevicesEditModelSnapShot } from "../stores/admin-devices-edit.store";

export const AdminDevicesEditState: IAdminDevicesEditModelSnapShot = {
  batchNumber: {
    name: "Batch ID",
    value: "",
    type: "input",
    fieldId: "batchNumber"
  },
  routeConfiguration: {
    name: "Route Configuration",
    value: "",
    type: "input",
    fieldId: "routeConfiguration"
  },
  workInstruction: {
    name: "Work Instruction",
    value: "",
    type: "select",
    showSelectedOptions: true,
    showSubLabel: true,
    fieldId: "workInstruction",
    options: []
  },
};