import { observer } from "mobx-react-lite";
import { FC, useMemo } from "react";
import { ETAdminWrapper } from "./et-admin.presents";
import { Outlet, useLocation } from "react-router-dom";
import { ADMIN_ROUTES } from "./et-admin-links";
import { useRootStore } from "../../stores/storeContext";
import { ETNavItem } from "../et-nav-item";

export const ETAdmin: FC = observer(() => {
  const { pathname } = useLocation();
  const { hasPermission } = useRootStore().userStore;
  const { isOpenedRightForm } = useRootStore().globalStore;

  const currentPage = useMemo(() => {
    return pathname.split("/")[2];
  }, [pathname]);

  return (
    <ETAdminWrapper>
      <div className="navigation">
        {ADMIN_ROUTES
          .map(({ link, title, permission }) =>
            !permission || hasPermission(permission)
              ? <ETNavItem
                key={link}
                isActive={link === currentPage}
                link={link}
              >
                {title}
              </ETNavItem>
              : null)
        }
      </div>
      <div className={`content ${isOpenedRightForm ? "form-margin" : ""}`}>
        <Outlet></Outlet>
      </div>
    </ETAdminWrapper>
  );
});