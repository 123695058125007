import styled from "styled-components";
import { theme } from "../../theme/theme";

export const SlAdminInviteModalWrapper = styled.div`
  .title {
    font-weight: normal;
    font-size: 24px;
    line-height: 28px;
    color: ${theme.colors.text};
  }

  .form-container {
    padding: 40px;

    .fields-container {
      padding-top: 60px;
      padding-bottom: 40px;
      width: 560px;
      margin-left: auto;
      margin-right: auto;
    }

    .btn-container {
      display: flex;
      justify-content: flex-end;

      div:last-child {
        margin-left: 24px;
      }
    }

    .col-container {
      display: flex;
      gap: 24px;

      .col-container-el {
        flex: 1;
      }
    }
  }
`;
