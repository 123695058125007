import styled from "styled-components";

export const AdminRouteConfigurationPageWrapper = styled.div`
  .scroll-table {
    height: calc(100vh - 274px);
    overflow: hidden;

    tbody {
      display: block;
      height: calc(100% - 58px);
      overflow-y: auto;

      &::-webkit-scrollbar-track {
        background-color: #F6F6F6;
      }

      &::-webkit-scrollbar-thumb {
        border: 4px solid transparent;
        background-clip: content-box;
      }
    }

    thead, tbody tr {
      display: table;
      width: 100%;
      table-layout: fixed;
    }

    table {
      display: block;
      height: 100%;
      margin-top: 0;
    }
  }
`;
