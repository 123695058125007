import { SnapshotIn, applySnapshot, flow, types } from "mobx-state-tree";
import { InputFormModel } from "vivaquant-components-library";
import { WI049FormState } from "../states/wi049-form.state";
import { WorkInstructionActionType } from "../boot/enums/WorkInstructionActionType";
import { TApiResponse } from "../types";
import { IWI049Response } from "../models/IWI049Response";
import { devicesApi } from "../services/api";
import { IWI049Request } from "../models/IWI049Request";

export const WI049FormModel = types
  .model("WI049FormModel", {
    show: types.optional(types.boolean, false),
    deviceId: types.maybeNull(types.string),
    batchId: types.maybeNull(types.string),
    serialNumber: types.maybeNull(types.string),
    wiRev: types.maybe(InputFormModel),
    batteryCode: types.maybe(InputFormModel),
    legsCode: types.maybe(InputFormModel),
    powerBoardCode: types.maybe(InputFormModel),
    housingBaseCode: types.maybe(InputFormModel),
    membraneSwitchCode: types.maybe(InputFormModel),
    housingCoverCode: types.maybe(InputFormModel),
    baseOperatorInitials: types.maybe(InputFormModel),
    coverOperatorInitials: types.maybe(InputFormModel),
    failureMode: types.maybe(InputFormModel),
    comment: types.maybe(InputFormModel),
    stage: types.maybeNull(types.number),
    baseData: types.frozen(),
    isNextDevice: types.optional(types.boolean, false)
  })
  .actions(self => {
    const resetForm = () => {
      self.deviceId = "";
      self.batchId = "";
      self.serialNumber = "";
      self.stage = 0;
      self.baseData = {};
      self.isNextDevice = false;
      applySnapshot(self, WI049FormState);
    };

    const openForm = () => {
      self.show = true;
    };

    const closeForm = () => {
      self.show = false;
      resetForm();
    };

    const validateField = (action: number): {errors: {[key: string]: string}, isValid: boolean} => {
      const errors: {[key: string]: string} = {};
      let isValid: boolean = true;

      if (action === WorkInstructionActionType.Pass) {
        if (!self.wiRev?.value) {
          errors.wiRev = "Please fill the field";
          isValid = false;
        }

        if (!self.batteryCode?.value) {
          errors.batteryCode = "Please fill the field";
          isValid = false;
        }

        if (!self.legsCode?.value) {
          errors.legsCode = "Please fill the field";
          isValid = false;
        }

        if (!self.powerBoardCode?.value) {
          errors.powerBoardCode = "Please fill the field";
          isValid = false;
        }

        if (!self.housingBaseCode?.value) {
          errors.housingBaseCode = "Please fill the field";
          isValid = false;
        }

        if (!self.membraneSwitchCode?.value) {
          errors.membraneSwitchCode = "Please fill the field";
          isValid = false;
        }

        if (!self.housingCoverCode?.value) {
          errors.housingCoverCode = "Please fill the field";
          isValid = false;
        }

        if (!self.baseOperatorInitials?.value) {
          errors.baseOperatorInitials = "Please fill the field";
          isValid = false;
        }

        if (!self.coverOperatorInitials?.value) {
          errors.coverOperatorInitials = "Please fill the field";
          isValid = false;
        }
      }

      return {
        errors,
        isValid
      };
    };

    const fetchData = flow(function* fetchData(
      id: string, 
      itemBatchIdNext: string, 
      isTransferData?: boolean, 
      isHistorical?: boolean
    ) {
      try {
        const response: TApiResponse<IWI049Response> = yield devicesApi.getWI049(id, isHistorical);
        if (response.isOk) {
          const { data } = response;
          self.deviceId = data.deviceId;
          self.batchId = data.batchId;
          self.serialNumber = data.serialNumber;
          self.stage = data.stage;
          self.wiRev.setValue(
            data.wiRev ? data.wiRev 
              : isTransferData ? self.wiRev.value : ""
          );
          self.batteryCode.setValue(
            data.batteryCode ? data.batteryCode 
              : isTransferData ? self.batteryCode.value : ""
          );
          self.legsCode.setValue(
            data.legsCode ? data.legsCode 
              : isTransferData ? self.legsCode.value : ""
          );
          self.powerBoardCode.setValue(
            data.powerBoardCode ? data.powerBoardCode 
              : isTransferData ? self.powerBoardCode.value : ""
          );
          self.housingBaseCode.setValue(
            data.housingBaseCode ? data.housingBaseCode 
              : isTransferData ? self.housingBaseCode.value : ""
          );
          self.membraneSwitchCode.setValue(
            data.membraneSwitchCode ? data.membraneSwitchCode 
              : isTransferData ? self.membraneSwitchCode.value : ""
          );
          self.housingCoverCode.setValue(
            data.housingCoverCode ? data.housingCoverCode 
              : isTransferData ? self.housingCoverCode.value : ""
          );
          self.baseOperatorInitials.setValue(
            data.baseOperatorInitials ? data.baseOperatorInitials 
              : isTransferData ? self.baseOperatorInitials.value : ""
          );
          self.coverOperatorInitials.setValue(
            data.coverOperatorInitials ? data.coverOperatorInitials 
              : isTransferData ? self.coverOperatorInitials.value : ""
          );

          self.failureMode.setLoading(true);
          self.failureMode.setValue(data.failureMode.toString());
          const option = self.failureMode.options.find((item) => item.value === data.failureMode.toString());
          if (option) {
            self.failureMode.setDefaultValue({ value: option.value.toString(), label: option.label });
          }
          setTimeout(() => {
            self.failureMode.setLoading(false);
          }, 2);
          self.comment.setValue(data.comment || "");

          if (self.batchId !== itemBatchIdNext) {
            self.isNextDevice = false;
          }

          self.baseData = {
            wiRev: data.wiRev,
            batteryCode: data.batteryCode,
            legsCode: data.legsCode,
            powerBoardCode: data.powerBoardCode,
            housingBaseCode: data.housingBaseCode,
            membraneSwitchCode: data.membraneSwitchCode,
            housingCoverCode: data.housingCoverCode,
            baseOperatorInitials: data.baseOperatorInitials,
            coverOperatorInitials: data.coverOperatorInitials,
            comment: data.comment,
            failureMode: data.failureMode,
          };

        }
      } catch (error) {
        console.error(error);
      }
    });

    const saveForm = flow(function* saveForm(action: number, isHistorical?: boolean) {
      try {
        const data: IWI049Request = {
          deviceId: self.deviceId,
          wiRev: self.wiRev.value,
          batteryCode: self.batteryCode.value,
          legsCode: self.legsCode.value,
          powerBoardCode: self.powerBoardCode.value,
          housingBaseCode: self.housingBaseCode.value,
          membraneSwitchCode: self.membraneSwitchCode.value,
          housingCoverCode: self.housingCoverCode.value,
          baseOperatorInitials: self.baseOperatorInitials.value,
          coverOperatorInitials: self.coverOperatorInitials.value,
          comment: self.comment.value,
          failureMode: +self.failureMode.value,
          action: action
        };
        if (isHistorical) {
          delete data.action;
        }
        const result =  yield devicesApi.saveWI049(data, isHistorical);
        if (isHistorical) {
          fetchData(self.deviceId, "", false, true);
        }
        return result;
      } catch (error) {
        console.error(error);
        return error;
      }
    });

    const isFormChanged = () => {
      if (!self.baseData) {
        return false;
      }
      if ( self.wiRev.value !== self.baseData.wiRev
        || self.batteryCode.value !== self.baseData.batteryCode
        || self.legsCode.value !== self.baseData.legsCode
        || self.powerBoardCode.value !== self.baseData.powerBoardCode
        || self.housingBaseCode.value !== self.baseData.housingBaseCode
        || self.membraneSwitchCode.value !== self.baseData.membraneSwitchCode
        || self.housingCoverCode.value !== self.baseData.housingCoverCode
        || self.baseOperatorInitials.value !== self.baseData.baseOperatorInitials
        || self.coverOperatorInitials.value !== self.baseData.coverOperatorInitials
        || self.comment.value !== self.baseData.comment
        || +self.failureMode.value !== self.baseData.failureMode
      ) {
        return true;
      } else {
        return false;
      }
    };

    const setIsNextDevice = (value: boolean) => {
      self.isNextDevice = value;
    };

    return {
      fetchData,
      openForm,
      closeForm,
      resetForm,
      validateField,
      saveForm,
      isFormChanged,
      setIsNextDevice
    };
  });

export interface IWI049FormModelSnapShot extends SnapshotIn<typeof WI049FormModel> {}
