export enum WI068ReprogrammingJustificationType {
  None = 0,
  MainBoardWasSalvaged = 1,
  DeviceNeedsToBeReprogrammed = 2,
  Other = 3,
}

export enum WI068ReprogrammingJustificationTypeLabels {
  None = "None",
  MainBoardWasSalvaged = "Main board was salvaged from RMA device with issue not related to main board",
  DeviceNeedsToBeReprogrammed = "Device needs to be reprogrammed and has never left VivaQuant control",
  Other = "Other",
}