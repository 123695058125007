import { WI041FailureMode } from "../boot/enums/WI041FailureMode";
import { IWI041FormModelSnapShot } from "../stores/wi041-form.store";

export const WI041FormState: IWI041FormModelSnapShot = {
  wiRev: {
    name: "WI REV",
    value: "",
    type: "input",
    fieldId: "wiRev"
  },
  boardReversion: {
    name: "Motherboard Revision",
    value: "",
    type: "input",
    fieldId: "boardReversion"
  },
  dimationBoardSN: {
    name: "Board Serial Number",
    value: "",
    type: "input",
    fieldId: "dimationBoardSN"
  },
  imei: {
    name: "Modem IMEI",
    value: "",
    type: "input",
    fieldId: "imei"
  },
  iccid: {
    name: "SIM ICCID",
    value: "",
    type: "input",
    fieldId: "iccid"
  },
  failureMode: {
    name: "Failure Mode",
    value: WI041FailureMode.None.toString(),
    type: "select",
    fieldId: "failureMode",
    options: Object.keys(WI041FailureMode)
      .filter((v) => isNaN(Number(v)))
      .map((key: string) => {
        return {
          value: WI041FailureMode[key as keyof typeof WI041FailureMode].toString(),
          label: key,
        };
      }),
    defaultValue: { value: WI041FailureMode.None.toString(), label: WI041FailureMode[0] },
  },
  comment: {
    name: "Comment",
    value: "",
    type: "textarea",
    fieldId: "comment"
  },
};
