import { Instance, flow, types } from "mobx-state-tree";
import { API } from "../boot/api";
import { TApiResponse } from "../types";
import { ILoginResponse } from "../models/ILoginResponse";
import { authAPI, inviteAPI, userAPI } from "../services/api";
import { PermissionService, Permissions } from "../services/permission.service";
import { IUpdateProfileRequest } from "../models/IUpdateProfileRequest";

const ProfileModel = types
  .model("ProfileModel", {
    id: types.maybeNull(types.string),
    firstname: types.maybeNull(types.string),
    lastname: types.maybeNull(types.string),
    email: types.maybeNull(types.string),
    username: types.maybeNull(types.string),
    group: types.maybeNull(types.string),
    groups: types.maybeNull(types.array(types.string)),
    location: types.maybeNull(types.string),
    is2FaEnabled: types.maybeNull(types.boolean),
    permissions: types.maybeNull(types.number),
  });

export interface IProfileModel extends Instance<typeof ProfileModel> {}

export const UserStoreModel = types
  .model("UserStoreModel", {
    isLoading: types.optional(types.boolean, false),
    isAuth: types.maybe(types.boolean),
    profile: types.maybe(ProfileModel)
  })
  .actions(self => ({
    setIsAuth(value: boolean) {
      self.isAuth = value;
    }
  }))
  .actions(self => {
    const checkAuthorized = flow(function* checkAuthorized() {
      try {
        self.isLoading = true;
        const token = localStorage.getItem("token");
        if (!token) {
          self.setIsAuth(false);
          return;
        }
        (API.defaults.headers as unknown as Record<string, string>).Authorization = token;
        const result: TApiResponse<IProfileModel> = yield userAPI.getCurrentUser();
        if (result.isOk) {
          self.setIsAuth(true);
          self.profile = result.data;
        } else {
          self.setIsAuth(false);
        }
        return result;
      } catch (e) {
        self.setIsAuth(false);
      } finally {
        self.isLoading = false;
      }
    });

    const hasPermission = (perm: Permissions) => {
      return PermissionService.hasPermission(self.profile, perm);
    };

    const updateProfileData = (profile: IUpdateProfileRequest) => {
      self.profile.firstname = profile.firstname;
      self.profile.lastname = profile.lastname;
      self.profile.email = profile.email;
      self.profile.username = profile.username;
      self.profile.location = profile.location;
      self.profile.is2FaEnabled = profile.is2FaEnabled;
    };

    const logout = () => {
      delete (API.defaults.headers as unknown as Record<string, string>).Authorization;
      localStorage.removeItem("token");
      self.setIsAuth(false);
    };

    const login = flow(function* login(email: string, password: string) {
      try {
        const response: TApiResponse<ILoginResponse> = yield authAPI.login(email, password);
        if (response.isOk) {
          if (!response.data.is2FactorAuthEnabled && response.data.token) {
            const token = "Bearer " + response.data.token;
            localStorage.setItem("token", token);
            (API.defaults.headers as unknown as Record<string, string>).Authorization = token;
            self.setIsAuth(true);
            self.profile = response.data.user;
            return true;
          } else if (response.data.is2FactorAuthEnabled && response.data.sendCodeToEmail) {
            return { email: response.data.sendCodeToEmail };
          } else {
            self.setIsAuth(false);
            return false;
          }
        } else {
          self.setIsAuth(false);
          return false;
        }
      } catch (err) {
        self.setIsAuth(false);
        return false;
      }
    });

    const loginTwoFactor = flow(function* loginTwoFactor(email: string, code: string) {
      try {
        const response: TApiResponse<ILoginResponse> = yield authAPI.loginTwoFactor(email, code);
        if (!response.isOk || !response.data.token) {
          self.setIsAuth(false);
          return false;
        }
        const token = "Bearer " + response.data.token;
        localStorage.setItem("token", token);
        (API.defaults.headers as unknown as Record<string, string>).Authorization = token;
        self.setIsAuth(true);
        self.profile = response.data.user;
        return true;
      } catch (err) {
        self.setIsAuth(false);
        return false;
      }
    });

    const resendLoginCode = flow(function* resendLoginCode(email: string) {
      try {
        const response: TApiResponse<null> = yield authAPI.resendLoginCode(email);
        return response.isOk;
      } catch (e) {
        return false;
      }
    });

    const forgotPassword = flow(function* forgotPassword(email: string) {
      try {
        const result: TApiResponse<null> = yield authAPI.resetPassword(email);
        return !!result.isOk;
      } catch (error) {
        return false;
      }
    });

    const isRecoveryLinkValid = flow(function* isRecoveryLinkValid(token: string) {
      try {
        const result: TApiResponse<boolean> = yield authAPI.isRecoveryLinkValid(token);
        return result.isOk ? result.data : false;
      } catch (error) {
        return false;
      }
    });

    const recoveryPassword = flow(function* recoveryPassword(token: string, password: string) {
      try {
        const result: TApiResponse<null> = yield authAPI.recoveryPassword(token, password);
        return !!result.isOk;
      } catch (error) {
        return false;
      }
    });

    const isTokenValid = flow(function* isTokenValid(token: string) {
      try {
        const result: TApiResponse<boolean> = yield inviteAPI.isInviteTokenValid(token);
        return result.isOk && result.data;
      } catch (error) {
        return false;
      }
    });

    const completeInvite = flow(function* completeInvite(token: string, username: string, password: string) {
      try {
        const result: TApiResponse<null> = yield inviteAPI.completeInvite(token, username, password);
        return !!result.isOk;
      } catch (error) {
        return false;
      }
    });

    return {
      checkAuthorized,
      hasPermission,
      logout,
      login,
      loginTwoFactor,
      resendLoginCode,
      forgotPassword,
      isRecoveryLinkValid,
      recoveryPassword,
      isTokenValid,
      completeInvite,
      updateProfileData
    };
  });
