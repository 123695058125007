import React from "react";
import { LoginFormModel } from "./authorization/login.store";
import { ForgotFormModel } from "./authorization/forgot.store";
import { RecoveryFormModel } from "./authorization/recovery.store";
import { InvitationFormModel } from "./authorization/invitation.store";
import { GlobalStoreModel } from "./global.store";
import { UserStoreModel } from "./user.store";
import { ProfileStoreModel } from "./profile.store";
import { AdminUsersInviteModel } from "./admin-users-invite.store";

import { ProfileInitialState } from "../states/profile.state";
import { AdminUsersInviteState } from "../states/admin-users-invite.state";
import { AdminUsersListModel } from "./admin-users-list.store";
import { InProgressListModel } from "./in-progress-list.store";
import { AdminDevicesListModel } from "./admin-devices-list.store";
import { AdminDevicesAddModel } from "./admin-devices-add.store";
import { AdminRouteConfigurationListModel } from "./admin-route-configuration-list.store";
import { AdminRouteConfigurationAddModel } from "./admin-route-configuration-add.store";
import { AdminRouteConfigurationAddState } from "../states/admin-route-configuration-add.state";
import { WI041FormModel } from "./wi041-form.store";
import { WI041FormState } from "../states/wi041-form.state";
import { WI068FormModel } from "./wi068-form.store";
import { WI068FormState } from "../states/wi068-form.state";
import { WI056FormModel } from "./wi056-form.store";
import { WI056FormState } from "../states/wi056-form.state";
import { WI083FormModel } from "./wi083-form.store";
import { WI083FormState } from "../states/wi083-form.state";
import { WI048FormModel } from "./wi048-form.store";
import { WI048FormState } from "../states/wi048-form.state";
import { WI049FormModel } from "./wi049-form.store";
import { WI049FormState } from "../states/wi049-form.state";
import { WI057FormModel } from "./wi057-form.store";
import { WI057FormState } from "../states/wi057-form.state";
import { WI050FormModel } from "./wi050-form.store";
import { WI050FormState } from "../states/wi050-form.state";
import { WI058FormModel } from "./wi058-form.store";
import { WI058FormState } from "../states/wi058-form.state";
import { WI051FormModel } from "./wi051-form.store";
import { WI051FormState } from "../states/wi051-form.state";
import { WI119FormModel } from "./wi119-form.store";
import { WI119FormState } from "../states/wi119-form.state";
import { ReleasedListModel } from "./released-list.store";
import { AdminDevicesEditModel } from "./admin-devices-edit.store";
import { AdminDevicesEditState } from "../states/admin-devices-edit.state";
import { WI059ERFormModel } from "./wi059er-form.store";
import { WI059ERFormState } from "../states/wi059er-form.state";
import { NCMListModel } from "./ncm-list.store";
import { NCMDispositionModel } from "./ncm-disposition.store";
import { NCMDispositionState } from "../states/ncm-disposition.state";
import { ReportModel } from "./report.store";

type RootStoreContextValue = {
  loginFormStore: any,
  forgotFormStore: any,
  recoveryFormStore: any,
  invitationFormStore: any,
  globalStore: any,
  userStore: any,
  profileStore: any,
  adminUsersInviteStore: any
  adminUsersListStore: any
  adminDevicesListStore: any,
  adminDevicesAddStore: any,
  adminDevicesEditStore: any,
  adminRouteConfigurationListStore: any,
  adminRouteConfigurationAddStore: any
  inProgressListStore: any,
  releasedListStore: any,
  ncmListStore: any,
  ncmDispositionStore: any,
  reportStore: any,

  wi041FormStore: any,
  wi068FormStore: any
  wi056FormStore: any,
  wi083FormStore: any,
  wi048FormStore: any,
  wi049FormStore: any,
  wi057FormStore: any,
  wi050FormStore: any,
  wi058FormStore: any,
  wi051FormStore: any,
  wi119FormStore: any
  wi059ERFormStore: any
};

const RootStoreContext = React.createContext<RootStoreContextValue>({} as RootStoreContextValue);

const context = {
  loginFormStore: LoginFormModel.create(),
  forgotFormStore: ForgotFormModel.create(),
  recoveryFormStore: RecoveryFormModel.create(),
  invitationFormStore: InvitationFormModel.create(),
  globalStore: GlobalStoreModel.create(),
  userStore: UserStoreModel.create(),
  profileStore: ProfileStoreModel.create(ProfileInitialState),
  adminUsersInviteStore: AdminUsersInviteModel.create(AdminUsersInviteState),
  adminUsersListStore: AdminUsersListModel.create(),
  adminDevicesListStore: AdminDevicesListModel.create(),
  adminDevicesAddStore: AdminDevicesAddModel.create(),
  adminDevicesEditStore: AdminDevicesEditModel.create(AdminDevicesEditState),
  adminRouteConfigurationListStore: AdminRouteConfigurationListModel.create(),
  adminRouteConfigurationAddStore: AdminRouteConfigurationAddModel.create(AdminRouteConfigurationAddState),
  inProgressListStore: InProgressListModel.create(),
  releasedListStore: ReleasedListModel.create(),
  ncmListStore: NCMListModel.create(),
  ncmDispositionStore: NCMDispositionModel.create(NCMDispositionState),
  reportStore: ReportModel.create(),

  wi041FormStore: WI041FormModel.create(WI041FormState),
  wi068FormStore: WI068FormModel.create(WI068FormState),
  wi056FormStore: WI056FormModel.create(WI056FormState),
  wi083FormStore: WI083FormModel.create(WI083FormState),
  wi048FormStore: WI048FormModel.create(WI048FormState),
  wi049FormStore: WI049FormModel.create(WI049FormState),
  wi057FormStore: WI057FormModel.create(WI057FormState),
  wi050FormStore: WI050FormModel.create(WI050FormState),
  wi058FormStore: WI058FormModel.create(WI058FormState),
  wi051FormStore: WI051FormModel.create(WI051FormState),
  wi119FormStore: WI119FormModel.create(WI119FormState),
  wi059ERFormStore: WI059ERFormModel.create(WI059ERFormState),
};

export const RootStoreProvider: React.FC<React.PropsWithChildren<{}>> = ({ children }) => {
  return <RootStoreContext.Provider value={context}>{children}</RootStoreContext.Provider>;
};
export const useRootStore = () => React.useContext(RootStoreContext);
