import { observer } from "mobx-react-lite";
import { FC, useEffect } from "react";
import { ReleasedPageWrapper } from "./Released.presents";
import { useRootStore } from "../../stores/storeContext";
import { ETReleasedListHeader } from "../../components/et-released-list-header";
import { ETReleasedListTable } from "../../components/et-released-list-table";
import { ETReportModal } from "../../components/et-report-modal";

export const ReleasedPage: FC = observer(() => {
  const {
    getTotalCount,
    fetchFunctionalDemoLocationOptions
  } = useRootStore().releasedListStore;
  const { setGlobalSpiner } = useRootStore().globalStore;

  const { show: showReport } = useRootStore().reportStore;

  
  useEffect(() => {
    setGlobalSpiner(true);
    fetchFunctionalDemoLocationOptions();
    getTotalCount().then(() => { setGlobalSpiner(false); });
  }, []);

  return (
    <>
      <ReleasedPageWrapper>
        <ETReleasedListHeader></ETReleasedListHeader>
        <ETReleasedListTable></ETReleasedListTable>
      </ReleasedPageWrapper>
      {showReport && <ETReportModal></ETReportModal>}
    </>
    
  );
});