import { WI057FailureMode, WI057FailureModeLabels } from "../boot/enums/WI057FailureMode";
import { IWI057FormModelSnapShot } from "../stores/wi057-form.store";

export const WI057FormState: IWI057FormModelSnapShot = {
  wiRev: {
    name: "WI REV",
    value: "",
    type: "input",
    fieldId: "wiRev"
  },
  cellSignal: {
    name: "Cell Signal",
    value: "",
    type: "input",
    fieldId: "cellSignal"
  },
  failureMode: {
    name: "Failure Mode",
    value: WI057FailureMode.None.toString(),
    type: "select",
    fieldId: "failureMode",
    options: Object.keys(WI057FailureMode)
      .filter((v) => isNaN(Number(v)))
      .map((key: string) => {
        return {
          value: WI057FailureMode[key as keyof typeof WI057FailureMode].toString(),
          label: WI057FailureModeLabels[key as keyof typeof WI057FailureMode],
        };
      }),
    defaultValue: { value: WI057FailureMode.None.toString(), label: WI057FailureModeLabels.None },
  },
  comment: {
    name: "Comment",
    value: "",
    type: "textarea",
    fieldId: "comment"
  },
};