import React, { FC, useMemo } from "react";
import { observer } from "mobx-react-lite";
import { Outlet, useLocation } from "react-router-dom";
import { LayoutWrapper } from "./et-layout.presents";
import { ETHeader } from "../et-header";
import { DASHBOARD_ROUTES } from "../et-header/et-header-links";
import { ADMIN_ROUTES } from "../et-admin/et-admin-links";

export const ETLayout: FC = observer(() => {
  const { pathname } = useLocation();

  const isDashboardPage = useMemo(() => {
    const splitNames = pathname.split("/");

    return !!DASHBOARD_ROUTES.find(item => item.link === splitNames[1])
      || !!ADMIN_ROUTES.find(item => item.link === splitNames[2]);
  }, [pathname]);

  return (
    <LayoutWrapper greyedBg={isDashboardPage}>
      <ETHeader/>
      <Outlet/>
    </LayoutWrapper>
  );
});
