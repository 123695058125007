import { observer } from "mobx-react-lite";
import { FC, useMemo } from "react";
import { useRootStore } from "../../stores/storeContext";
import debounce from "lodash.debounce";
import { VQGreenButton, VQInputSearch, VQPagination, VQTableSearchSection } from "vivaquant-components-library";
import { PAGINATION } from "../../boot/constants";
import { Permissions } from "../../services/permission.service";

export const ETadminRouteConfigurationListHeader: FC = observer(() => {
  const {
    setSearch,
    searchValue,
    getTotalCount,
    page,
    total,
    nextPage,
    previousPage,
    setFirstPage
  } = useRootStore().adminRouteConfigurationListStore;
  const { resetForm, openForm, fetchData } = useRootStore().adminRouteConfigurationAddStore;
  const { setGlobalSpiner, setOpenedRightForm, isOpenedRightForm } = useRootStore().globalStore;
  const { hasPermission } = useRootStore().userStore;

  const onAdd = () => {
    resetForm();
    setOpenedRightForm(true);
    setGlobalSpiner(true);
    return fetchData().then(() => {
      openForm();
      setGlobalSpiner(false);
    });
  };

  const handleSearchChange = (value: string) => {
    setSearch(value);
    refreshData();
  };

  const refreshData = useMemo(
    () => debounce(() => {
      setFirstPage();
      getTotalCount();
    }, 1000), []);

  return(
    <>
      <VQTableSearchSection>
        <VQInputSearch
          disabled={isOpenedRightForm}
          handleChange={handleSearchChange}
          placeholder={"Search"}
          value={searchValue}
          autoComplete={false}/>
        <div className="left">
          <VQGreenButton
            click={onAdd}
            text="Add Route Configuration"
            isDisabled={isOpenedRightForm || !hasPermission(Permissions.RouteConfigurationEdit)}
            width="254px"></VQGreenButton>
          <VQPagination
            disabled={isOpenedRightForm}
            next={nextPage}
            previous={previousPage}
            total={total}
            countOnPage={PAGINATION.ROWS_PER_PAGE}
            disableControls={true}
            page={page}/>
        </div>
      </VQTableSearchSection>
    </>
  );
});
