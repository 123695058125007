import React, { FC } from "react";
import { NavItemProps } from "./et-nav-item.props";
import { NavItemWrapper } from "./et-nav-item.presents";
import { useNavigate } from "react-router-dom";
import { useRootStore } from "../../stores/storeContext";
import { confirmAlert } from "react-confirm-alert";
import { WIFormService } from "../../services/wi-form.service";
import { StringsUtils } from "../../services/utils";
import { WorkInstructionType } from "../../boot/enums/WorkInstructionType";

export const ETNavItem: FC<NavItemProps> = ({ link, isActive, children }) => {
  const { setOpenedRightForm } = useRootStore().globalStore;
  const stores = useRootStore();

  const navigate = useNavigate();

  const getWIName = (value: number) => {
    return StringsUtils.getWIStoreName(WorkInstructionType[value]);
  };

  const actionHandler = () => {
    let closeForm: Function = null;
    let isFormChanged: Function = null;
    let selectedStore: any = {};
    let storeName = "";

    for (const [key, value] of Object.entries(stores)) {
      if (value.show) {
        closeForm = value.closeForm;
        isFormChanged = value.isFormChanged;
        selectedStore = value;
        storeName = key;
      }
    }

    if (closeForm && isFormChanged) {
      const hide = () => {
        setOpenedRightForm(false);
        closeForm();
        navigate(link);
      };

      if (storeName === "adminDevicesEditStore") {
        const { isFormChanged: isFormChangedWI } 
          = stores[getWIName(+selectedStore?.workInstruction?.value) as keyof typeof stores];
        WIFormService.onClose(() => { 
          return isFormChanged(selectedStore.isWIFormOpened ? isFormChangedWI : null); 
        }, confirmAlert, hide);
      } else {
        return WIFormService.onClose(isFormChanged, confirmAlert, hide);
      }
      
    } else {
      return navigate(link);
    }
  };

  return (
    <NavItemWrapper isActive={isActive}>
      <div className="link" onClick={actionHandler}>{children}</div>
    </NavItemWrapper>
  );
};
