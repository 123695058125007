import { flow, types } from "mobx-state-tree";
import { reportApi } from "../services/api";
import { ErrorMessages, ReportStatusError } from "../boot/constants";

export const ReportModel = types
  .model("ReportModel", {
    show: types.optional(types.boolean, false),
    isLoading: types.optional(types.boolean, false),
    blobPDF: types.maybe(types.frozen()),
    urlPDF: types.maybe(types.frozen()),
    namePDF: types.optional(types.string, "")
  })
  .actions(self => ({
    setLoading(isLoading: boolean) {
      self.isLoading = isLoading;
    }
  }))
  .actions(self => {

    const reset = () => {
      self.isLoading = false;
      self.blobPDF = null;
      self.urlPDF = null;
      self.namePDF = "";
    };

    const openModal = () => {
      self.show = true;
    };

    const closeModal = () => {
      self.show = false;
      reset();
    };

    const getReport = flow(function* getReport(id: string, name: string) {
      self.setLoading(true);
      try {
        const response = yield reportApi.getReport(id);
        let msg = "";
        if (response.isOk) {
          self.blobPDF = response.data.data;
          self.urlPDF = URL.createObjectURL(response.data.data);
          self.namePDF = name;
        } else {
          if (response.status === 404 && response.headers?.etravelernotfounderror === ReportStatusError.notFinished) {
            msg = ErrorMessages.reportNotFinished;
          } else if (response.status === 404 
            && response.headers?.etravelernotfounderror === `${ReportStatusError.notFound} ${id}`
          ) {
            msg = ErrorMessages.reportNotFound;
          } else {
            msg = ErrorMessages.reportErrorMsg;
          }
        }
        self.setLoading(false);
        return msg;
      } catch (error) {
        self.setLoading(false);
        return ErrorMessages.reportErrorMsg;
      }
    });

    const downloadReport = () => {
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(self.blobPDF);
      link.download = self.namePDF;
      link.click();
      link.parentNode?.removeChild(link);
    };

    return {
      getReport,
      openModal,
      closeModal,
      downloadReport
    };
  });
