/* eslint-disable max-len */
import { StringsUtils } from "../services/utils";
import { NcmDeviceStage } from "./enums/NcmDeviceStage";
import { WorkInstructionType } from "./enums/WorkInstructionType";

export const API_URL = "https://et-api.rhythmexpressecg.com/api";
export const RB_API_URL = "https://v5th9f6aag.execute-api.us-east-2.amazonaws.com/production";
export const RECAPTCHA_KEY = "6Lf3GssbAAAAAIqUo2myXWPyzkMpfWcu4dX97Id4";

export const SuccessMessages = {
  successForgot: "We have emailed you a link resetting your password."
    + "\nIf you did not receive the email please check your address and your spam folder."
    + "\nOnly users already registered with RhythmExpress can apply for a new password.",
  successRecovery: "Your password was successfully updated!",
  successInvitation: "Your password was successfully created! Use your email to sign in."
};

export const ErrorMessages = {
  errorLogin: "Wrong email or password",
  errorPhone: "Wrong phone format",
  errorUsername: "Minimum 6 characters required",
  errorEmptyField: "Empty field",
  errorForgot: "Wrong email",
  errorSelectedAccount: "Account is not selected",
  errorAccountType: "Account type in not selected",
  wrongEmail: "Wrong Email",
  wrongPassword: "Wrong password",
  actionCanNotUse: "This action can not use now",
  recoveryError: "Error recovery password",
  recoveryLinkInvalid: "Recovery link is invalid",
  invitationLinkInvalid: "Invitation link is invalid",
  passwordsDidNotMatch: "Those passwords didn't match. Try again.",
  failInvitation: "Your account cannot be created",
  invalidDate: "The date is invalid",
  noItemsOrdered: "You have to order at least 1 item",
  invalidZipCode: "Zip code format is invalid",
  emptyField: "Please fill the field",
  reportNotFinished: "Your report is currently being generated. Please check back shortly.",
  reportNotFound: "The report couldn't be found. Please try completing the Work Instruction again to generate the report.",
  reportErrorMsg: "The report is not available. Please try again later."
};

export const ReportStatusError = {
  notFinished: "ETravellerReport generation not finished",
  notFound: "ETravellerReport was not found by ETReportId|"
};

export const PasswordRules = (
  <div>
    <div>{`A password shall consist of a minimum of 8 characters including 
    at least one character matching each of the following requirements:`}</div>
    <ul>
      <li>capital letter</li>
      <li>small letter</li>
      <li>number</li>
      <li>special character (i.e. #, $, !, &, #, space)</li>
    </ul>
  </div>
);

export const enum SortDirection
{
  ASC = 0,
  DESC = 1
}

export const PAGINATION = {
  ROWS_PER_PAGE: 20,
};

const GetInProgressListCategories = () => {
  const result = Object.keys(WorkInstructionType)
    .filter((v) => isNaN(Number(v)))
    .map((key: string) => {
      return {
        title: StringsUtils.addWIDashes(key),
        value: WorkInstructionType[key as keyof typeof WorkInstructionType].toString(),
        state: key,
      };
    });

  result.unshift({
    title: "All",
    value: "",
    state: "All",
  });
  return result;
};

const GetNCMListCategories = () => {
  const result = Object.keys(NcmDeviceStage)
    .filter((v) => isNaN(Number(v)))
    .map((key: string) => {
      return {
        title: StringsUtils.addNCMWIDashes(key),
        value: NcmDeviceStage[key as keyof typeof NcmDeviceStage].toString(),
        state: key,
      };
    });

  result.unshift({
    title: "All",
    value: "",
    state: "All",
  });
  return result;
};

export const InProgressListCategories = GetInProgressListCategories();
export const NCMListCategories = GetNCMListCategories();

export const ReleasedCategoriesValue = {
  all: "",
  inStock: "in-stock",
  shipped: "shipped",
  functionalDemo: "demo"
};

export const ReleasedListCategories = [
  {
    title: "All",
    value: ReleasedCategoriesValue.all,
    state: "all",
  },
  {
    title: "In Stock",
    value: ReleasedCategoriesValue.inStock,
    state: "inStock",
  },
  {
    title: "Shipped",
    value: ReleasedCategoriesValue.shipped,
    state: "shipped",
  },
  {
    title: "Functional Demo",
    value: ReleasedCategoriesValue.functionalDemo,
    state: "functionalDemo",
  }
];

export const InStockLocationOptions = [
  {
    label: "In Stock",
    value: 0
  },
  {
    label: "Engineering",
    value: 1
  }
];

export const InProgressListSort = {
  batchId: "batchId",
  serialNumber: "serialNumber",
  routeConfiguration: "routeConfiguration",
  stage: "stage",
  movedtostageat: "movedtostageat"
};

export const ReleasedListSort = {
  serialnumber: "serialnumber",
  releasedat: "releasedat",
  shippedat: "shippedat",
  dispositionedat: "dispositionedat",
  instocklocation: "instocklocation",
  functionaldemolocation: "functionaldemolocation",
  stage: "stage"
};

export const NCMListSort = {
  serialnumber: "serialnumber",
  stage: "stage",
  failuremode: "failuremode",
  movedtoncmat: "movedtoncmat"
};

export const AdminDevicesListSort = {
  ...InProgressListSort,
  importDate: "importDate"
};

export const AdminRouteConfigurationListSort = {
  name: "name"
};
