import { observer } from "mobx-react-lite";
import { FC, useEffect } from "react";
import { AdminDevicesPageWrapper } from "./Devices.presents";
import { useRootStore } from "../../../stores/storeContext";
import { ETAdminDevicesListHeader } from "../../../components/et-admin-devices-list-header";
import { ETAdminDevicesListTable } from "../../../components/et-admin-devices-list-table";
import { ETRightForm } from "../../../components/et-right-form";
import { ETAdminDevicesEdit } from "../../../components/et-admin-devices-edit";
import { ETReportModal } from "../../../components/et-report-modal";

export const AdminDevicesPage: FC = observer(() => {
  const { getTotalCount } = useRootStore().adminDevicesListStore;
  const { setGlobalSpiner } = useRootStore().globalStore;
  const { show } = useRootStore().adminDevicesEditStore;

  const { show: showReport } = useRootStore().reportStore;

  useEffect(() => {
    setGlobalSpiner(true);
    getTotalCount().then(() => { setGlobalSpiner(false); });
  }, []);
  return (
    <>
      <AdminDevicesPageWrapper>
        <ETAdminDevicesListHeader></ETAdminDevicesListHeader>
        <ETAdminDevicesListTable></ETAdminDevicesListTable>
      </AdminDevicesPageWrapper>
      
      {show && <ETRightForm 
        title={"Edit Device"}
      >
        <ETAdminDevicesEdit></ETAdminDevicesEdit>
      </ETRightForm>}

      {showReport && <ETReportModal></ETReportModal>}
    </>
    
  );
});