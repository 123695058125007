export enum WI056FailureMode {
  None = 0,
  BoardVoltage = 1,
  ProgrammerConnection = 2,
  MCProgrammingError = 3,
  DSPProgrammingError = 4,
  Other = 5,
}

export enum WI056FailureModeLabels {
  None = "None",
  BoardVoltage = "Board Voltage",
  ProgrammerConnection = "Programmer Connection",
  MCProgrammingError = "MC Programming Error",
  DSPProgrammingError = "DSP Programming Error",
  Other = "Other",
}