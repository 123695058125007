import { WI049FailureMode, WI049FailureModeLabels } from "../boot/enums/WI049FailureMode";
import { IWI049FormModelSnapShot } from "../stores/wi049-form.store";

export const WI049FormState: IWI049FormModelSnapShot = {
  wiRev: {
    name: "WI REV",
    value: "",
    type: "input",
    fieldId: "wiRev"
  },
  batteryCode: {
    name: "Battery",
    value: "",
    type: "input",
    fieldId: "batteryCode"
  },
  legsCode: {
    name: "Legs",
    value: "",
    type: "input",
    fieldId: "legsCode"
  },
  powerBoardCode: {
    name: "Power Board",
    value: "",
    type: "input",
    fieldId: "powerBoardCode"
  },
  housingBaseCode: {
    name: "Housing Base",
    value: "",
    type: "input",
    fieldId: "housingBaseCode"
  },
  membraneSwitchCode: {
    name: "Membrane Switch",
    value: "",
    type: "input",
    fieldId: "membraneSwitchCode"
  },
  housingCoverCode: {
    name: "Housing Cover",
    value: "",
    type: "input",
    fieldId: "housingCoverCode"
  },
  baseOperatorInitials: {
    name: "Base Operator",
    value: "",
    type: "input",
    fieldId: "baseOperatorInitials"
  },
  coverOperatorInitials: {
    name: "Cover Operator",
    value: "",
    type: "input",
    fieldId: "coverOperatorInitials"
  },
  failureMode: {
    name: "Failure Mode",
    value: WI049FailureMode.None.toString(),
    type: "select",
    fieldId: "failureMode",
    options: Object.keys(WI049FailureMode)
      .filter((v) => isNaN(Number(v)))
      .map((key: string) => {
        return {
          value: WI049FailureMode[key as keyof typeof WI049FailureMode].toString(),
          label: WI049FailureModeLabels[key as keyof typeof WI049FailureMode],
        };
      }),
    defaultValue: { value: WI049FailureMode.None.toString(), label: WI049FailureModeLabels.None },
  },
  comment: {
    name: "Comment",
    value: "",
    type: "textarea",
    fieldId: "comment"
  },
};
