import { API } from "../../boot/api";
import { ILoginResponse } from "../../models/ILoginResponse";
import { ApiResponseDto } from "../../models/api";
import { TApiResponse } from "../../types";
import { ProcessAPIError } from "../decorators";

class AuthApi {
  @ProcessAPIError()
  public async login(login: string, password: string): Promise<ApiResponseDto<ILoginResponse>> {
    const { data } = await API.post("auth/login", { login, password });
    return new ApiResponseDto<ILoginResponse>(data);
  }

  @ProcessAPIError()
  public async loginTwoFactor(login: string, code: string): Promise<TApiResponse<ILoginResponse>> {
    const result = await API.post("auth/login/2fa", { login, code });
    return new ApiResponseDto(result.data);
  }

  @ProcessAPIError()
  public async resendLoginCode(email: string): Promise<TApiResponse<null>> {
    await API.post("auth/2fa", { email });
    return new ApiResponseDto(null);
  }

  @ProcessAPIError()
  public async resetPassword(email: string) : Promise<ApiResponseDto<{isResetLinkSent: boolean}>> {
    const { data } = await API.post<{isResetLinkSent: boolean}>("auth/reset-password", { email });
    return new ApiResponseDto<{isResetLinkSent: boolean}>(data);
  }

  @ProcessAPIError()
  public async isRecoveryLinkValid(token: string) : Promise<ApiResponseDto<boolean>> {
    const { data } = await API.get<boolean>(`auth/reset-password/${token}/is-valid`);
    return new ApiResponseDto<boolean>(data);
  }

  @ProcessAPIError()
  public async recoveryPassword(link: string, password: string) : Promise<ApiResponseDto<null>> {
    await API.patch<null>("auth/recover-password", { link, password });
    return new ApiResponseDto<null>(null);
  }
}

export const authAPI = new AuthApi();
