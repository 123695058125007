import { API } from "../../boot/api";
import { IEditDeviceRequest } from "../../models/IEditDeviceRequest";
import { IEditDeviceResponse } from "../../models/IEditDeviceResponse";
import { IInProgressDevicesCountResponse } from "../../models/IInProgressDevicesCountResponse";
import { IWI041Request } from "../../models/IWI041Request";
import { IWI041Response } from "../../models/IWI041Response";
import { IWI048Request } from "../../models/IWI048Request";
import { IWI048Response } from "../../models/IWI048Response";
import { IWI049Request } from "../../models/IWI049Request";
import { IWI049Response } from "../../models/IWI049Response";
import { IWI050Request } from "../../models/IWI050Request";
import { IWI050Response } from "../../models/IWI050Response";
import { IWI051Request } from "../../models/IWI051Request";
import { IWI051Response } from "../../models/IWI051Response";
import { IWI056Request } from "../../models/IWI056Request";
import { IWI056Response } from "../../models/IWI056Response";
import { IWI057Request } from "../../models/IWI057Request";
import { IWI057Response } from "../../models/IWI057Response";
import { IWI058Request } from "../../models/IWI058Request";
import { IWI058Response } from "../../models/IWI058Response";
import { IWI059ERRequest } from "../../models/IWI059ERRequest";
import { IWI059ERResponse } from "../../models/IWI059ERResponse";
import { IWI068Request } from "../../models/IWI068Request";
import { IWI068Response } from "../../models/IWI068Response";
import { IWI083Request } from "../../models/IWI083Request";
import { IWI083Response } from "../../models/IWI083Response";
import { IWI119Request } from "../../models/IWI119Request";
import { IWI119Response } from "../../models/IWI119Response";
import { ApiResponseDto } from "../../models/api";
import { IDeviceModelSnapShot } from "../../stores/admin-devices-list.store";
import { IInProgressModelSnapShot } from "../../stores/in-progress-list.store";
import { TApiResponse } from "../../types";
import { ProcessAPIError } from "../decorators";
import { InProgressListFilter } from "../filters/in-progress-list.filter";

class DevicesApi {
  @ProcessAPIError()
  public async getCounters(filter: InProgressListFilter): Promise<TApiResponse<IInProgressDevicesCountResponse>> {
    const result = await API.get("/devices/count", { params: filter }).then(r => r.data);
    return new ApiResponseDto(result);
  }

  @ProcessAPIError()
  public async getInProgressList(filter: InProgressListFilter)
  : Promise<TApiResponse<IInProgressModelSnapShot[] | IDeviceModelSnapShot[]>> {
    const { data } = await API.get("/devices",
      { params: filter });
    return new ApiResponseDto(data);
  }

  @ProcessAPIError()
  public async getExport(filter: InProgressListFilter,): Promise<TApiResponse<any>> {
    const result = await API.get("/devices/export",
      { params: filter });
    return new ApiResponseDto(result);
  }

  @ProcessAPIError()
  public async getDevice(id: string): Promise<TApiResponse<IEditDeviceResponse>> {
    const { data } = await API.get(`/devices/${id}`);
    return new ApiResponseDto(data);
  }

  @ProcessAPIError()
  public async saveDevices(data: IEditDeviceRequest): Promise<TApiResponse<string>> {
    const result = await API.patch("devices/multiple", data);
    return new ApiResponseDto(result.data);
  }

  @ProcessAPIError()
  public async getWI041(id: string, isHistorical: boolean): Promise<TApiResponse<IWI041Response>> {
    const { data } = await API.get(`/devices/${id}/work-instructions/wi-041${isHistorical ? "/historical" : ""}`);
    return new ApiResponseDto(data);
  }

  @ProcessAPIError()
  public async saveWI041(data: IWI041Request, isHistorical: boolean): Promise<TApiResponse<string>> {
    const result = await API.patch(`/devices/work-instructions/wi-041${isHistorical ? "/historical" : ""}`, data);
    return new ApiResponseDto(result.data);
  }

  @ProcessAPIError()
  public async getWI068(id: string, isHistorical: boolean): Promise<TApiResponse<IWI068Response>> {
    const { data } = await API.get(`/devices/${id}/work-instructions/wi-068${isHistorical ? "/historical" : ""}`);
    return new ApiResponseDto(data);
  }

  @ProcessAPIError()
  public async saveWI068(data: IWI068Request, isHistorical: boolean): Promise<TApiResponse<string>> {
    const result = await API.patch(`/devices/work-instructions/wi-068${isHistorical ? "/historical" : ""}`, data);
    return new ApiResponseDto(result.data);
  }
  
  @ProcessAPIError()
  public async getWI068CheckPreviousDeviceSerialNumber(id: string, previousDeviceSerialNumber: string)
    : Promise<TApiResponse<boolean>> {
    const { data }
      = await API.get(`/devices/${id}/work-instructions/wi-068/previous/${previousDeviceSerialNumber}/is-exist`);
    return new ApiResponseDto(data);
  }

  @ProcessAPIError()
  public async saveWI056(data: IWI056Request, isHistorical: boolean): Promise<TApiResponse<string>> {
    const result = await API.patch(`/devices/work-instructions/wi-056${isHistorical ? "/historical" : ""}`, data);
    return new ApiResponseDto(result.data);
  }

  @ProcessAPIError()
  public async getWI056(id: string, isHistorical: boolean): Promise<TApiResponse<IWI056Response>> {
    const { data } = await API.get(`/devices/${id}/work-instructions/wi-056${isHistorical ? "/historical" : ""}`);
    return new ApiResponseDto(data);
  }

  @ProcessAPIError()
  public async saveWI083(data: IWI083Request, isHistorical: boolean): Promise<TApiResponse<string>> {
    const result = await API.patch(`/devices/work-instructions/wi-083${isHistorical ? "/historical" : ""}`, data);
    return new ApiResponseDto(result.data);
  }

  @ProcessAPIError()
  public async getWI083(id: string, isHistorical: boolean): Promise<TApiResponse<IWI083Response>> {
    const { data } = await API.get(`/devices/${id}/work-instructions/wi-083${isHistorical ? "/historical" : ""}`);
    return new ApiResponseDto(data);
  }

  @ProcessAPIError()
  public async saveWI048(data: IWI048Request, isHistorical: boolean): Promise<TApiResponse<string>> {
    const result = await API.patch(`/devices/work-instructions/wi-048${isHistorical ? "/historical" : ""}`, data);
    return new ApiResponseDto(result.data);
  }

  @ProcessAPIError()
  public async getWI048(id: string, isHistorical: boolean): Promise<TApiResponse<IWI048Response>> {
    const { data } = await API.get(`/devices/${id}/work-instructions/wi-048${isHistorical ? "/historical" : ""}`);
    return new ApiResponseDto(data);
  }

  @ProcessAPIError()
  public async saveWI049(data: IWI049Request, isHistorical: boolean): Promise<TApiResponse<string>> {
    const result = await API.patch(`/devices/work-instructions/wi-049${isHistorical ? "/historical" : ""}`, data);
    return new ApiResponseDto(result.data);
  }

  @ProcessAPIError()
  public async getWI049(id: string, isHistorical: boolean): Promise<TApiResponse<IWI049Response>> {
    const { data } = await API.get(`/devices/${id}/work-instructions/wi-049${isHistorical ? "/historical" : ""}`);
    return new ApiResponseDto(data);
  }

  @ProcessAPIError()
  public async saveWI057(data: IWI057Request, isHistorical: boolean): Promise<TApiResponse<string>> {
    const result = await API.patch(`/devices/work-instructions/wi-057${isHistorical ? "/historical" : ""}`, data);
    return new ApiResponseDto(result.data);
  }

  @ProcessAPIError()
  public async getWI057(id: string, isHistorical: boolean): Promise<TApiResponse<IWI057Response>> {
    const { data } = await API.get(`/devices/${id}/work-instructions/wi-057${isHistorical ? "/historical" : ""}`);
    return new ApiResponseDto(data);
  }

  @ProcessAPIError()
  public async saveWI050(data: IWI050Request, isHistorical: boolean): Promise<TApiResponse<string>> {
    const result = await API.patch(`/devices/work-instructions/wi-050${isHistorical ? "/historical" : ""}`, data);
    return new ApiResponseDto(result.data);
  }

  @ProcessAPIError()
  public async getWI050(id: string, isHistorical: boolean): Promise<TApiResponse<IWI050Response>> {
    const { data } = await API.get(`/devices/${id}/work-instructions/wi-050${isHistorical ? "/historical" : ""}`);
    return new ApiResponseDto(data);
  }

  @ProcessAPIError()
  public async saveWI058(data: IWI058Request, isHistorical: boolean): Promise<TApiResponse<string>> {
    const result = await API.patch(`/devices/work-instructions/wi-058${isHistorical ? "/historical" : ""}`, data);
    return new ApiResponseDto(result.data);
  }

  @ProcessAPIError()
  public async getWI058(id: string, isHistorical: boolean): Promise<TApiResponse<IWI058Response>> {
    const { data } = await API.get(`/devices/${id}/work-instructions/wi-058${isHistorical ? "/historical" : ""}`);
    return new ApiResponseDto(data);
  }

  @ProcessAPIError()
  public async saveWI051(data: IWI051Request, isHistorical: boolean): Promise<TApiResponse<string>> {
    const result = await API.patch(`/devices/work-instructions/wi-051${isHistorical ? "/historical" : ""}`, data);
    return new ApiResponseDto(result.data);
  }

  @ProcessAPIError()
  public async getWI051(id: string, isHistorical: boolean): Promise<TApiResponse<IWI051Response>> {
    const { data } = await API.get(`/devices/${id}/work-instructions/wi-051${isHistorical ? "/historical" : ""}`);
    return new ApiResponseDto(data);
  }

  @ProcessAPIError()
  public async saveWI119(data: IWI119Request, isHistorical: boolean): Promise<TApiResponse<string>> {
    const result = await API.patch(`/devices/work-instructions/wi-119${isHistorical ? "/historical" : ""}`, data);
    return new ApiResponseDto(result.data);
  }

  @ProcessAPIError()
  public async getWI119(id: string, isHistorical: boolean): Promise<TApiResponse<IWI119Response>> {
    const { data } = await API.get(`/devices/${id}/work-instructions/wi-119${isHistorical ? "/historical" : ""}`);
    return new ApiResponseDto(data);
  }

  @ProcessAPIError()
  public async saveWI059ER(data: IWI059ERRequest, isHistorical: boolean): Promise<TApiResponse<string>> {
    const result = await API.patch(`/devices/work-instructions/wi-059-er${isHistorical ? "/historical" : ""}`, data);
    return new ApiResponseDto(result.data);
  }

  @ProcessAPIError()
  public async getWI059ER(id: string, isHistorical: boolean): Promise<TApiResponse<IWI059ERResponse>> {
    const { data } = await API.get(`/devices/${id}/work-instructions/wi-059-er${isHistorical ? "/historical" : ""}`);
    return new ApiResponseDto(data);
  }
}

export const devicesApi = new DevicesApi();
