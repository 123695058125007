import { observer } from "mobx-react-lite";
import { FC } from "react";
import { useRootStore } from "../../stores/storeContext";
import { VQIcon, VQNoResult, VQTableLoader, VQTableSection, VQTooltip } from "vivaquant-components-library";
import { PAGINATION } from "../../boot/constants";
import { IRouteConfigurationModel } from "../../stores/admin-route-configuration-list.store";
import { StringsUtils } from "../../services/utils";
import { WorkInstructionType } from "../../boot/enums/WorkInstructionType";
import { Permissions } from "../../services/permission.service";
import ExecuteIcon from "../../assets/icons/execute.svg";
import _uniqueId from "lodash.uniqueid";

export const ETAdminRouteConfigurationListTable: FC = observer(() => {
  const {
    items,
    isLoading,
  } = useRootStore().adminRouteConfigurationListStore;
  const { resetForm, openForm, fetchData, id } = useRootStore().adminRouteConfigurationAddStore;
  const { setGlobalSpiner, setOpenedRightForm } = useRootStore().globalStore;
  const { hasPermission } = useRootStore().userStore;

  const onEdit = (id: string) => {
    resetForm();
    setOpenedRightForm(true);
    setGlobalSpiner(true);
    return fetchData(id).then(() => {
      openForm();
      setGlobalSpiner(false);
    });
  };

  const getContent = () => {
    if (isLoading) {
      return (
        <VQTableLoader
          columns={3}
          rows={PAGINATION.ROWS_PER_PAGE}/>
      );
    }

    return (
      <tbody>
        {items.map((item: IRouteConfigurationModel, index: number) => {
          return (
            <tr key={index} className={`${id && id === item.id ? "tr-selected" : ""}`}>
              <td>
                <div className="td-item">
                  {item.name || "-"}
                </div>
              </td>
              <td>
                <div className="td-item">
                  {item.devicesCount || "0"}
                </div>
              </td>
              <td>
                <div className="td-item">
                  {item.workInstructions 
                    ? item.workInstructions
                      .map((el: number) => StringsUtils.addWIDashes(WorkInstructionType[el]))
                      .join(" - ") 
                    : "-"}
                </div>
              </td>
              <td className="action">
                <div className="td-item actions-wrappwer">
                  <div
                    className={`action-item ${hasPermission(Permissions.RouteConfigurationEdit) ? "" : "disabled"}`}
                    onClick={() => { onEdit(item.id); }}>
                    <VQTooltip
                      id={`tooltip-${_uniqueId()}`}
                      class="vq-tooltip"
                      tooltipBlock={<div>Edit</div>}>
                      <VQIcon icon={ExecuteIcon}/>
                    </VQTooltip>
                  </div>
                </div>
              </td>
            </tr>
          );
        })}
      </tbody>
    );
  };

  return (
    <div className="margin-table scroll-table m-t-24">
      <VQTableSection>
        <thead>
          <tr>
            <th>Title</th>
            <th>Devices QTY</th>
            <th>Work Instructions</th>
            <th className="action">Actions</th>
          </tr>
        </thead>
        {isLoading && getContent()}
        {items.length > 0 && !isLoading && getContent()}
        {items.length === 0 && !isLoading && <VQNoResult/> }
      </VQTableSection>
    </div>
  );
});