export enum NcmDeviceDisposition {
  None = 0,
  InProgress = 1,
  Released = 2,
  Decommissioned = 3
}

export enum NcmDeviceDispositionLabel {
  None = "None",
  InProgress = "In Progress",
  Released = "Released",
  Decommissioned = "Decommissioned"
}