import styled from "styled-components";
import { theme } from "../../theme/theme";

export const NCMPageWrapper = styled.div`
  margin: 24px 60px 24px 60px;
  background-color: #fff;
  border-radius: 16px;
  box-shadow: 8px 8px 16px 0 #0000000A;
  box-sizing: border-box;
  padding: 24px;
  max-height: ${`calc(100vh - ${theme.size.header} - 48px)`};
  overflow: hidden;

  .scroll-table {
    height: calc(100vh - 228px);
    overflow: hidden;

    tbody {
      display: block;
      height: calc(100% - 66px);
      overflow-y: auto;

      &::-webkit-scrollbar-track {
        background-color: #F6F6F6;
      }

      &::-webkit-scrollbar-thumb {
        border: 4px solid transparent;
        background-clip: content-box;
      }
    }

    thead, tbody tr {
      display: table;
      width: 100%;
      table-layout: fixed;
    }

    table {
      display: block;
      height: calc(100% - 24px);
    }
  }
`;
