export enum WI083FailureMode {
  None = 0,
  ModemUpdateFailure = 1,
  ModemPowerOnFailure = 2,
  ModemPowerOffFailure = 3,
  Other = 4,
}

export enum WI083FailureModeLabels {
  None = "None",
  ModemUpdateFailure = "Modem Update Failure",
  ModemPowerOnFailure = "Modem Power On Failure",
  ModemPowerOffFailure = "Modem Power Off Failure",
  Other = "Other",
}