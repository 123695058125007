import { Instance, SnapshotIn, flow, types } from "mobx-state-tree";
import { AdminDevicesListSort, PAGINATION, SortDirection } from "../boot/constants";
import { ReportInfoModel, RouteConfigurationModel } from "./in-progress-list.store";
import { IObservableArray } from "mobx";
import { TApiResponse } from "../types";
import { IInProgressDevicesCountResponse } from "../models/IInProgressDevicesCountResponse";
import { devicesApi } from "../services/api";
import { InProgressListFilter } from "../services/filters/in-progress-list.filter";

const DeviceModel = types
  .model("DeviceModel", {
    id: types.maybe(types.string),
    batchId: types.maybeNull(types.string),
    routeConfiguration: types.maybeNull(RouteConfigurationModel),
    serialNumber: types.maybeNull(types.string),
    importDate: types.maybeNull(types.string),
    dimationBoardSN: types.maybeNull(types.string),
    boardReversion: types.maybeNull(types.string),
    report: types.maybeNull(ReportInfoModel)
  });

export interface IDeviceModel extends Instance<typeof DeviceModel> {}
export interface IDeviceModelSnapShot extends SnapshotIn<typeof DeviceModel> {}

export const AdminDevicesListModel = types
  .model("AdminDevicesListModel", {
    page: 0,
    total: 0,
    searchValue: types.maybe(types.string),
    sortBy: types.optional(types.string, AdminDevicesListSort.batchId),
    sortDirection: types.optional(types.number, SortDirection.DESC),
    isLoading: types.optional(types.boolean, true),
    items: types.optional(types.array(DeviceModel), []),
  })
  .actions(self => ({
    setLoading(isLoading: boolean) {
      self.isLoading = isLoading;
    },
    setList(dto: IDeviceModelSnapShot[]) {
      self.items = dto as IObservableArray;
    }
  }))
  .actions(self => {
    const setFirstPage = () => {
      self.page = 0;
    };

    const nextPage = () => {
      self.page++;
      getList();
    };

    const previousPage = () => {
      self.page--;
      getList();
    };

    const setDefaultParams = () => {
      self.page = 0;
      self.searchValue = "";
      self.sortBy = AdminDevicesListSort.batchId;
      self.sortDirection = SortDirection.DESC;
    };

    const setSearch = (searachValue? : string) => {
      self.searchValue = searachValue;
    };

    const setSortBy = (sortBy: string, sortDirection: number) => {
      self.sortBy = sortBy;
      self.sortDirection = sortDirection;
      getList();
    };

    const getTotalCount = flow(function* getTotalCount() {
      try {
        const filter = new InProgressListFilter();
        filter.search = self.searchValue || "";

        const result: TApiResponse<IInProgressDevicesCountResponse> = yield devicesApi.getCounters(filter);
        if (result.isOk) {
          self.total = result.data.all;
          getList();
        }
      } catch (error) {
        console.error(error);
      }
    });

    const getList = flow(function* getList() {
      self.setLoading(true);

      try {
        const rows = self.total && ((self.page * PAGINATION.ROWS_PER_PAGE + PAGINATION.ROWS_PER_PAGE) > self.total)
          ? self.total - (self.page * PAGINATION.ROWS_PER_PAGE)
          : PAGINATION.ROWS_PER_PAGE;

        const filter = new InProgressListFilter();
        filter.skip = self.page * PAGINATION.ROWS_PER_PAGE;
        filter.take = rows;
        filter.search = self.searchValue || "";
        filter.sortBy = self.sortBy;
        filter.sortDirection = self.sortDirection;

        const result: TApiResponse<IDeviceModelSnapShot[]>
          = yield devicesApi.getInProgressList(filter);
        if (result.isOk) {
          self.setList(result.data);
          self.setLoading(false);
        } else {
          self.setList([]);
          self.setLoading(false);
        }
      } catch (error) {
        console.error(error);
        self.setList([]);
        self.setLoading(false);
      }
    });
    
    
    return {
      nextPage,
      previousPage,
      getList,
      getTotalCount,
      setSearch,
      setSortBy,
      setFirstPage,
      setDefaultParams,
    };
  });