export enum WI051FailureMode {
  None = 0,
  VibrationFailure = 1,
  PostFailure = 2,
  MissingOrDamagedLabel = 3,
  DefectiveConnector = 4,
  ChargeFailure = 5,
  Unresponsive = 6,
  Other = 7
}

export enum WI051FailureModeLabels {
  None = "None",
  VibrationFailure = "Vibration Failure",
  PostFailure = "POST Failure",
  MissingOrDamagedLabel = "Missing/Damaged Label",
  DefectiveConnector = "Defective Connector",
  ChargeFailure = "Charge failure",
  Unresponsive = "Unresponsive",
  Other = "Other"
}