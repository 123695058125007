import { FC } from "react";
import LogoImgAccess from "../../assets/img/rhythm-express-by-viva-quant.png";
import { HeaderWrapper } from "./et-header.presents";

export const ETHeaderLogoOnly: FC = () => {
  return (
    <HeaderWrapper className={"center"}>
      <img className="logo-access" src={LogoImgAccess} alt="Rhythm access logo"/>
    </HeaderWrapper>
  );
};
