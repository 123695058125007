import { WI058FailureMode, WI058FailureModeLabels } from "../boot/enums/WI058FailureMode";
import { IWI058FormModelSnapShot } from "../stores/wi058-form.store";

export const WI058FormState: IWI058FormModelSnapShot = {
  wiRev: {
    name: "WI REV",
    value: "",
    type: "input",
    fieldId: "wiRev"
  },
  failureMode: {
    name: "Failure Mode",
    value: WI058FailureMode.None.toString(),
    type: "select",
    fieldId: "failureMode",
    options: Object.keys(WI058FailureMode)
      .filter((v) => isNaN(Number(v)))
      .map((key: string) => {
        return {
          value: WI058FailureMode[key as keyof typeof WI058FailureMode].toString(),
          label: WI058FailureModeLabels[key as keyof typeof WI058FailureMode],
        };
      }),
    defaultValue: { value: WI058FailureMode.None.toString(), label: WI058FailureModeLabels.None },
  },
  comment: {
    name: "Comment",
    value: "",
    type: "textarea",
    fieldId: "comment"
  },
};
