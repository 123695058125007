export enum WI048FailureMode {
  None = 0,
  CurrentState1 = 1,
  CurrentState4 = 2,
  CurrentModem = 3,
  CurrentWiFi = 4,
  WiFiConnectionFailure = 5,
  AlarmConstant = 6,
  AlarmNone = 7,
  NoLeadsOn = 8,
  NoLeadsOff = 9,
  Unresponsive = 10,
  ResetLooping = 11,
  McUdateFailureHashSign = 12,
  DsPupdateFailure = 13,
  McUpdateFailureDollarSign = 14,
  VibrationFailureNone = 15,
  VibrationFailureConstant = 16,
  BeatCountWrong = 17,
  SimCardFailure = 18,
  Other = 19
}

export enum WI048FailureModeLabels {
  None = "None",
  CurrentState1 = "Current State 1",
  CurrentState4 = "Current State 4",
  CurrentModem = "Current Modem",
  CurrentWiFi = "Current Wi-Fi",
  WiFiConnectionFailure = "Wi-Fi Connection Failure",
  AlarmConstant = "Alarm (Constant)",
  AlarmNone = "Alarm (None)",
  NoLeadsOn = "No \"Leads On\"",
  NoLeadsOff = "No \"Leads Off\"",
  Unresponsive = "Unresponsive",
  ResetLooping = "Reset Looping",
  McUdateFailureHashSign = "\"#\" MC update Failure",
  DsPupdateFailure = "\"@\" DSP update Failure",
  McUpdateFailureDollarSign = "\"$\" MC update Failure",
  VibrationFailureNone = "Vibration Failure (None)",
  VibrationFailureConstant = "Vibration Failure (Constant)",
  BeatCountWrong = "Beat Count Wrong",
  SimCardFailure = "SIM Card Failure",
  Other = "Other"
}