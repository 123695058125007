export enum WorkInstructionType
{
  WI041 = 0,
  WI068 = 9,
  WI056 = 5,
  WI083 = 10,
  WI048 = 1,
  WI049 = 2,
  WI057 = 6,
  WI050 = 3,
  WI058 = 7,
  WI051 = 4,
  WI119 = 11,
  WIER = 12
}