export abstract class ValidationUtils {
  // eslint-disable-next-line max-len
  private static EMAIL_REGEX = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  private static USERNAME_REGEX = /^[a-zA-Z0-9_]{6,}$/;
  private static PASSWORD_REGEX = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/;
  private static PHONE_REGEX = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/;
  private static SERIAL_NUM = /^(RX)\d{6}$/;
  private static DB_SERIAL_NUM = /^(DI)\d{6}$/;
  private static BATCH_ID = /^(B)\d{5}$/;


  public static isEmailValid(email: string): boolean {
    return ValidationUtils.EMAIL_REGEX.test(email.toLowerCase());
  }

  public static isLoginValueValid(value: string): boolean {
    if (value.includes("@")) {
      return ValidationUtils.isEmailValid(value);
    }
    return ValidationUtils.USERNAME_REGEX.test(value);
  }

  public static isPasswordValid(password: string): boolean {
    return ValidationUtils.PASSWORD_REGEX.test(password);
  }

  public static isUsernameValid(username: string): boolean {
    return ValidationUtils.USERNAME_REGEX.test(username);
  }

  public static isPhonedValid(phone: string): boolean {
    return ValidationUtils.PHONE_REGEX.test(ValidationUtils.setPhoneFormat(phone));
  }

  public static setPhoneFormat(phone: string): string {
    if (!phone) {
      return "";
    }
    return phone.replace(/[^\w\s]/gi, "").replace(/\s/g, "");
  }

  public static isSerialNum(sn: string): boolean {
    return ValidationUtils.SERIAL_NUM.test(sn.trim());
  }

  public static isDimationBoardSerialNumber(sn: string): boolean {
    return ValidationUtils.DB_SERIAL_NUM.test(sn.trim());
  }

  public static isBatchID(id: string): boolean {
    return ValidationUtils.BATCH_ID.test(id.trim());
  }
}
