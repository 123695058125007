import { observer } from "mobx-react-lite";
import { FC } from "react";
import { useRootStore } from "../../stores/storeContext";
import { 
  VQIcon, 
  VQIconSort, 
  VQNoResult, 
  VQTableLoader, 
  VQTableSection, 
  VQTooltip
} from "vivaquant-components-library";
import { AdminDevicesListSort, PAGINATION, SortDirection } from "../../boot/constants";
import SortListIcon from "../../assets/icons/sort-list.svg";
import { IDeviceModel } from "../../stores/admin-devices-list.store";
import { DateUtils, StringsUtils } from "../../services/utils";
import ExecuteIcon from "../../assets/icons/execute.svg";
import ReporteIcon from "../../assets/icons/report.svg";
import _uniqueId from "lodash.uniqueid";
import { Permissions } from "../../services/permission.service";
import { confirmAlert } from "react-confirm-alert";

export const ETAdminDevicesListTable: FC = observer(() => {
  const {
    items,
    isLoading,
    setSortBy,
  } = useRootStore().adminDevicesListStore;
  const { setGlobalSpiner, setOpenedRightForm, isOpenedRightForm } = useRootStore().globalStore;
  const { resetForm, openForm, fetchData, id } = useRootStore().adminDevicesEditStore;
  const { openModal, getReport } = useRootStore().reportStore;

  const { hasPermission } = useRootStore().userStore;
  const isReportRead = hasPermission(Permissions.ReportRead);

  const sort = (sortBy: string, sortDirection: number) => {
    return setSortBy(sortBy, sortDirection);
  };

  const onEdit = (id: string) => {
    resetForm();
    setOpenedRightForm(true);
    setGlobalSpiner(true);
    return fetchData(id).then(() => {
      openForm();
      setGlobalSpiner(false);
    });
  };

  const onReport = (id: string, serialNumber: string, date: string) => {
    setGlobalSpiner(true);
    return getReport(id, StringsUtils.reportName(serialNumber, date))
      .then((msg: string) => {
        if (msg) {
          confirmAlert({
            message: msg,
            overlayClassName: "sl-warning-modal",
            buttons: [
              {
                label: "Ok",
                onClick: () => {}
              }
            ]
          });
        } else {
          openModal();
        }
        
        setGlobalSpiner(false);
      });
  };

  const getContent = () => {
    if (isLoading) {
      return (
        <VQTableLoader
          columns={7}
          rows={PAGINATION.ROWS_PER_PAGE}/>
      );
    }

    return (
      <tbody>
        {items.map((item: IDeviceModel, index: number) => {
          return (
            <tr key={index} className={`${id && id === item.id ? "tr-selected" : ""}`}>
              <td>
                <div className="td-item">
                  {item.batchId || "-"}
                </div>
              </td>
              <td>
                <div className="td-item">
                  {item.routeConfiguration?.name || "-"}
                </div>
              </td>
              <td>
                <div className="td-item">
                  {item.serialNumber || "-"}
                </div>
              </td>
              <td>
                <div className="td-item">
                  {DateUtils.formatDate(item.importDate) || "-"}
                </div>
              </td>
              <td>
                <div className="td-item">
                  {item.dimationBoardSN || "-"}
                </div>
              </td>
              <td>
                <div className="td-item">
                  {item.boardReversion || "-"}
                </div>
              </td>
              <td className="action">
                <div className="td-item">
                  <div className="td-item actions-wrappwer">
                    <div
                      className={"action-item"}
                      onClick={() => { onEdit(item.id); }}>
                      <VQTooltip
                        id={`tooltip-${_uniqueId()}`}
                        class="vq-tooltip"
                        tooltipBlock={<div>Edit</div>}>
                        <VQIcon 
                          icon={ExecuteIcon}
                          padding="0 8px"/>
                      </VQTooltip>
                    </div>
                    <div
                      className={`action-item ${!isOpenedRightForm && isReportRead && item.report ? "" : "disabled"}`}
                      style={{margin: "0"}}
                      onClick={() => { onReport(item.report.id, item.serialNumber, item.report.updatedAt); }}>
                      <VQTooltip
                        id={`tooltip-${_uniqueId()}`}
                        class="vq-tooltip"
                        tooltipBlock={<div>Open Device Report</div>}>
                        <VQIcon 
                          icon={ReporteIcon}
                          padding="0 8px"/>
                      </VQTooltip>
                    </div>
                  </div>
                </div>
              </td>
            </tr>
          );
        })}
      </tbody>
    );
  };

  return (
    <div className="margin-table scroll-table m-t-24">
      <VQTableSection>
        <thead>
          <tr>
            <th>
              <div className="sort">
                <div className="sort-actions">
                  <div onClick={() => sort(AdminDevicesListSort.batchId, SortDirection.ASC)}>
                    <VQIconSort icon={SortListIcon}></VQIconSort>
                  </div>
                  <div onClick={() => sort(AdminDevicesListSort.batchId, SortDirection.DESC)} className="bottom-sort">
                    <VQIconSort icon={SortListIcon}></VQIconSort>
                  </div>
                </div>
                <div className="sort-title">Batch ID</div>
              </div>
            </th>
            <th>
              <div className="sort">
                <div className="sort-actions">
                  <div onClick={() => sort(AdminDevicesListSort.routeConfiguration, SortDirection.ASC)}>
                    <VQIconSort icon={SortListIcon}></VQIconSort>
                  </div>
                  <div onClick={() => sort(AdminDevicesListSort.routeConfiguration, SortDirection.DESC)}
                    className="bottom-sort">
                    <VQIconSort icon={SortListIcon}></VQIconSort>
                  </div>
                </div>
                <div className="sort-title">Route Configuration</div>
              </div>
            </th>
            <th>
              <div className="sort">
                <div className="sort-actions">
                  <div onClick={() => sort(AdminDevicesListSort.serialNumber, SortDirection.ASC)}>
                    <VQIconSort icon={SortListIcon}></VQIconSort>
                  </div>
                  <div onClick={() => sort(AdminDevicesListSort.serialNumber, SortDirection.DESC)}
                    className="bottom-sort">
                    <VQIconSort icon={SortListIcon}></VQIconSort>
                  </div>
                </div>
                <div className="sort-title">Serial Number</div>
              </div>
            </th>
            <th>
              <div className="sort">
                <div className="sort-actions">
                  <div onClick={() => sort(AdminDevicesListSort.importDate, SortDirection.ASC)}>
                    <VQIconSort icon={SortListIcon}></VQIconSort>
                  </div>
                  <div onClick={() => sort(AdminDevicesListSort.importDate, SortDirection.DESC)}
                    className="bottom-sort">
                    <VQIconSort icon={SortListIcon}></VQIconSort>
                  </div>
                </div>
                <div className="sort-title">Import Date</div>
              </div>
            </th>
            <th>Board SN</th>
            <th>Board Revision</th>
            <th className="action">Actions</th>
          </tr>
        </thead>
        {isLoading && getContent()}
        {items.length > 0 && !isLoading && getContent()}
        {items.length === 0 && !isLoading && <VQNoResult/> }
      </VQTableSection>
    </div>
  );
});
