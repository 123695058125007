export enum NcmDeviceStage {
  NcmWI041 = 0,
  NcmWI068 = 9,
  NcmWI056 = 5,
  NcmWI083 = 10,
  NcmWI048 = 1,
  NcmWI049 = 2,
  NcmWI057 = 6,
  NcmWI050 = 3,
  NcmWI058 = 7,
  NcmWI051 = 4,
  NcmWI119 = 11,
  NcmWIER = 12,
  Released = 13
}