import { SnapshotIn, flow, types } from "mobx-state-tree";
import { InputFormModel } from "vivaquant-components-library";
import { IProfileModel } from "./user.store";
import { IUpdateProfileRequest } from "../models/IUpdateProfileRequest";
import { ValidationUtils } from "../services/utils";
import { ApiResponseDto } from "../models/api";
import { inviteAPI, userAPI } from "../services/api";
import { TApiResponse } from "../types";
import { IUpdateProfileResponse } from "../models/IUpdateProfileResponse";

export const ProfileStoreModel = types
  .model("ProfileStoreModel", {
    showModal: types.optional(types.boolean, false),
    firstName: types.maybe(InputFormModel),
    lastName: types.maybe(InputFormModel),
    email: types.maybe(InputFormModel),
    username: types.maybe(InputFormModel),
    location: types.maybe(InputFormModel),
    is2FaEnabled: types.optional(types.boolean, false),
    typeUser: types.maybe(InputFormModel),
  })
  .actions(self => ({
    openModal() {
      self.showModal = true;
    },
    closeModal() {
      self.showModal = false;
    }
  }))
  .actions(self => {
    const setIs2FaEnabled = (value: boolean) => {
      self.is2FaEnabled = value;
    };

    const initProfile = (profile: IProfileModel) => {
      fetchGroupsAllowed();
      self.firstName.setValue(profile.firstname || "");
      self.lastName.setValue(profile.lastname || "");
      self.email.setValue(profile.email || "");
      self.username.setValue(profile.username || "");
      self.location.setValue(profile.location || "");
      self.is2FaEnabled = profile.is2FaEnabled;
      if (profile.groups && profile.groups.length) {
        self.typeUser.setValueMultiply(profile.groups.map((el: string) => el));
        self.typeUser.setDefaultMultiValue(profile.groups.map((el: string) => el));
      }
    };

    const fetchGroupsAllowed = flow(function* fetchGroupsAllowed() {
      const { data }: ApiResponseDto<any> = yield inviteAPI.getGroupsAllowed();
      const options = data.map((item: any) => {
        return { value: item.name, label: item.name };
      });
      self.typeUser.setOptions(options);
    });

    const getDataForSave = (): IUpdateProfileRequest => {
      return {
        firstname: self.firstName.value.trim(),
        lastname: self.lastName.value.trim(),
        email: self.email.value,
        username: self.username.value,
        location: self.location.value,
        is2FaEnabled: self.is2FaEnabled
      };
    };

    const validateCode = flow(function* validateCode(authCode: string) {
      const data = getDataForSave();
      return yield updateProfile2Fa(data, authCode);
    });

    const saveForm = flow(function* saveForm() {
      const data = getDataForSave();
      return yield updateProfile(data);
    });

    const updateProfile = flow(function* updateProfile(request: IUpdateProfileRequest) {
      try {
        const response: TApiResponse<IUpdateProfileResponse> = yield userAPI.updateProfile(request);
        return response;
      } catch (e) {
        return false;
      }
    });

    const updateProfile2Fa = flow(function* updateProfile2Fa(request: IUpdateProfileRequest, code: string) {
      try {
        const response: TApiResponse<IUpdateProfileResponse> = yield userAPI.updateProfile2Fa(request, code);
        return response.isOk;
      } catch (e) {
        return false;
      }
    });

    const resendUpdateCode = flow(function* resendUpdateCode() {
      try {
        const response: TApiResponse<null> = yield userAPI.resendUpdateCode();
        return response.isOk;
      } catch (e) {
        return false;
      }
    });

    const validateField = (): {errors: {[key: string]: string}, isValid: boolean} => {
      const errors: {[key: string]: string} = {};
      let isValid: boolean = true;

      if (!self.firstName?.value) {
        errors.firstName = "Please fill the field";
        isValid = false;
      }

      if (!self.lastName?.value) {
        errors.lastName = "Please fill the field";
        isValid = false;
      }

      if (!self.username?.value) {
        errors.username = "Please fill the field";
        isValid = false;
      } else {
        if (!ValidationUtils.isUsernameValid(self.username?.value)) {
          errors.username = "Minimum 6 characters required";
          isValid = false;
        }
      }

      if (!self.email?.value) {
        errors.email = "Please fill the field";
        isValid = false;
      } else {
        if (!ValidationUtils.isEmailValid(self.email?.value)) {
          errors.email = "Wrong email";
          isValid = false;
        }
      }

      return {
        errors,
        isValid
      };
    };

    return {
      initProfile,
      validateCode,
      saveForm,
      validateField,
      getDataForSave,
      resendUpdateCode,
      setIs2FaEnabled
    };
  });

export interface IProfileStoreModelSnapShot extends SnapshotIn<typeof ProfileStoreModel> {}
