import moment from "moment";

export class DateUtils {

  // 08/08/23
  public static formatDate(date: string) {
    return moment(date).format("MM/DD/YY");
  }

  // 08/08/23
  public static formatDateTime(date: string) {
    return moment(date).format("MM/DD/YY, HH:mm");
  }

  // yyyy-mm-dd
  public static formatYYY_MM_DD(date: string) {
    return moment(date).format("YYYY-MM-DD");
  }

  public static toServerFormat(date?: Date) : string {
    if (!date) {
      return null;
    }
    const offset = date.getTimezoneOffset();
    date = new Date(date.getTime() - (offset * 60 * 1000));
    return date.toISOString().split("T")[0];
  }
}
